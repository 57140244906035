import * as React from 'react';
import { useRef, useEffect } from 'react';

export interface WayPointProps {
  onEnter?: () => void;
  onLeave?: () => void;
  tid?: string;
  threshold?: string;
  minInterval?: number;
}

export default function WayPoint({ onEnter, onLeave, threshold, minInterval }: WayPointProps) {
  const el = useRef<HTMLDivElement | null>(null);
  const handles = useRef<Array<(() => void) | undefined>>([]);
  let { current: lastTick } = useRef<number>(-1);

  handles.current = [onEnter, onLeave];

  useEffect(() => {
    const container = el.current;

    if (container) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          const now = Date.now();
          // debounce
          if (minInterval && now - lastTick < minInterval) {
            return;
          }

          const [handleEnter, handleLeave] = handles.current;
          lastTick = now;

          if (entry.isIntersecting && handleEnter) {
            handleEnter();
          } else if (!entry.isIntersecting && handleLeave) {
            handleLeave();
          }
        },
        {
          rootMargin: threshold || '10px',
        }
      );

      setTimeout(() => {
        observer.observe(container);
      }, 1000);

      return () => {
        observer.unobserve(container);
      };
    }
  }, []);

  return <div ref={el} />;
}
