import { gaDataset, SEND_PROJ_TARGET } from './analytics';

export const ANUE_EVENT_DATA_ATTR = 'data-anue-ga-event';

/**
 * This util searches value of the DOM has data attribute `data-anue-ga-event` then replace it with GA event data attributes based on the given value.
 * @param gaName GA name.
 */
export function handleSearchAttrsAndAppendGaEvent() {
  if (typeof window === 'undefined') {
    return null;
  }

  const domWithEventList: NodeListOf<Element> = document.querySelectorAll(`[${ANUE_EVENT_DATA_ATTR}]`);

  if (domWithEventList.length > 0) {
    for (const d of domWithEventList) {
      const event = d.getAttribute(ANUE_EVENT_DATA_ATTR).split(',') || [];

      if (event.length < 3) {
        console.log(
          `${ANUE_EVENT_DATA_ATTR} attribute is not valid. Please make sure to fill the value of Category,Action,Label`
        );
        return;
      }
      d.removeAttribute(ANUE_EVENT_DATA_ATTR);

      // ga name array
      const gName = event[3] ? SEND_PROJ_TARGET.filter(target => event.slice(3).indexOf(target) !== -1) : null;

      const gaAttrs = gaDataset(gName, event[0], event[1], event[2]);

      if (gaAttrs) {
        for (const attr in gaAttrs) {
          d.setAttribute(attr, gaAttrs[attr]);
        }
      }
    }
  }
}
