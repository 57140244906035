import { localStorageSetItem, localStorageGetItem } from '../../utils/localStorageWrapper';
import { GETv1Analysis } from '../../utils/api';

const LOAD_VISITOR_ID = 'cnyes/visitorId/LOAD';
const LOAD_VISITOR_ID_FAIL = 'cnyes/visitorId/LOAD_FAIL';
const LOAD_VISITOR_ID_SUCCESS = 'cnyes/visitorId/LOAD_SUCCESS';
const SET_VISITOR_ID = 'cnyes/visitorId/SET_VISITOR_ID';
const PUSH_TO_QUEUE = 'cnyes/visitorId/PUSH_TO_QUEUE';

export const initialState = {
  data: '',
  isFetching: false,
  queue: [],
};

function process(state = initialState, action) {
  switch (action.type) {
    case LOAD_VISITOR_ID: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case LOAD_VISITOR_ID_FAIL: {
      const { queue } = state;

      queue.forEach(fn => fn('unknown'));

      return {
        ...state,
        isFetching: false,
        error: action.error,
        queue: [],
      };
    }
    case LOAD_VISITOR_ID_SUCCESS: {
      const { queue } = state;
      const data = action.result.items.visitorId;

      localStorageSetItem('visitorId', data);
      queue.forEach(fn => fn(data));

      return {
        ...state,
        isFetching: false,
        data,
        queue: [],
      };
    }
    case SET_VISITOR_ID: {
      const { queue } = state;

      queue.forEach(fn => fn(action.visitorId));

      return {
        ...state,
        data: action.visitorId,
        queue: [],
      };
    }
    default:
      return state;
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_VISITOR_ID:
    case LOAD_VISITOR_ID_FAIL:
    case LOAD_VISITOR_ID_SUCCESS:
    case SET_VISITOR_ID: {
      return {
        ...state,
        ...process(state, action),
      };
    }
    case PUSH_TO_QUEUE: {
      return {
        ...state,
        queue: [...state.queue.slice(0), action.doAction],
      };
    }
    default: {
      return state;
    }
  }
}

export function shouldFetch(state) {
  if (state.visitorId && state.visitorId.data && state.visitorId.data.length) {
    return false;
  }

  return true;
}

export function setVisitorId(visitorId = '') {
  return {
    type: SET_VISITOR_ID,
    visitorId,
  };
}

export function pushToQueue(doAction) {
  return {
    type: PUSH_TO_QUEUE,
    doAction,
  };
}

export function loadVisitorId() {
  return {
    types: [LOAD_VISITOR_ID, LOAD_VISITOR_ID_SUCCESS, LOAD_VISITOR_ID_FAIL],
    promise: () => GETv1Analysis(),
  };
}

export function loadVistorIdIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      const visitorId = localStorageGetItem('visitorId');

      if (visitorId && visitorId.length) {
        return dispatch(setVisitorId(visitorId));
      }

      return dispatch(loadVisitorId());
    }

    return Promise.resolve();
  };
}

export function visitoridAndThen(doAction) {
  return (dispatch, getState) => {
    const state = getState();

    if (shouldFetch(state) && !(state.visitorId && state.visitorId.error)) {
      return dispatch(pushToQueue(doAction));
    }

    doAction(state.visitorId.data);

    return Promise.resolve(state.visitorId.data);
  };
}
