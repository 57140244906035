import { useState, useEffect } from 'react';
import globalConfig from 'constant/globalConfig';
const Shared = anue.shared;
const Auth = Shared.get('packages.auth');
const Eventy = Shared.get('utils.eventy');

const auth: AuthService = new Auth({
  host: `${globalConfig.memberServiceEndpoint}/member`,
});

auth
  .onStateChange(ctx => {
    if (ctx && ctx.status === 'online' && ctx.context) {
      event.emit('change', ctx.context);
    } else {
      event.emit('change', null);
    }
  })
  .onError(err => {
    console.log('auth error', err);
  });

const event = new Eventy();

export const isLogin = () => {
  const ctx = auth.getCredentialContext();
  if (ctx) {
    return !!ctx.authorization;
  }
  return false;
};

export function getProfile() {
  const profile = auth ? auth.optimisticallyGetProfile() : null;
  if (profile) {
    profile.avatar = profile.avatar || 'https://sfiles.cnyes.cool/member/icon-user.svg';

    profile.uid = profile.ga_id || profile.email;
  }

  return profile;
}

export async function logout() {
  auth.clearCredentials();
}

export async function login(ref?: string) {
  if (location) {
    const { origin, pathname } = location;

    location.href = `${globalConfig.loginUrl}?aink=${origin}${pathname}`;
  }

  // add pageview for tracking login from which action
  if (ref) {
    const nextUrl = String(location.pathname)
      .replace(new RegExp(`/${ref}`, 'g'), '')
      .replace(/\/+$/g, '');
    history.pushState(null, document.title, nextUrl + '/' + ref);
  }
}

export function getInstance() {
  return auth;
}

export default function useAuth(): [
  Anue.Auth.Credentials | null,
  (ref?: string, wording?: string) => void,
  () => void
] {
  const [authState, setAuth] = useState<Anue.Auth.Credentials | null>(auth.getCredentialContext() || null);

  useEffect(() => {
    const removeListener = event.on('change', (ctx: Anue.Auth.Credentials | null) => {
      setAuth(ctx);
    });
    return () => removeListener();
  }, []);

  return [authState, login, logout];
}
