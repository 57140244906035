import React, { Component, PropTypes } from 'react';
import Helmet from 'react-helmet';
import idx from 'idx';
import { SITE_TITLE } from '../../helpers/meta';

class HelmetWithGA extends Component {
  static propTypes = {
    config: PropTypes.shape(Helmet.propTypes),
    pageview: PropTypes.func.isRequired,
    ignoreTitle: PropTypes.string.isRequired,
    ignoreWhenFirstChange: PropTypes.bool,
  };

  static defaultProps = {
    config: undefined,
    ignoreWhenFirstChange: true,
  };

  shouldComponentUpdate() {
    // avoid rerender whatever props change
    return false;
  }

  /*
   * Note: Listen Helmet change is for getting real page title and send pageview correctly
   * */
  onChangeClientState = newState => {
    if (!newState) {
      return;
    }

    const { ignoreTitle, ignoreWhenFirstChange } = this.props;

    // get lastTitle from last state
    const lastTitle = idx(this._lastHelmetState, _ => _.title) || '';

    let sendPageView = false;
    let saveLastState = true;

    if (newState.title === ignoreTitle) {
      // dont save last state if title is ignoreTitle
      saveLastState = false;
    }

    if (saveLastState && newState.title !== SITE_TITLE && newState.title !== lastTitle) {
      // send pageview if title is not the same
      if (ignoreWhenFirstChange) {
        if (lastTitle && lastTitle !== '') {
          sendPageView = true;
        }
      } else {
        sendPageView = true;
      }
    }

    if (sendPageView) {
      // eslint-disable-next-line react/prop-types
      const { location } = this.props;

      this.props.pageview(`${location.pathname}${location.search}`);
    }

    if (saveLastState) {
      // set newState as variable
      this._lastHelmetState = newState;
    }
  };

  render() {
    return <Helmet {...this.props.config} onChangeClientState={this.onChangeClientState} />;
  }
}

export default HelmetWithGA;
