import * as React from 'react';
import { useCallback } from 'react';
import useChat from 'hooks/chat';
import useAuth, { getProfile } from 'hooks/auth';
import ChatRoomList from 'components/ChatRoomList/ChatRoomList';
import ChatInput from 'components/ChatInput/ChatInput';
import getty from 'anue-fe-sdk/getty';
import styles from './chat-room.scss';

export default function ChatRoom() {
  const [messages, , onlineCount, fetchHistory, onMessage] = useChat();
  const [auth] = useAuth();
  const uid = getty(auth, ['profile', 'ga_id']) || getty(auth, ['profile', 'email']);
  const StaticTitle = useCallback(
    () => (
      <h2 className={styles.header}>
        <img src={require('assets/icon-chat.svg')} alt="聊天" />
        聊天室
      </h2>
    ),
    []
  );

  return (
    <div className={styles.container}>
      <header>
        <StaticTitle />
        <div className={styles.online}>{onlineCount} 人在線</div>
      </header>
      {((getProfile() && uid) || !uid) && (
        <ChatRoomList uid={uid || ''} data={messages} onEndReached={fetchHistory} onNewMessage={onMessage} />
      )}
      <ChatInput />
    </div>
  );
}
