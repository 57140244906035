import getty from 'anue-fe-sdk/getty';
import Variation from 'constant/variation';
import { pageview } from 'utils/analytics';

function setMeta(key, val) {
  let meta = document.querySelector(`meta[name="${key}"]`);
  if (!meta) {
    meta = document.createElement('meta');
    document.head.appendChild(meta);
  }
  meta.setAttribute('name', key);
  meta.setAttribute('content', val);
}

export default function useMeta() {
  function updateMeta(quote: Quote) {
    if (!quote) {
      return;
    }
    const code = getty(String(quote.symbol).split(':'), [1]);
    const title = `${quote.name} (${code}) 即時股價走勢 | ${Variation.meta.title} | Anue鉅亨`;
    const desc = `#線上看盤 #股市聊天 #即時新聞 #個股走勢 #${quote.name}`;
    setMeta('description', desc);
    setMeta('og:description', desc);
    setMeta('keywords', `股市, anue鉅亨, ${quote.name}, ${code}`);
    setMeta('og:keywords', `股市, anue鉅亨, ${quote.name}, ${code}`);

    document.title = title;
    pageview(location.pathname);
  }

  return [updateMeta];
}
