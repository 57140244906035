export default function clientMiddleware(client, extraArgument) {
  return ({ dispatch, getState }) => {
    return next => action => {
      if (typeof action === 'function') {
        return action(dispatch, getState, extraArgument);
      }

      if (!action) {
        return next(action);
      }

      const { promise, types, ...rest } = action;

      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;

      next({ ...rest, type: REQUEST });

      if (__DEVELOPMENT__ && console && console.time) {
        console.time(SUCCESS);
      }

      const actionPromise = promise(client);

      if (__DEVELOPMENT__ && console && console.timeEnd) {
        actionPromise.then(console.timeEnd.bind(console, SUCCESS), console.timeEnd.bind(console, SUCCESS));
      }

      actionPromise
        .then(
          result =>
            next({
              ...rest,
              result: result && result.data,
              type: SUCCESS,
            }),
          error =>
            next({
              ...rest,
              error: (error && error.response && error.response.data) || (error && error.response) || error,
              type: FAILURE,
            })
        )
        .catch(error => {
          console.error('MIDDLEWARE ERROR:', error);
          next({ ...rest, error, type: FAILURE });
        });

      return actionPromise;
    };
  };
}
