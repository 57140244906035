import * as Network from 'anue-fe-sdk/Network';
import { AxiosResponse } from 'axios';
import Variation from 'constant/variation';
import globalConfig from 'constant/globalConfig';
import apiClientFactory from 'helpers/apiClientFactory';

function getAPIClient(apiUrlName) {
  return apiClientFactory({
    baseURL: apiUrlName,
  });
}

const reuterApiClient = getAPIClient(globalConfig.reuterAPI);
const apiClient = getAPIClient(globalConfig.defaultAPI);
/**
 * TODO: Keep moving API to this util.
 */

type PagedSearchResult = {
  message: string;
  statusCode: number;
  data: {
    currentPage: number;
    lastPage: number;
    items: SearchResult[];
  };
};

export async function GETv1EssSiteSearchFundQuoteName(keyword: string, num: number) {
  return await Network.getDriver().send<PagedSearchResult>({
    url: Variation.search.url(keyword, num),
  });
}

export async function GETv1QuoteBySymbol(symbols: string | string[]): Promise<AxiosResponse<Response3<[]>>> {
  const symbolStr = Array.isArray(symbols) ? symbols.join(',') : symbols;
  const url = `/ws/api/v1/quote/quotes/${symbolStr}?column=FORMAT`;

  return reuterApiClient.get(url);
}

export async function GETv1ChartHistory(params: ChartRequestParams): Promise<AxiosResponse<ChartUIProps>> {
  const url = `/ws/api/v1/charting/history`;

  return reuterApiClient.get(url, { params });
}

export function GETv1SymbolByUrl(url) {
  return reuterApiClient.get<PagedSearchResult>(url);
}

export function GETv1VisitorTags(visitorId) {
  return apiClient.get(`${globalConfig.defaultAPI}/media/api/v1/visitor/tag?visitorId=${visitorId}`);
}

export function GETv1Data(url) {
  return apiClient.get(url);
}

export function GETv1Eps(id) {
  const url = `${globalConfig.marketAPI}/mi/api/v1/${id}/eps`;
  return apiClient.get(url);
}

export function GETv1Balance(id) {
  const url = `${globalConfig.marketAPI}/mi/api/v1/${id}/balance`;
  return apiClient.get(url);
}

export function GETv1Income(id) {
  const url = `${globalConfig.marketAPI}/mi/api/v1/${id}/income`;
  return apiClient.get(url);
}

export function GETv1Ratios(id) {
  const url = `${globalConfig.marketAPI}/mi/api/v1/${id}/ratio`;
  return apiClient.get(url);
}

export function GETv1Info(id) {
  const url = `${globalConfig.marketAPI}/mi/api/v1/${id}/info`;
  return apiClient.get(url);
}

export function GETv1Investor(id) {
  const url = `${globalConfig.marketAPI}/mi/api/v1/${id}/investors`;
  return apiClient.get(url);
}

export function GETv1Margin(id) {
  const url = `${globalConfig.marketAPI}/mi/api/v1/${id}/margin`;
  return apiClient.get(url);
}

export function GETv1Revenue(id) {
  const url = `${globalConfig.marketAPI}/mi/api/v1/${id}/revenue`;
  return apiClient.get(url);
}

export function GETv1Analysis() {
  const url = 'https://analytics.prod.jpp.cnyes.cool/visitorid.php';

  const params = {
    withCredentials: true,
  };
  return apiClient.get(url, { ...params });
}
