import * as React from 'react';
import { useMemo } from 'react';
import idx from 'idx';
import useSharedQuote from 'hooks/sharedQuotes';
import classnames from 'classnames';
import styles from './style.scss';

interface MarketStatusProps {
  symbol: string;
  isLoaded: boolean;
}

function MarketStatus({ symbol, isLoaded }) {
  const [quotes] = useSharedQuote();
  const isTrading = idx(quotes, _ => _[symbol].isTrading);
  const marketStatus: MarketStatusType = isLoaded ? (!!isTrading ? '即時' : '盤後數據') : '載入數據';

  return useMemo(
    () => (
      <div
        className={classnames(styles['market-status'], {
          [styles['is-open']]: marketStatus === '即時',
          [styles['is-loading']]: marketStatus === '載入數據',
        })}
      >
        <span className={styles.dot} /> <span>{marketStatus}</span>
      </div>
    ),
    [marketStatus]
  );
}

export default React.memo(
  (props: MarketStatusProps) => <MarketStatus {...props} />,
  (prev, next) => prev.isLoaded === next.isLoaded && prev.symbol === next.symbol
);
