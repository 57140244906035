import * as React from 'react';
import getty from 'anue-fe-sdk/getty';
import { useRef, useEffect } from 'react';
import useAuth from 'hooks/auth';
import usePortfolio from 'hooks/portfolio';
import useSharedQuote from 'hooks/sharedQuotes';
import styles from './portfolio.scss';
import QuoteItem from 'components/QuoteItem';
import PortfolioHeader from 'containers/PortfolioHeader';

function Portfolio() {
  const [groups, currentGroup, setActiveGroup, getGroups, setGroup] = usePortfolio();
  const [quotes, setSymbols] = useSharedQuote();
  const [auth, login] = useAuth();
  const isLogin = !!getty(auth, ['authorization']);
  const el = useRef<HTMLUListElement | null>(null);
  const displayGroup = getty(groups, [currentGroup]) || [];
  const lastGroup = useRef<{ count: number; index: number; isLogin: boolean }>({
    count: displayGroup.length,
    index: 0,
    isLogin,
  });

  // when auth state changed, get group list again
  useEffect(() => {
    getGroups(!isLogin);
    setActiveGroup(0);
  }, [isLogin]);

  useEffect(() => {
    if (displayGroup && displayGroup.length > 0) {
      setSymbols(displayGroup);
    }

    if (
      el.current &&
      lastGroup.current.isLogin &&
      lastGroup.current.index === currentGroup &&
      lastGroup.current.count < displayGroup.length
    ) {
      if (el.current.scrollTo) {
        el.current.scrollTo(0, el.current.scrollHeight + 40);
      }
      // Edge workaround
      else {
        el.current.scrollTop = el.current.scrollHeight + 40;
      }
    }

    lastGroup.current = {
      count: displayGroup.length,
      index: currentGroup,
      isLogin,
    };
  }, [displayGroup]);

  function handleDragDrop(targetSymbol: string, beforeSymbol: string) {
    if (!isLogin) {
      login('login_plus');
    } else if (beforeSymbol) {
      let targetGroup = groups[currentGroup] || [];
      console.log('place [%s] before [%s]', targetSymbol, beforeSymbol);

      targetGroup = targetGroup
        .reduce<string[]>((p, c) => {
          if (c === beforeSymbol) {
            p.push(targetSymbol, beforeSymbol);
          } else if (c !== targetSymbol) {
            p.push(c);
          }
          return p;
        }, [])
        .concat(beforeSymbol === 'dummy' ? [targetSymbol] : []);

      setGroup(currentGroup + 1, targetGroup);
    }
  }

  function handleRemove(symbol: string) {
    const next = [...displayGroup];
    const index = next.indexOf(symbol);

    if (isLogin && index > -1) {
      next.splice(next.indexOf(symbol), 1);
      setGroup(currentGroup + 1, next);
    }
  }

  function renderCurrentGroup() {
    if (displayGroup.length === 0) {
      return (
        <div className={`col ${styles.empty}`}>
          <div>尚未儲存任何股票</div>
          <div>
            請按右上角”
            <img src={require('assets/icon-add-fav.svg')} />
            ”來新增
          </div>
        </div>
      );
    }

    return (
      <ul ref={el}>
        {[...displayGroup, 'dummy'].map((g, i) => {
          const quote = getty(quotes, [g]) || {
            symbol: g,
            name: '',
            lp: '',
            ch: '',
            chp: '',
            isTrading: false,
            tradeTime: '',
            hasInfo: false,
            hasMajorInvestor: false,
            hasRevenue: false,
            hasMarginShort: false,
            prevClosePrice: '',
            volume: 0,
          };
          const isPlaceholderElement = g === 'dummy';
          return (
            (quote || isPlaceholderElement) && (
              <QuoteItem
                currentGroup={currentGroup}
                isEmpty={isPlaceholderElement}
                onRemove={handleRemove}
                key={`${currentGroup}-${g}-${i}`}
                {...quotes[g]}
                onDrop={handleDragDrop}
              />
            )
          );
        })}
      </ul>
    );
  }

  function handleAddSymbol(symbol: string) {
    const activeGroup = getty(groups, [currentGroup]) || [];

    if (activeGroup.indexOf(symbol.toUpperCase()) < 0) {
      setGroup(currentGroup + 1, [...activeGroup, symbol]);
    }
  }

  return (
    <div className={`col ${styles.container}`}>
      <PortfolioHeader
        isLogin={isLogin}
        onSymbolSelected={handleAddSymbol}
        currentGroup={currentGroup}
        onGroupChange={i => {
          setActiveGroup(i);
        }}
      />
      {renderCurrentGroup()}
    </div>
  );
}

export default Portfolio;
