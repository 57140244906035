import { Component, PropTypes } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import idx from 'idx';
import { GETv1VisitorTags } from '../../utils/api';
import { getDomainFromHost } from '../../utils/UrlParser';

export const WALLY_DTAGS_CACHE_TTL = 8 * 60 * 60; // sec
export const WALLY_DTAGS_ERROR_CACHE_TTL = 2 * 60;
export const WALLY_VISITOR_TAGS_COOKIE_NAME = 'wally_v_tags';

export function wallyGetDefaultTagsPromise(req, cachedClientGet) {
  if (req && cachedClientGet) {
    const wallyDTagsPromise = cachedClientGet(`/media/api/v1/url/tag?url=${req.get('host')}${req.originalUrl}`);

    return wallyDTagsPromise;
  }

  return Promise.resolve();
}

class Wally extends Component {
  static childContextTypes = {
    wallyGetTags: PropTypes.func,
  };

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    cookies: PropTypes.instanceOf(Cookies),
    defaultTags: PropTypes.arrayOf(PropTypes.string),
    visitorId: PropTypes.string,
  };

  static defaultProps = {
    cookies: undefined,
    defaultTags: undefined,
    visitorId: '',
  };

  static contextTypes = {
    request: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.done = false;
    this.shouldGetTag = false;
    this.tags = null;
  }

  getChildContext() {
    return {
      wallyGetTags: this.getTags,
    };
  }

  componentDidMount() {
    const { cookies, visitorId } = this.props;
    const visitorTagsFromCookie = cookies.get(WALLY_VISITOR_TAGS_COOKIE_NAME);

    if (visitorTagsFromCookie) {
      this.done = true;
    } else if (!visitorTagsFromCookie && visitorId.length > 0) {
      this.getVisitorTags();
    } else if (!visitorTagsFromCookie && visitorId.length === 0) {
      this.shouldGetTag = true;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visitorId !== nextProps.visitorId && !this.done && this.shouldGetTag) {
      this.getVisitorTags(nextProps.visitorId);
    }
  }

  getVisitorTags = (nextVisitorId = undefined) => {
    const { cookies, visitorId } = this.props;
    const _visitorId = nextVisitorId || visitorId;

    if (!_visitorId || _visitorId.length === 0) {
      return;
    }

    this.done = true;
    this.shouldGetTag = false;
    GETv1VisitorTags(_visitorId).then(res => {
      const tags = idx(res, _ => _.data.items.tags);
      const domain = getDomainFromHost(location.host);
      const eightHoursLater = new Date(Date.now() + 8 * 60 * 60 * 1000);

      cookies.set(WALLY_VISITOR_TAGS_COOKIE_NAME, tags, {
        path: '/',
        expires: eightHoursLater,
        domain,
      });
    });
  };

  getTags = () => {
    const { defaultTags, cookies } = this.props;
    const visitorTagsFromCookie = cookies.get(WALLY_VISITOR_TAGS_COOKIE_NAME);
    const tags = visitorTagsFromCookie || defaultTags;

    return tags;
  };

  render() {
    return this.props.children;
  }
}

export default withCookies(Wally);
