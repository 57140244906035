import * as React from 'react';
import getty from 'anue-fe-sdk/getty';
import useAuth, { isLogin } from 'hooks/auth';
import usePortfolio from 'hooks/portfolio';
import Variation from 'constant/variation';
import { sendEventLog, SEND_PROJ_TARGET } from 'utils/analytics';
import styles from './fav-star.scss';
import sendDataLayer from 'fe-common-library/dest/utils/sendDataLayer';

function FavStar({ symbol, name }: { symbol: string; name: string }) {
  const [portfolio, activeGroup, , , setGroup] = usePortfolio();
  const [, login] = useAuth();
  const currentGroup = (getty(portfolio, [activeGroup]) as string[]) || [];

  const activeGroupData = portfolio[activeGroup];
  const isAddedIntoPortfolio = isLogin() && activeGroupData && activeGroupData.indexOf(symbol.toUpperCase()) > -1;
  const starClass = isAddedIntoPortfolio ? styles['star-on'] : styles['star-off'];

  function handleStarClick() {
    if (!isLogin()) {
      login('login_star');
      sendEventLog(Variation.stockChannelName, '加入自選 click', '登入', {}, SEND_PROJ_TARGET);
      sendDataLayer({
        dataPrefix: SEND_PROJ_TARGET,
        eventName: 'Click_新增自選',
        section: '線圖上方',
        clickItem: '登入',
      });
    } else {
      const symbolUC = symbol.toUpperCase();
      const existingIndex = currentGroup.indexOf(symbolUC);

      if (existingIndex > -1) {
        currentGroup.splice(existingIndex, 1);
        setGroup(activeGroup + 1, [...currentGroup]);
        sendEventLog(Variation.stockChannelName, '加入自選 click', '移除', {}, SEND_PROJ_TARGET);
        sendDataLayer({
          dataPrefix: SEND_PROJ_TARGET,
          eventName: 'Click_新增自選',
          section: '線圖上方_移除',
          clickItem: `${activeGroup + 1}_${symbolUC}_${name}`,
        });
      } else {
        setGroup(activeGroup + 1, [...currentGroup, symbolUC]);
        sendEventLog(
          Variation.stockChannelName,
          '加入自選 click',
          `${activeGroup + 1}_${symbolUC}`,
          {},
          SEND_PROJ_TARGET
        );
        sendDataLayer({
          dataPrefix: SEND_PROJ_TARGET,
          eventName: 'Click_新增自選',
          section: '線圖上方_新增',
          clickItem: `${activeGroup + 1}_${symbolUC}_${name}`,
        });
      }
    }
  }

  return (
    <button
      aria-label="加入自選"
      style={{ opacity: isLogin() ? 1 : 0.5 }}
      className={`${styles.star} ${starClass}`}
      title={isAddedIntoPortfolio ? '從投資組合中移除' : '新增至投資組合'}
      onClick={handleStarClick}
    />
  );
}

export default React.memo(
  (props: { symbol: string; name: string }) => <FavStar {...props} />,
  (prev, next) => prev.symbol === next.symbol
);
