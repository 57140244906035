import * as React from 'react';
import NewsListByType from 'containers/NewsListByType/NewsListByType';
import Variation from 'constant/variation';
import styles from './News.scss';
import gaDataset from 'fe-common-library/dest/utils/gaDataset';
import { SEND_PROJ_TARGET } from 'utils/analytics';

interface NewsPropsState {
  collapse: boolean;
  currentTab: number;
}

interface NewsProps {
  symbol: string;
}

class News extends React.Component<NewsProps> {
  state: NewsPropsState = {
    collapse: false,
    currentTab: 0,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.symbol !== nextProps.symbol ||
      this.state.collapse !== nextState.collapse ||
      this.state.currentTab !== nextState.currentTab
    );
  }

  changeTab = index => () => {
    this.setState({
      currentTab: index,
    });
  };

  renderTabs() {
    const items = [
      {
        key: 'relatedNews',
        name: '相關新聞',
        showNotify: false,
      },
      {
        key: 'realtimeNews',
        name: '即時新聞',
        showNotify: false,
      },
      {
        key: 'videoNews',
        name: '財經影音',
        showNotify: false,
      },
    ];

    return items.map((item, index) => {
      return (
        <li key={item.key} data-index={index}>
          <a
            onClick={this.changeTab(index)}
            data-ga-category="新聞"
            data-ga-action="Click"
            data-ga-label={item.name}
            data-anue-ga-event={`${Variation.stockChannelName},新聞列表 click,${item.name},proj`}
            className={item.showNotify && this.state.currentTab !== 1 ? styles.notify : ''}
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Tab',
              section: '投資組合',
              clickItem: item.name,
            })}
          >
            {item.name}
          </a>
        </li>
      );
    });
  }

  renderTabContent = () => {
    const { symbol } = this.props;
    const { currentTab } = this.state;

    switch (currentTab) {
      case 0:
        return <NewsListByType key={`news-related-${symbol}`} symbol={symbol} type="related" />;
      case 1:
        return <NewsListByType key="news-realtime" type="realtime" updateInterval={60000} />;
      case 2:
        return <NewsListByType key="news-video" type="video" />;
    }
  };

  render() {
    const { collapse } = this.state;

    const wrapperClass = [styles.wrapper];
    const toggleClass = [styles.toggle];

    if (collapse) {
      wrapperClass.push(styles['wrapper-collapse']);
      toggleClass.push(styles['toggle-collapse']);
    }

    return (
      <div className={wrapperClass.join(' ')}>
        <header>
          <nav className={styles.nav}>
            <ul className={styles.tabs}>{this.renderTabs()}</ul>
            <hr className={[styles.highlight, styles[`highlight-tab${this.state.currentTab}`]].join(' ')} />
          </nav>
          <button
            aria-label="切換顯示"
            className={toggleClass.join(' ')}
            data-anue-ga-event={`${Variation.stockChannelName},新聞列表 click,收合,proj`}
            onClick={() => {
              this.setState({ collapse: !this.state.collapse });
            }}
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Tab',
              section: '投資組合',
              clickItem: '收合',
            })}
          />
        </header>
        <div
          className={styles.content}
          style={{
            display: collapse ? 'none' : 'block',
          }}
        >
          {this.renderTabContent()}
        </div>
      </div>
    );
  }
}

export default News;
