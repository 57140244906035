import globalConfig from './globalConfig';

const {
  googleAnalyticsId,
  googleAnalyticsIdForCross,
  googleAnalyticsIdForProj,
  googleAnalyticsIdForGlobal,
  googleTagManagerId,
  googleTagManagerContainerId,
  googleTagManagerContainerIdForProj,
  googleTagManagerContainerIdForGlobal,
} = globalConfig;

export default {
  gaId: googleAnalyticsId,
  gaIdForCross: googleAnalyticsIdForCross,
  gaIdForProj: googleAnalyticsIdForProj,
  gaIdForGlobal: googleAnalyticsIdForGlobal,
  gtmId: googleTagManagerId,
  gtmContainerId: googleTagManagerContainerId,
  gtmContainerIdForProj: googleTagManagerContainerIdForProj,
  gtmContainerIdForGlobal: googleTagManagerContainerIdForGlobal,
};
