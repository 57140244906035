import { useState, useEffect } from 'react';
import Eventy from 'anue-fe-sdk/eventy';

const event = new Eventy();
let initState: UIState = {
  video: {
    visible: false,
  },
  aside: {
    tabIndex: 0,
  },
  esun: {
    tab: '',
  },
};

export function dispatch(action: UIAction) {
  event.emit('dispatch', action);
}

function reducer(state = initState, action: UIAction) {
  switch (action.type) {
    case 'video':
    case 'aside':
    case 'esun':
      return {
        ...state,
        [action.type]: action.payload,
      };
    default:
      return state;
  }
}

export default function useUI(): [UIState, typeof dispatch] {
  const [ui, setState] = useState<UIState>(initState);

  useEffect(() => {
    const remove = event.on('dispatch', action => {
      const nextState = {
        ...ui,
        ...reducer(initState, action),
      };
      initState = nextState;
      setState(nextState);
    });

    return () => {
      remove();
    };
  }, []);

  return [ui, dispatch];
}
