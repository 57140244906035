import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { wallyDefaultTagsSelector } from '../../redux/selectors/wally';
import { visitorIdSelector } from '../../redux/selectors/visitorId';
import Wally from './Wally';

const mapStateToProps = () => {
  const selector = createStructuredSelector({
    defaultTags: wallyDefaultTagsSelector,
    visitorId: visitorIdSelector,
  });

  return state => selector(state);
};

export default connect(mapStateToProps)(Wally);
