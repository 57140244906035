import * as React from 'react';
import getty from 'anue-fe-sdk/getty';
import pad from 'utils/pad';
import globalConfig from 'constant/globalConfig';
import Variation from 'constant/variation';
import { sendEventLog, SEND_PROJ_TARGET } from 'utils/analytics';
import { getDeviceInfo } from 'hooks/device';
import { dispatch } from 'hooks/ui';
import styles from './news-list.scss';
import WayPoint from 'components/WayPoint/WayPoint';
import gaDataset from 'fe-common-library/dest/utils/gaDataset';
import sendDataLayer from 'fe-common-library/dest/utils/sendDataLayer';

interface NewsListProps {
  type: string;
  data: NewsUIProps[];
  video?: boolean;
  isLoaded: boolean;
  onNextPage: () => void;
  renderAdsAtIndex?: (index: number) => any;
}

const Label = {
  realtime: '即時新聞',
  related: '相關新聞',
  video: '影音新聞',
};

const deviceInfo = getDeviceInfo();

interface NewsItemProps {
  data: NewsUIProps;
  label: string;
  logo?: string;
  isVideo?: boolean;
}

const NewsIdSelector = ['data', 'id'];
const comparator = (a, b) => getty(a, NewsIdSelector) === getty(b, NewsIdSelector);

function renderDate(date: Date) {
  const now = new Date();
  const today = new Date(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`).getTime();

  if (date.getTime() < today) {
    return `${pad(date.getMonth() + 1, 2, '0')}/${pad(date.getDate(), 2, '0')}`;
  }
  return `${pad(date.getHours(), 2, '0')}:${pad(date.getMinutes(), 2, '0')}`;
}

function NewsItem({ data, label, isVideo, logo }: NewsItemProps) {
  const date = new Date((data.publishedAt || 0) * 1000);
  const rightElement = (
    // @ts-ignore
    <div
      className={styles.thumbnail}
      video={isVideo ? '1' : '0'}
      style={{
        background: `url(${data.src}) center no-repeat`,
        backgroundSize: 'cover',
      }}
    />
  );

  const href = isVideo ? `https://www.youtube.com/watch?v=${data.id}` : `${globalConfig.newsUrl}/news/id/${data.id}`;

  return (
    <a
      key={data.id}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      data-anue-ga-event={`${Variation.stockChannelName},${label}清單 click,${data.title},proj`}
      onClick={e => {
        if (isVideo && deviceInfo.layout === 'desktop') {
          e.preventDefault();
          dispatch({
            type: 'video',
            payload: {
              url: href,
              isLiTV: data.id === 'litv',
              channelTitle: data.channelTitle,
              visible: true,
            },
          });
        }
        sendEventLog('新聞', 'Article', label);
        sendDataLayer({
          dataPrefix: SEND_PROJ_TARGET,
          eventName: 'Click_News',
          section: label,
          clickItem: `${data.id}_${data.title}`,
        });
      }}
      className={`${styles.item} row`}
      {...gaDataset({
        dataPrefix: SEND_PROJ_TARGET,
        eventName: 'Click_News',
        section: label,
        clickItem: `${data.id}_${data.title}`,
      })}
    >
      <span className="col">
        <h3>{data.title}</h3>
        <time className="row">
          {logo ? (
            <img src={logo} alt={`${data.id}`} />
          ) : (
            isVideo && <img src={require('assets/icon-youtube-off.svg')} />
          )}
          {data.isLive ? (
            <div className={styles.live}>LIVE</div>
          ) : (
            data.publishedAt && <span>{data.isLive ? '直播' : renderDate(date)}</span>
          )}
          <span>{data.channelTitle || ''}</span>
        </time>
      </span>
      {rightElement}
    </a>
  );
}

export const NewsItemMemo = React.memo((props: NewsItemProps) => <NewsItem {...props} />, comparator);

function NewsList({ data, type, renderAdsAtIndex, isLoaded, video, onNextPage }: NewsListProps) {
  function renderList() {
    const label = Label[type];

    if (isLoaded && data.length === 0) {
      return <div className={styles.empty}>此商品暫無相關新聞</div>;
    }

    return data.map((d, i) => {
      return (
        <React.Fragment key={`label-${i}`}>
          {renderAdsAtIndex && renderAdsAtIndex(i)}
          <NewsItemMemo data={d} label={label} isVideo={video} />
        </React.Fragment>
      );
    });
  }

  return (
    <>
      {renderList()}
      <WayPoint onEnter={onNextPage} />
    </>
  );
}

export default NewsList;
