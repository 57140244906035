import React, { PureComponent } from 'react';
import styles from './AgreementPage.scss';
import AgreementContent from './AgreementContent';

const Header = () => {
  return (
    <header className={styles['header-wrapper']}>
      <div className={styles['logo-wrapper']}>
        <a href="http://www.cnyes.com/" className={styles.logo} />
        <div className={styles['vertical-bar']} />
        <div className={styles.title}>會員條款</div>
      </div>
    </header>
  );
};

class AgreementPage extends PureComponent {
  render() {
    return (
      <div>
        <Header />
        <AgreementContent />
      </div>
    );
  }
}

export default AgreementPage;
