import { ChartingLibraryWidgetOptions } from './vendor/charting_library/charting_library.min';

export interface WidgetConfigBuilderArgs {
  containerId: string;
  symbol: string;
  datafeed: DataFeedAbstract;
  debug: boolean;
}

function widgetConfig({
  containerId,
  symbol,
  datafeed,
  debug = false
}): ChartingLibraryWidgetOptions {
  return {
    symbol,
    timeframe: '6m',
    time_frames: [
      { text: '1d', resolution: '1', description: '當日即時' },
      { text: '6m', resolution: 'D', description: '6M' },
      { text: '3y', resolution: 'W', description: 'W' },
      { text: '5y', resolution: 'M', description: 'M' }
    ],
    interval: 'D',
    // @ts-ignore
    datafeed,
    // @ts-ignore
    container_id: containerId,
    library_path: '/static/tv_static/',
    // @ts-ignore
    locale: 'zh_TW',
    timezone: 'Asia/Taipei',
    drawings_access: { type: 'black', tools: [{ name: 'Regression Trend' }] },
    disabled_features: [
      'use_localstorage_for_settings',
      'header_symbol_search',
      'header_saveload',
      'header_resolutions',
      'header_undo_redo',
      'header_interval_dialog_button',
      'symbol_info',
      'display_market_status'
    ],
    enabled_features: [
      'move_logo_to_main_pane',
      'keep_left_toolbar_visible_on_small_screens'
    ],
    charts_storage_url: 'http://saveload.tradingview.com',
    charts_storage_api_version: '1.1',
    client_id: 'tradingview.com',
    user_id: 'public_user_id',
    fullscreen: false,
    autosize: true,
    loading_screen: {
      backgroundColor: '#fff'
    },
    // override area chart line and fill color
    overrides: {
      'mainSeriesProperties.candleStyle.upColor': '#d75442',
      'mainSeriesProperties.candleStyle.downColor': '#6ba583',
      'mainSeriesProperties.candleStyle.drawBorder': true,
      'mainSeriesProperties.candleStyle.borderUpColor': '#d75442',
      'mainSeriesProperties.candleStyle.borderDownColor': '#6ba583',
      'mainSeriesProperties.hollowCandleStyle.upColor': '#d75442',
      'mainSeriesProperties.hollowCandleStyle.downColor': '#6ba583',
      'mainSeriesProperties.candleStyle.wickUpColor': '#AAA',
      'mainSeriesProperties.candleStyle.wickDownColor': '#AAA',
      'mainSeriesProperties.barStyle.upColor': '#d75442',
      'mainSeriesProperties.barStyle.downColor': '#6ba583',
      'mainSeriesProperties.haStyle.upColor': '#d75442',
      'mainSeriesProperties.haStyle.downColor': '#6ba583',
      'mainSeriesProperties.showPriceLine': false,

      'mainSeriesProperties.minTick': '100',
      'symbolWatermarkProperties.color': 'rgba(0, 0, 0, 0)',
      'paneProperties.topMargin': 10,
      'paneProperties.bottomMargin': 10,
      'timeScale.rightOffset': 0
      // 外匯的色系
      // 'mainSeriesProperties.lineStyle.color': '#e03f19',
      // 'mainSeriesProperties.lineStyle.linewidth': 1,
      // 'mainSeriesProperties.areaStyle.color1': '#e03f19',
      // 'mainSeriesProperties.areaStyle.color2': '#e03f19',
      // 'mainSeriesProperties.areaStyle.linecolor': '#e03f19',
      // 'mainSeriesProperties.areaStyle.priceSource': 'close',
    },
    studies_overrides: {
      'volume.volume.color.0': '#6ba583',
      'volume.volume.color.1': '#d75442',
      // 外匯的色系
      // 'volume.volume.color.0': '#7ed321',
      // 'volume.volume.color.1': '#e03f19',
      'volume.volume.transparency': 70,
      'volume.show ma': false
    },
    debug: false
  };
}

export default widgetConfig;
