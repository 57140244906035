import React, { PropTypes } from 'react';

const StaticSizeBtn = props => {
  const { className, name, width, height } = props;
  const size = { width, height };
  const _onClick = () => {
    props.onClick(size, name);
  };

  return <button className={className} onClick={_onClick} />;
};

StaticSizeBtn.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

StaticSizeBtn.defaultProps = {
  className: undefined,
};

StaticSizeBtn.displayName = 'StaticSizeBtn';

export default StaticSizeBtn;
