import React, { Component, PropTypes } from 'react';
import MarketApp from 'components/MarketApp/MarketApp';
import { CookiesProvider } from 'react-cookie';
import GlobalConfig from 'constant/globalConfig';
import { locationShape, matchShape } from 'utils/propTypes';
import { pageview, sendEventLog } from '../../utils/analytics';
import AnalyticsWrapper from '../../components/AnalyticsWrapper/AnalyticsWrapper';
import DFPProviderWrapper from '../../components/DFP/DFPProviderWrapper';
import HelmetWithGA from '../../components/HelmetWithGA/HelmetWithGA';
import { SITE_TITLE } from '../../helpers/meta';
import { authType } from '../../utils/propTypes';
import './App.scss';
// eslint-disable-next-line
const OTAC = anue.shared.get('packages.otac');

OTAC.setServer(GlobalConfig.loginUrl);

class App extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    match: matchShape.isRequired,
    loadVisitorId: PropTypes.func.isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      location: locationShape.isRequired,
    }).isRequired,
    location: locationShape.isRequired,
  };

  static defaultProps = {
    children: undefined,
  };

  static contextTypes = {
    auth: authType,
  };

  componentDidMount() {
    this.props.loadVisitorId();
  }

  render() {
    const { match, location, history } = this.props;

    return (
      <CookiesProvider>
        <DFPProviderWrapper>
          <AnalyticsWrapper sendEvent={sendEventLog}>
            <HelmetWithGA
              config={{ title: SITE_TITLE }}
              location={location}
              pageview={pageview}
              ignoreTitle={''}
              ignoreWhenFirstChange={false}
            />
            <MarketApp location={location} match={match} history={history}>
              {this.props.children}
            </MarketApp>
          </AnalyticsWrapper>
        </DFPProviderWrapper>
      </CookiesProvider>
    );
  }
}

export default App;
