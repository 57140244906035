import { PropTypes } from 'react';

export const searchMetaType = PropTypes.shape({
  current_page: PropTypes.number,
  from: PropTypes.number,
  last_page: PropTypes.number,
  next_page_url: PropTypes.string,
  prev_page: PropTypes.number,
  prev_page_url: PropTypes.string,
  to: PropTypes.number,
  total: PropTypes.number,
});

const imageType = PropTypes.shape({
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
});

export const locationShape = PropTypes.shape({
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
  hash: PropTypes.string,
  state: PropTypes.object,
});

export const matchShape = PropTypes.shape({
  params: PropTypes.object.isRequired,
  isExact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const routerShape = PropTypes.shape({
  children: PropTypes.node,
  history: PropTypes.object.isRequired,
  staticContext: PropTypes.object,
});

export const coverSrcType = PropTypes.shape({
  xxl: imageType,
  xl: imageType,
  l: imageType,
  m: imageType,
  s: imageType,
  xs: imageType,
});

export const newsType = PropTypes.shape({
  newsId: PropTypes.number.isRequired,
  title: PropTypes.string,
  publishAt: PropTypes.number,
  hasCoverPhoto: PropTypes.number,
  coverSrc: coverSrcType,
});

export const videoType = PropTypes.shape({
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string,
  publishedAt: PropTypes.number,
  thumbnails: PropTypes.object,
  isLive: PropTypes.number,
});

export const videoOnPlayingType = PropTypes.shape({
  url: PropTypes.string.isRequired,
});

export const symbolInfoPropsType = PropTypes.object;

export const naviMenuListType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  img: PropTypes.string,
  onClickCallBack: PropTypes.func,
  redirect: PropTypes.object,
});

export const userProfileType = PropTypes.shape({
  uid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nickname: PropTypes.string,
  email: PropTypes.string,
  avatar: PropTypes.string.isRequired,
  gender: PropTypes.oneOf(['', 'male', 'female']),
  vip: PropTypes.oneOf([0, 1]),
});

export const authType = PropTypes.shape({
  channel: PropTypes.func.isRequired,
  renew: PropTypes.func.isRequired,
  ready: PropTypes.func.isRequired,
  getCredentialContext: PropTypes.func.isRequired,
  connectProvider: PropTypes.func.isRequired,
  onStateChange: PropTypes.func.isRequired,
  clearCredentials: PropTypes.func.isRequired,
});

export const requestType = PropTypes.func;

export const messageProps = {};

export const imageSizeEnum = PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', 'xxl', 'o']);

export const spinnerCustomStyleType = PropTypes.object;
