import getty from 'anue-fe-sdk/getty';
import { useReducer } from 'react';
import Variation from 'constant/variation';
import globalConfig from 'constant/globalConfig';
import api from 'helpers/api';

type NewsStore = Record<string, NewsUIProps[]>;
type GetNextPage = () => void;
type UseNews = [NewsStore, GetNextPage, (symbol: string) => Promise<GetNextPage>, () => Promise<GetNextPage>];

const CacheTTL = 45000;

function transformVideoNewsToUIProp(data: VideoNewsData) {
  return {
    id: data.vendor_video_id,
    src: getty(data, ['thumbnails', 'default']) || `https://i.ytimg.com/vi/${data.vendor_video_id}/default.jpg`,
    title: data.title,
    channelTitle: data.channel_title,
    publishedAt: data.published_at,
    isVideo: true,
    isLive: data.is_live,
  } as NewsUIProps;
}

function transformNewsToUIProp(data: NewsData) {
  return {
    id: data.newsId,
    src: getty(data, ['coverSrc', 's', 'src']),
    title: data.title,
    channelTitle: data.channelTitle,
    publishedAt: data.publishAt,
  } as NewsUIProps;
}

enum NewsActionType {
  PUT,
}

interface NewsAction {
  type: NewsActionType;
  key: string;
  payload: NewsUIProps[];
}

function reducer(state: NewsStore = {}, action: NewsAction): NewsStore {
  switch (action.type) {
    case NewsActionType.PUT:
      return {
        ...state,
        [action.key]: [...(state[action.key] || []), ...action.payload],
      };
    default:
      return state;
  }
}

export default function useNews(): UseNews {
  const [news, dispatch] = useReducer(reducer, {});

  async function fetchRelatedNews(symbol: string) {
    const ctx = await api<NewsData[]>(
      {
        url: Variation.news.related(symbol),
        ttl: CacheTTL,
        params: {
          page: 1,
          limit: 10,
        },
      },
      ['items', 'data'],
      data => {
        dispatch({
          type: NewsActionType.PUT,
          key: symbol,
          payload: [...(news[symbol] || []), ...data.map(transformNewsToUIProp)],
        });
      }
    );
    if (ctx.error) {
      dispatch({
        type: NewsActionType.PUT,
        key: symbol,
        payload: [],
      });
    }
    return ctx.next;
  }

  async function fetchRealtimeNews() {
    await api<NewsData[]>(
      {
        url: Variation.realtimeNews,
        ttl: CacheTTL,
      },
      ['items', 'data'],
      data => {
        dispatch({
          type: NewsActionType.PUT,
          key: 'realtime',
          payload: [...(news.realtime || []), ...data.map(transformNewsToUIProp)],
        });
      }
    );
  }

  async function fetchVideoNews() {
    const end = Math.round(Date.now() / 1000);
    const stat = end - 60 * 60 * 24 * 30;
    const ctx = await api<VideoNewsData[]>(
      {
        url: `${globalConfig.videoAPI}/video/api/v1/videos`,
        ttl: CacheTTL,
        params: {
          page: 1,
          start_at: stat,
          end_at: end,
        },
      },
      ['items', 'data'],
      data => {
        dispatch({
          type: NewsActionType.PUT,
          key: 'video',
          payload: [...(news.video || []), ...data.map(transformVideoNewsToUIProp)],
        });
      }
    );
    return ctx.next;
  }

  return [news, fetchRealtimeNews, fetchRelatedNews, fetchVideoNews];
}
