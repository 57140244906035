import { createStore as _createStore, applyMiddleware } from 'redux';
import createMiddleware from './middleware/clientMiddleware';

export default function createStore(history, data) {
  const middleware = [createMiddleware()];
  const reducer = require('./modules/reducer').default(history); // eslint-disable-line global-require
  const store = applyMiddleware(...middleware)(_createStore)(reducer, data);

  return store;
}
