import React, { Component } from 'react';
import Rnd from 'react-rnd';
import cloneDeep from 'lodash.clonedeep';
import cx from 'classnames';
import idx from 'idx';
import { dispatch } from 'hooks/ui';
import { AdSlotWithWally } from 'cnyes-dfp';
import styles from './VideoBlock.scss';
import StaticSizeBtn from './StaticSizeBtn';
import { sendEventLog } from '../../utils/analytics';
import { videoOnPlayingType } from '../../utils/propTypes';
import AdProfiles from '../DFP/AdProfiles';

class VideoBlock extends Component {
  static propTypes = {
    video: videoOnPlayingType,
  };

  static defaultProps = {
    video: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      url: (props.video && props.video.url) || '',
      channelTitle: (props.video && props.video.channelTitle) || '',
      isVideoBlockVisible: true,
      isDragging: false,
      isResizing: false,
      staticSize: 's',
      // 1 for thumbnail and 2 for player
      displayMode: 1,
    };
    this.initRnd = {
      x: 0,
      y: 0,
      width: 320,
      height: 205,
    };
    this.constraintsRnd = {
      minHeight: 132,
      minWidth: 172,
      maxWidth: 1095,
      maxHeight: 705,
    };
    this.eventCallbacks = {
      onResizeStart: this.onResizeStart,
      onResizeStop: this.onResizeStop,
      onDragStart: this.onDragStart,
      onDragStop: this.onDragStop,
    };
    this.initIframe = {
      width: this.initRnd.width - 12,
      height: this.initRnd.height - 42,
    };
    this.initStaticSizeClass = {
      s: [styles['size-s']],
      m: [styles['size-m']],
      l: [styles['size-l']],
    };
    this.player = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      url: nextProps.video && nextProps.video.url,
      channelTitle: nextProps.video && nextProps.video.channelTitle,
      isVideoBlockVisible: true,
    });
  }

  onResizeStart = () => {
    this.setState({
      isResizing: true,
      staticSize: '',
    });
  };

  onResizeStop = () => {
    this.setState({
      isResizing: false,
    });
  };

  onDragStart = () => {
    this.setState({
      isDragging: true,
    });
  };

  onDragStop = () => {
    this.setState({
      isDragging: false,
    });
  };

  setStaticSize = (size, sizeName) => {
    this.rnd.updateSize({
      width: size.width,
      height: size.height,
    });
    this.setState({
      staticSize: sizeName,
    });
  };

  closeVideoBlock = () => {
    dispatch({
      type: 'video',
      payload: {
        visible: false,
      },
    });
    this.setState({
      url: '',
      channelTitle: '',
      isVideoBlockVisible: false,
    });
  };

  closeVideoAd = () => {
    this.setState({
      shouldDisplayVideoAd: false,
    });
  };

  handlePlayerStart = () => {
    const { channelTitle } = this.state;

    sendEventLog('影音', 'Click', channelTitle);
  };

  renderEmbededVideo = url => {
    const { displayMode } = this.state;
    const videoId = idx(url.split('v='), p => p[1]);
    const isLiTV = idx(this.props, p => p.video.isLiTV);

    let playerOrThumbnail = null;

    if (isLiTV) {
      playerOrThumbnail = (
        <iframe
          title="LiTV"
          src="https://player.svc.litv.tv/v3/ppnif.html?s=Y3k="
          style={{
            width: '100%',
            height: '100%',
          }}
          frameBorder="0"
          scrolling="no"
          allowFullScreen
        />
      );
    } else if (videoId) {
      switch (displayMode) {
        case 1:
          playerOrThumbnail = (
            <div
              className={[styles['player-content'], styles['player-thumbnail']].join(' ')}
              onMouseOver={() => {
                this.setState({
                  displayMode: 2,
                });
              }}
              style={{
                backgroundImage: `url(https://i.ytimg.com/vi/${videoId}/hqdefault.jpg)`,
              }}
            >
              <svg
                height="48"
                width="68"
                style={{
                  backgroundColor: 'rgba(0,0,0,0.75)',
                  broderRadius: '8px',
                }}
              >
                <path d="M 24 12 L 48 24 L 24 36 Z" fill="white" />
              </svg>
            </div>
          );
          break;
        default:
          playerOrThumbnail = (
            <iframe
              title={`player-${videoId}`}
              className={styles['player-content']}
              frameBorder="0"
              allowFullScreen="1"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
            />
          );
          break;
      }
    }

    return <div className={styles['player-wrapper']}>{playerOrThumbnail}</div>;
  };

  render() {
    const { video } = this.props;
    const { isVideoBlockVisible, isDragging, isResizing, staticSize, url, shouldDisplayVideoAd } = this.state;

    if (!video && shouldDisplayVideoAd) {
      // DFP determines visibility of the ad by changing the style of .video-ad-wrapper.
      return (
        <div className={cx([styles['block-wrapper'], 'video-ad-wrapper'])}>
          <div className={cx(['video-ad-container', styles['video-block']])}>
            <button className={styles['btn-dialog-close']} onClick={this.closeVideoAd} />
            <AdSlotWithWally profile={AdProfiles.liveStreamBlockVideoAd} key="ad-live-stream-block" />
          </div>
        </div>
      );
    }

    const { autoplay, muted } = video;
    const videoBlockClass = isVideoBlockVisible
      ? [styles['video-block']]
      : [styles['video-block'], styles['video-block-hidden']];

    const staticSizeClass = cloneDeep(this.initStaticSizeClass);
    const staticSizeRatio = 0.65; // height/width
    const getStaticHeight = width => width * staticSizeRatio;

    if (staticSize) {
      staticSizeClass[staticSize].push(styles['on-size']);
    }

    let blockWrapperStyle = {};
    let overLayerStyle = {};

    if (isDragging || isResizing) {
      // create an area below the video block for boundings
      blockWrapperStyle = {
        visibility: 'visible',
      };

      // for avoiding triggering iframe
      overLayerStyle = {
        display: 'block',
      };
    }

    return (
      <div className={styles['block-wrapper']} style={blockWrapperStyle}>
        <Rnd
          ref={ref => {
            this.rnd = ref;
          }}
          className={cx(videoBlockClass)}
          initial={this.initRnd}
          bounds={'parent'}
          zIndex={10}
          lockAspectRatio
          {...this.eventCallbacks}
          {...this.constraintsRnd}
        >
          <div className={styles['btn-dialog-size']}>
            <StaticSizeBtn
              className={cx(staticSizeClass.s)}
              name={'s'}
              width={320}
              height={getStaticHeight(320)}
              onClick={this.setStaticSize}
            />
            <StaticSizeBtn
              className={cx(staticSizeClass.m)}
              name={'m'}
              width={438}
              height={getStaticHeight(438)}
              onClick={this.setStaticSize}
            />
            <StaticSizeBtn
              className={cx(staticSizeClass.l)}
              name={'l'}
              width={732}
              height={getStaticHeight(732)}
              onClick={this.setStaticSize}
            />
          </div>
          <button className={styles['btn-dialog-close']} onClick={this.closeVideoBlock} />
          {this.renderEmbededVideo(url, autoplay, muted)}
          <div className={styles['over-layer']} style={overLayerStyle} />
        </Rnd>
      </div>
    );
  }
}

export default VideoBlock;
