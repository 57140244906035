export const SITE_TITLE = '股市talk | Anue鉅亨';

const genTitle = metaTitle => {
  if (!metaTitle) {
    return SITE_TITLE;
  }

  return `${metaTitle} | ${SITE_TITLE}`;
};

export default {
  getSymbolMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 即時股價走勢`);
    const content = unescape(`${symbol} 個股相關新聞、即時股價、當日走勢、20年技術分析K線圖`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
  getInvestorMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 三大法人買賣超`);
    const content = unescape(`${symbol} 外資、投信、自營商，三大法人進出、法人持股相關數據追蹤`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
  getMarginMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 融資融券餘額`);
    const content = unescape(`${symbol} 融資餘額、融券餘額、融資斷頭、融券軋空、融資使用率，散戶相關數據追蹤`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
  getRevenueMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 營業收入`);
    const content = unescape(`${symbol} 每月營收、合併營收、累計營收、營收成長、衰退數據追蹤`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
  getEpsMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 每股盈餘 (EPS)`);
    const content = unescape(`${symbol} 每股稅後盈餘 (EPS) 財報獲利查詢`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
  getIncomeMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 損益表`);
    const content = unescape(`${symbol} 損益表財報分析`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
  getBalanceMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 資產負債表`);
    const content = unescape(`${symbol} 資產負債表財報分析`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
  getRatiosMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 財務比率`);
    const content = unescape(`${symbol} 毛利率、股東權益報酬率(ROE)、負債比率財報分析`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
  getInfoMeta(symbolName, symbolCode) {
    const symbol = `${symbolName} (${symbolCode})`;
    const title = genTitle(`${symbol} 個股基本資料`);
    const content = unescape(`${symbol} 公司資料、主要業務、股本、本益比、淨值比等個股與市場資訊查詢`) || '';
    const description = content.replace(/(<([^>]+)>)/gi, '').substr(0, 150);

    const meta = [
      { name: 'description', content: description },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
    ];

    return {
      title,
      meta,
    };
  },
};
