import axios, { AxiosInstance } from 'axios';
import { isTwStockChannel } from 'constant/variation';

const __SERVER__ = false;

const DEFAULT_PROJECT_NAME = 'fe-stock';
const DEFAULT_APP_NAME = 'stock';

/**
 * @param {Object} defaultConfig - default config for axios
 * @param {string} defaultConfig.baseURL - baseURL for axios
 * @param {Object} param - config for customized functions
 * @param {string} param.hostname - hostname to be filled in user-agent header
 * @param {string} param.projectName - project name to be filled in user-agent header
 * @param {string} param.appName - app name for auth request
 * @returns {Function} api client
 */
function apiClientFactory(
  defaultConfig, // { baseURL }
  { hostname = '', projectName = DEFAULT_PROJECT_NAME, appName = DEFAULT_APP_NAME } = {}
): AxiosInstance {
  const userAgentHeader = {};

  if (__SERVER__) {
    const agentUa = 'axios'; // TODO determine axios version
    const ua = `${agentUa} ${projectName} ${hostname}`;

    userAgentHeader['User-Agent'] = ua;
  }

  const client = axios.create({
    headers: {
      ...userAgentHeader,
      ...defaultConfig.headers,
      'X-System-Kind': isTwStockChannel ? 'STOCK' : 'GLOBAL_STOCK',
      'X-platform': 'WEB',
      ...(appName ? { 'X-CNYES-APP': appName } : {}),
    },
    ...defaultConfig,
  });

  return client;
}

export default apiClientFactory;
