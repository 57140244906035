import { IChartingLibraryWidget } from './vendor/charting_library/charting_library.min';

export interface TradingViewButton {
  click: (fn: () => void) => TradingViewButton;
  text: (val: string) => TradingViewButton;
  outerBackground: (color: string) => TradingViewButton;
  style: (styles: Record<string, string>) => TradingViewButton;
}
/**
 * Aa hyper script wrapper for setting buttons created by TradingView Widget.
 *
 * @param tvInstance A TradingView Widget instance
 */
export default function createButton(
  tvInstance: IChartingLibraryWidget
): TradingViewButton {
  const button: HTMLDivElement = tvInstance.createButton()[0];
  // @ts-ignore
  const parent: HTMLDivElement = button.parentElement.parentElement;
  parent.style.margin = '0';

  function setStyle(el: HTMLDivElement, styles: Record<string, string>) {
    for (const s in styles) {
      el.style[s] = styles[s];
    }
  }

  return {
    click(fn) {
      button.addEventListener('click', fn);
      return this;
    },
    text(val) {
      button.textContent = val;
      return this;
    },
    style(styles) {
      setStyle(button, styles);
      return this;
    },
    outerBackground(color) {
      parent.style.backgroundColor = color;
      return this;
    }
  };
}
