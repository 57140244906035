import * as React from 'react';
import styles from './ShareFB.scss';

interface ShareFbProps {
  symbol: string;
}

function ShareFb({ symbol }: ShareFbProps) {
  const host = `${window.location.protocol}//${window.location.host}`;

  return (
    <a
      href={`https://www.facebook.com/sharer/sharer.php?u=${host}/${symbol}`}
      target="_blank"
      className={styles['fb-share-button']}
      data-ga-category="Facebook Share Button"
      data-ga-action="click"
    >
      <span className={styles['fb-logo']} />
      <span className={styles['fb-share-text']}>分享</span>
    </a>
  );
}

export default React.memo((props: ShareFbProps) => <ShareFb {...props} />, (prev, next) => prev.symbol === next.symbol);
