import * as React from 'react';
import { useRef, useEffect, useCallback, useReducer } from 'react';
import useSearch from 'hooks/search';
import useAuth, { isLogin } from 'hooks/auth';
import { getDeviceInfo } from 'hooks/device';
import Variation from 'constant/variation';
import { sendEventLog, SEND_PROJ_TARGET } from 'utils/analytics';
import { sendMessage, onReply } from 'hooks/chat';

import styles from './chat-input.scss';
import SearchResult from 'components/SearchResult/SearchResult';
import ChatInputMenu from 'components/ChatInputMenu';
import sendDataLayer from 'fe-common-library/dest/utils/sendDataLayer';

const tagRgx = /\$[^ \u200B]+$/i;
const device = getDeviceInfo();
const MessageTemplates = {
  Empty: '',
  Placeholder: `<div class="${styles['chat-empty']}">輸入股票請用 $</div>`,
  WBR: '&#8203;',
};

function setEndOfContenteditable(contentEditableElement) {
  let range;
  let selection;
  if (document.createRange) {
    // Firefox, Chrome, Opera, Safari, IE 9+
    range = document.createRange(); // Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); // Select the entire contents of the element with the range
    range.collapse(false); // collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection(); // get the selection object (allows you to change selection)
    selection.removeAllRanges(); // remove any selections already made
    selection.addRange(range); // make the range you have just created the visible selection
  }
}

/**
 * Filter invalid characters here.
 */
function filterMessage(msg) {
  return msg.replace(/\u00A0/g, String.fromCharCode(32));
}

/**
 * ChatInput internal state
 */
interface ChatInputState {
  html: string;
  selectedIndex: number;
  reply: any;
}

const initialState: ChatInputState = {
  html: MessageTemplates.Placeholder,
  selectedIndex: 0,
  reply: null,
};

type ChatInputActions = { type: 'SET_REPLY'; data: any } | { type: 'SET_SELECTED'; data: number };

function chatInputReducer(state = initialState, action: ChatInputActions) {
  switch (action.type) {
    case 'SET_REPLY':
      return { ...state, reply: action.data };
    case 'SET_SELECTED':
      return { ...state, selectedIndex: action.data };
  }
  return state;
}

function ChatInput() {
  const [state, dispatch] = useReducer(chatInputReducer, initialState);
  const el = useRef<HTMLDivElement | null>(null);
  const [auth, login] = useAuth();
  const [result, search, clearSearch] = useSearch();
  const searchContext = useRef<SearchContext>({
    keyword: '',
    next: () => {},
  });
  const tags = useRef<Record<string, string>>({});
  const input = el.current;
  const { html, selectedIndex, reply } = state;
  const hint = useRef<HTMLDivElement | null>(null);

  let height = result.length > 0 ? Math.min(result.length * 45, 250) : 0;

  // if (device.layout === 'mobile') {
  //   height = Math.min(window.innerHeight - 40, height);
  // }

  useEffect(() => {
    const remove = onReply(data => {
      if (el.current) {
        el.current.focus();
      }
      dispatch({
        type: 'SET_REPLY',
        data,
      });
    });
    return () => remove();
  }, []);

  // when HTML template updates, move caret to end of content.
  // Generally HTML content only updates when user selects a tag.
  useEffect(() => {
    const selection = document.getSelection();

    if (selection && selection.focusNode && el.current) {
      setEndOfContenteditable(el.current);
    }
  }, [html]);

  /**
   * Reset search result, use this function when a tag searching
   * is finished. The search result window will become invisible
   * after calling this function.
   */
  function reset() {
    searchContext.current = {
      keyword: '',
      next: () => {},
    };
    clearSearch();
    dispatch({
      type: 'SET_SELECTED',
      data: 0,
    });
  }

  function vanillaSetHTML(nextHtml: string, jumpToEnd: boolean = false) {
    if (el.current) {
      el.current.innerHTML = nextHtml;
    }
    if (jumpToEnd) {
      setEndOfContenteditable(el.current);
    }
  }

  function sanitizePastedContent() {
    const target = el.current;

    if (target) {
      // immediately sanitize will not work
      setTimeout(() => {
        // remove all tags then restore symbol tags from sanitized text
        let str = target.innerText;

        for (const tag in tags.current) {
          str = str.replace(tag, `&#8203;<a>$${tag}</a>&#8203;`);
        }
        vanillaSetHTML(str, true);
      }, 200);
    }
  }

  function handleSelect(num: number) {
    dispatch({
      type: 'SET_SELECTED',
      data: Math.min(Math.max(num, 0), result.length - 1),
    });
    if (result.length < selectedIndex + 3) {
      searchContext.current.next();
    }
  }

  /**
   * insert selected tag's HTML into input box.
   */
  function handleTagSelected() {
    if (input) {
      const pure = input.innerHTML.replace(/\<\/?(div|br|p|b|script|link)>/g, '');

      const item = result[selectedIndex];
      if (item) {
        const { symbol, chName } = item;
        const tagKey = chName.replace(/\<\/?(mark)>/g, '');
        const nextHtml = pure.replace(tagRgx, m => {
          return `&#8203;<a>$${tagKey}</a>&#8203;`;
        });
        vanillaSetHTML(nextHtml, true);
        tags.current[`\$${tagKey}`] = symbol;
        reset();
      }
    }
  }

  async function handleKey(e) {
    const hasSearchWindow = result.length > 0;
    if (hint.current) {
      hint.current.style.display = 'none';
    }

    if (hasSearchWindow) {
      switch (e.keyCode) {
        case 38:
          handleSelect(selectedIndex - 1);
          return;
        case 40:
          handleSelect(selectedIndex + 1);
          return;
        case 13:
          handleTagSelected();
          return;
      }
    }
    // Usually when user hits return key, the content-editable div will append
    // a end-line element <div><br/></div>.
    // However, some IME also favors return key for selecting texts.
    // In this case, there will be no <div> append to the input element and we
    // should not submit message as the user is likely want to input more message.
    else if (input && /\<(\/div|\/p|br|\/br)\>$/.test(input.innerHTML) && e.keyCode === 13) {
      submit();
    }
    // when user hit backspace button, check if the caret inside an DOM "A" element
    // if so, remove the element.
    else if (e.keyCode === 8) {
      const selection = document.getSelection();

      if (selection && selection.anchorNode) {
        const node = selection.anchorNode.nodeType === 3 ? selection.anchorNode.parentElement : selection.anchorNode;

        // @ts-ignore
        if (node && node.tagName === 'A') {
          e.target.removeChild(node);
          setEndOfContenteditable(e.target);
        }
      }
    }
    const target = e.target as HTMLDivElement;
    const value = target.innerText;

    // check if current text matches tag pattern
    const matches = String(value).match(tagRgx);

    if (hint.current) {
      // when input end with `$` show hint
      // for FireFox, a \n sometimes followed by `$`
      hint.current.style.display = /\$\n?$/.test(value) ? 'block' : 'none';
    }

    if (matches) {
      // remove empty characters
      const word = String(matches[0]).replace(/[ \n]/g, '');
      if (result.length !== 0) {
        reset();
      }
      if (word) {
        searchContext.current.keyword = word.substr(1, word.length - 1);
        searchContext.current.next = await search(searchContext.current.keyword);
      }
    } else {
      reset();
    }
  }

  /**
   * Format and submit template message to chatroom.
   */
  const submit = useCallback(
    function() {
      if (!input || input.innerHTML === MessageTemplates.Placeholder) {
        return;
      }

      if (input) {
        // remove end line chars
        let message = input.innerText.replace(new RegExp(`[\n${MessageTemplates.Empty}]`, 'g'), '');

        if (message.length > 200) {
          alert('訊息長度不可超過200個字元');
          return;
        }

        // for each tags we've recorded when user input, replace then with
        // markdown link template [$tag](symbol:code:etc)
        for (const k in tags.current) {
          message = message.replace(new RegExp(`\\$${k.replace('$', '')}`, 'g'), m => {
            return tags.current[m] ? `[${k}](${tags.current[k]})` : m;
          });
        }
        input.innerHTML = MessageTemplates.Empty;
        reset();
        tags.current = {};
        message = filterMessage(message);
        if (message) {
          if (reply) {
            message = `[@${reply.profile.name}](${reply.message} ) ${message}`;
          }
          sendMessage(message);
          dispatch({
            type: 'SET_REPLY',
            data: null,
          });
        }
        input.focus();
      }
    },
    [input, reply]
  );

  const isLoggedIn = isLogin();

  if (device.layout === 'mobile') {
    height = Math.min(window.innerHeight - 40, height);
  }

  return (
    <div className={`${styles.container} ${' ' || styles[device.layout]} ${isLoggedIn ? '' : styles.disabled}`}>
      <SearchResult
        reversed={false && device.layout === 'mobile'}
        onClick={handleTagSelected}
        onMouseEnter={handleSelect}
        selectedIndex={selectedIndex}
        data={searchContext.current.keyword ? result || [] : []}
        top={-height}
        onPageEnd={() => {
          searchContext.current.next();
        }}
        height={height}
      />
      <ChatInputMenu />
      {reply && (
        <div
          className={styles.reply}
          onClick={() =>
            dispatch({
              type: 'SET_REPLY',
              data: null,
            })
          }
        >
          回應: {reply.profile.name} ☓
        </div>
      )}
      <div ref={hint} className={styles.hint} data-tid="chat-hint">
        輸入股票代碼 例：${Variation.chat.hintSymbol}
      </div>
      <div
        ref={el}
        onClick={() => {
          if (!isLoggedIn) {
            login('login_chat');
            sendEventLog(Variation.stockChannelName, '聊天室 click', '登入', SEND_PROJ_TARGET);
            sendDataLayer({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click',
              section: '聊天室',
              clickItem: '登入',
            });
          }
        }}
        data-tid="chat-input"
        className={styles.input}
        contentEditable={!!isLoggedIn}
        onKeyUp={handleKey}
        onFocus={e => {
          if (e.target.innerHTML === MessageTemplates.Placeholder) {
            vanillaSetHTML(MessageTemplates.Empty, true);
          }
        }}
        onPaste={sanitizePastedContent}
        onBlur={e => {
          if (e.target.innerText === MessageTemplates.Empty) {
            e.target.innerHTML = MessageTemplates.Placeholder;
          }
        }}
        dangerouslySetInnerHTML={{
          __html: isLoggedIn ? html.replace(/\<\/?(div|br|p|b|script|link)>/g, '') : '請登入開始聊天',
        }}
      />
      <img
        onClick={submit}
        alt="送出訊息"
        style={isLoggedIn ? {} : { pointerEvents: 'none' }}
        className={styles.submit}
        src={require('assets/icon-chatroom-submit.svg')}
      />
    </div>
  );
}

export default ChatInput;
