function extractDomain(url) {
  let domain;
  // find & remove protocol (http, ftp, etc.) and get domain

  if (url.indexOf('://') > -1) {
    domain = url.split('/')[2];
  } else {
    domain = url.split('/')[0];
  }
  // find & remove port number
  domain = domain.split(':')[0];

  return domain;
}

export function getUrlHolder(url) {
  const domain = extractDomain(url);
  let result = '';

  switch (domain) {
    case 'www.youtube.com':
      result = 'Youtube';
      break;
    case 'www.facebook.com':
      result = 'Facebook';
      break;
    default:
      break;
  }

  return result;
}

export function getParameterByName(paraName, url) {
  const name = paraName.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  } else if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getDomainFromHost(host) {
  if (host) {
    const domain = host && host.substring(host.indexOf('.'));

    return domain;
  }

  return undefined;
}
