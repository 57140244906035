export function localStorageSetItem(key, value) {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    // ignore
  }
}

export function localStorageGetItem(key) {
  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    // ignore
  }

  return undefined;
}

export function localStorageRemoveItem(key) {
  try {
    window.localStorage.removeItem(key);
  } catch (e) {
    // ignore
  }
}
