import * as React from 'react';
import { useEffect, useRef } from 'react';
import history from 'utils/history';
import getty from 'anue-fe-sdk/getty';
import useSharedQuote from 'hooks/sharedQuotes';
import useMeta from 'hooks/meta';
import { getProfile } from 'hooks/auth';
import pad from 'utils/pad';
import variation from 'constant/variation';
import FavStar from 'containers/FavStar';
import volFormat from 'utils/vol-format';
// import EsunOpenButton from 'components/EsunOpenButton';
import styles from './style.scss';

interface SymbolInfoProps {
  symbol: string;
}

function SymbolInfo({ symbol }) {
  const userProfile = getProfile();
  const [quotes, , setStaticSymbol] = useSharedQuote();
  const [updateMeta] = useMeta();
  const price = useRef<HTMLSpanElement | null>(null);

  async function onSymbolChanged() {
    const res = await setStaticSymbol(symbol);
    // here's the symbol from API response
    const resolvedSymbol = (res && res.symbol) || variation.quote.defaultSymbol;

    // BACKWARD COMPATIBILITY: when symbol changes check if remote symbol is the same as the one in URL
    // if not, use the symbol from remote instead.
    if (resolvedSymbol !== symbol) {
      history.getHistory().push(`/market/${resolvedSymbol}`);
    }
  }

  // whenever symbol changes, update quote of the symbol and re-config update timer
  useEffect(() => {
    onSymbolChanged();
  }, [symbol]);

  function getTimeString(t: number) {
    if (isNaN(+t) || t <= 0) {
      return '';
    }

    const date = new Date(t * 1000);

    return `${pad(date.getFullYear())}/${pad(date.getMonth() + 1)}/${pad(date.getDate())} ${pad(date.getHours())}:${pad(
      date.getMinutes()
    )}`;
  }

  const symbolInfo = quotes[symbol];
  const name = getty(symbolInfo, ['name']);
  const ch = getty(symbolInfo, ['ch']);

  useEffect(() => {
    updateMeta(quotes[symbol]);
  }, [name]);

  useEffect(() => {
    if (price.current && ch) {
      const next = ch > 0 ? styles['up-hl'] : styles['down-hl'];
      price.current.className = `${price.current.className.replace(next, '')} ${next}`;
      setTimeout(() => {
        if (price.current) {
          price.current.className = price.current.className.replace(next, '');
        }
      }, 500);
    }
  }, [ch]);

  if (!symbolInfo) {
    return <div className={styles['symbol-product-wrapper']} />;
  }
  const chp = getty(symbolInfo, ['chp']);
  const productCode = getty(symbolInfo, ['productCode']);
  const lp = getty(symbolInfo, ['lp']) || getty(symbolInfo, ['prevClosePrice']);
  const vol = getty(symbolInfo, ['volume']);
  const time = getty(symbolInfo, ['tradeTime']);
  const isTWStock = /^t.+:stock$/i.test(symbol);
  let colorClass = '';

  if (ch > 0) {
    colorClass = styles.up;
  } else if (ch < 0) {
    colorClass = styles.down;
  }

  return (
    <div className={styles['symbol-product-wrapper']}>
      <div className={styles.title}>
        <div className={styles.name}>
          <FavStar symbol={symbol} name={name} />
          <h1>
            <span className={styles.code}>{productCode}</span>
            <span className={styles['full-name']}>{name}</span>
          </h1>
        </div>
        <div className={styles.value}>
          <span ref={price} className={`${colorClass} ${styles.price}`} key={new Date().getTime()}>
            {lp}
          </span>
          <span className={`${colorClass} ${styles.change}`}>{ch}</span>
          <span className={`${colorClass} ${styles['change-percent']}`}>{chp}%</span>
          <span className={`${colorClass} ${styles.volume}`}>{volFormat(symbol, vol)}</span>
          {/* {isTWStock && (
            <EsunOpenButton
              isLarge
              userId={userProfile && userProfile.uid}
              cb={() => {
                history.getHistory().push(`/market/${symbol}`);
              }}
            />
          )} */}
        </div>
      </div>
      <div className={styles['trade-time']}>{getTimeString(time)}</div>
    </div>
  );
}

export default React.memo(
  (props: SymbolInfoProps) => <SymbolInfo {...props} />,
  (prev, next) => prev.symbol === next.symbol
);
