import React, { useMemo } from 'react';
import styles from './EsunAbout.scss';
import EsunAboutContent from './EsunAboutContent';

export default function EsunAboutPage() {
  return useMemo(
    () => (
      <div>
        <header className={styles['header-wrapper']}>
          <div className={styles['logo-wrapper']}>
            <a href="http://www.cnyes.com/" className={styles.logo} />
          </div>
        </header>
        <EsunAboutContent />
      </div>
    ),
    []
  );
}
