import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import TradingView from 'components/TradingView';
import Variation from 'constant/variation';
import styles from './StockPanel.scss';
import useSharedQuote from 'hooks/sharedQuotes';
import useDevice, { getDeviceInfo } from 'hooks/device';
import gaDataset from 'fe-common-library/dest/utils/gaDataset';
import { SEND_PROJ_TARGET } from 'utils/analytics';
import generateStockLinkBySymbol from 'utils/linkFactory';

interface StockPanelProps {
  symbol: string;
  main: JSX.Element;
}

const device = getDeviceInfo();
const MainTabs = {
  eps: 'eps',
  balance: 'balance',
  income: 'income',
  ratios: 'ratios',
  margin: 'margin',
  info: 'info',
  revenue: 'revenue',
  '3investor': '3investor',
  analyze: 'analyze',
  more: 'more',
};

function getTabClassName(selected) {
  return styles[selected ? 'selected-tab' : 'tab'];
}

function StockPanel({ symbol, main }: StockPanelProps) {
  const [currentTab, setCurrentTab] = useState<string>(MainTabs.analyze);
  const [deviceInfo] = useDevice();
  const [quotes] = useSharedQuote();

  useEffect(() => {
    const lastPart = location.pathname.split('/').slice(-1)[0];
    const nextTab = MainTabs[lastPart] || MainTabs.analyze;

    if (currentTab !== nextTab) {
      setCurrentTab(nextTab);
    }
  });

  const baseUrl = `/market/${symbol}`;
  const isAnalyze = currentTab === MainTabs.analyze;
  const notAnalyzePages = isAnalyze ? null : main;
  const panelWrapperClass = cx(styles['stock-panel-wrapper'], styles[`wrapper-${isAnalyze ? 'analyze' : 'reports'}`]);
  const classAnalyzeWrapper = isAnalyze ? styles['analyze-wrapper'] : styles['analyze-wrapper-hide'];
  const quote = quotes[symbol];
  // @ts-ignore
  const { hasInfo, hasRevenue, hasMajorInvestor, hasMarginShort, hasEps, productCode } = quote || {
    hasInfo: false,
    hasRevenue: false,
    hasMajorInvestor: false,
    hasMarginShort: false,
    hasEps: false,
  };
  const height = deviceInfo.height - (isAnalyze ? 200 : 145);

  return (
    <div>
      <nav className={`${styles[device.layout]} ${styles['profile-tags']} row`}>
        <Link
          to={`${baseUrl}`}
          className={getTabClassName(currentTab === MainTabs.analyze)}
          data-anue-ga-event={`${Variation.stockChannelName},行情列表 click,技術,proj`}
          {...gaDataset({
            dataPrefix: SEND_PROJ_TARGET,
            eventName: 'Click_Tab',
            section: '線圖上方',
            clickItem: '技術',
          })}
        >
          技術
        </Link>
        {hasMajorInvestor && (
          <Link
            to={`${baseUrl}/3investor`}
            data-key={MainTabs['3investor']}
            className={getTabClassName(currentTab === MainTabs['3investor'])}
            data-anue-ga-event={`${Variation.stockChannelName},行情列表 click,法人,proj`}
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Tab',
              section: '線圖上方',
              clickItem: '法人',
            })}
          >
            法人
          </Link>
        )}
        {hasMarginShort && (
          <Link
            to={`${baseUrl}/margin`}
            data-key={MainTabs.margin}
            className={getTabClassName(currentTab === MainTabs.margin)}
            data-anue-ga-event={`${Variation.stockChannelName},行情列表 click,資券,proj`}
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Tab',
              section: '線圖上方',
              clickItem: '資券',
            })}
          >
            資券
          </Link>
        )}
        {hasRevenue && (
          <Link
            to={`${baseUrl}/revenue`}
            data-key={MainTabs.revenue}
            className={getTabClassName(currentTab === MainTabs.revenue)}
            data-anue-ga-event={`${Variation.stockChannelName},行情列表 click,營收,proj`}
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Tab',
              section: '線圖上方',
              clickItem: '營收',
            })}
          >
            營收
          </Link>
        )}
        {hasEps && (
          <Link
            to={`${baseUrl}/eps`}
            data-key={MainTabs.eps}
            className={getTabClassName(
              currentTab === MainTabs.eps ||
                currentTab === MainTabs.balance ||
                currentTab === MainTabs.income ||
                currentTab === MainTabs.ratios
            )}
            data-anue-ga-event={`${Variation.stockChannelName},行情列表 click,財報,proj`}
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Tab',
              section: '線圖上方',
              clickItem: '財報',
            })}
          >
            財報
          </Link>
        )}
        {hasInfo && (
          <Link
            to={`${baseUrl}/info`}
            data-key={MainTabs.info}
            className={getTabClassName(currentTab === MainTabs.info)}
            data-anue-ga-event={`${Variation.stockChannelName},行情列表 click,基本,proj`}
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Tab',
              section: '線圖上方',
              clickItem: '基本',
            })}
          >
            基本
          </Link>
        )}
        <a
          href={generateStockLinkBySymbol(symbol)}
          data-key={MainTabs.more}
          target="_blank"
          className={styles.tab}
          data-anue-ga-event={`${Variation.stockChannelName},行情列表 click,更多,proj`}
          {...gaDataset({
            dataPrefix: SEND_PROJ_TARGET,
            eventName: 'Click_Tab',
            section: '線圖上方',
            clickItem: '更多',
          })}
        >
          更多
        </a>
      </nav>
      <div
        className={panelWrapperClass}
        style={{
          height,
          maxHeight: height,
          overflowY: 'auto',
        }}
      >
        <div className={classAnalyzeWrapper}>
          <TradingView symbol={symbol} interval={10000} />
        </div>
        {notAnalyzePages}
      </div>
      {isAnalyze && (
        <div className={styles['stock-panel-sponsor']}>
          <a
            className={styles['chart-provider']}
            href="https://tw.tradingview.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            圖表由 TradingView 提供
          </a>
        </div>
      )}
    </div>
  );
}

export default React.memo(
  (props: StockPanelProps) => <StockPanel {...props} />,
  (prev, next) => prev.symbol === next.symbol && prev.main === next.main
);
