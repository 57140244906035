import * as React from 'react';
import { useRef, useEffect } from 'react';
import idx from 'idx';
import SearchResultItem from 'components/SearchResultItem/SearchResultItem';
import WayPoint from 'components/WayPoint/WayPoint';
import styles from './search-result.scss';

interface SearchResultProps {
  data: SearchResult[];
  top: number;
  zIndex?: number;
  height: number;
  reversed?: boolean;
  maxHeight?: number;
  position?: 'fixed' | 'absolute';
  selectedIndex: number;
  onClick: (index: number) => void;
  onMouseEnter: (index: number) => void;
  onPageEnd: () => void;
}

const DEFAULT_MAX_HEIGHT = 250;

export default function SearchResult({
  data,
  position,
  height,
  maxHeight,
  top,
  selectedIndex,
  onClick,
  zIndex,
  onMouseEnter,
  onPageEnd,
}: SearchResultProps) {
  const scroll = useRef<HTMLOListElement | null>(null);

  useEffect(() => {
    const nextIndex = selectedIndex + 1;

    if (scroll.current && data.length > 0) {
      const scrollTop = scroll.current.scrollTop;
      let correction = -1;
      if (nextIndex * 45 < scrollTop + height && scrollTop < selectedIndex * 45) {
        return;
      }
      // next index is larger than current visible index (move down)
      if (scrollTop + height < nextIndex * 45) {
        correction = nextIndex * 45 - height;
      }
      // next index is smaller than current visible index (move upward)
      else if (scrollTop > selectedIndex * 45) {
        correction = selectedIndex * 45;
      }
      if (correction) {
        scroll.current.scrollTo(0, Math.max(nextIndex * 45 - height, 0));
      }
    }
  }, [selectedIndex]);

  function filterItems() {
    return data.reduce<SearchResult[]>((p, c, i) => {
      if (idx(c, _ => _.code && (_.chName || _.enName) && _.mtype && _.exchange)) {
        p.push(c);
      }
      return p;
    }, []);
  }

  const list = filterItems();

  return (
    <ol
      ref={scroll}
      className={styles.container}
      style={{
        position,
        height,
        maxHeight: maxHeight || DEFAULT_MAX_HEIGHT,
        top,
        zIndex,
        display: data.length > 0 ? 'block' : 'none',
      }}
    >
      {list.length > 0 ? (
        list.map((r, i) => (
          <SearchResultItem
            key={i}
            data={r}
            selected={selectedIndex === i}
            onClick={() => {
              onClick(i);
            }}
            onMouseEnter={() => {
              onMouseEnter(i);
            }}
          />
        ))
      ) : (
        <SearchResultItem
          selected
          data={{
            chName: '查無符合商品',
            code: '',
            enName: '',
            exchange: '',
            industry: '',
            market: '',
            mtype: '',
            objectType: '',
            symbol: '',
            type: '',
          }}
        />
      )}
      <WayPoint onEnter={onPageEnd} minInterval={1000} />
    </ol>
  );
}
