import React, { PureComponent } from 'react';
import styles from './AgreementPage.scss';

class AgreementContent extends PureComponent {
  render() {
    return (
      <div className={styles['content-wrapper']}>
        <div className={styles['agreement-wrapper']}>
          <h1>歡迎您加入《Anue鉅亨》會員</h1>
          <p>
            《Anue鉅亨》為鉅亨金融科技股份有限公司(以下簡稱本公司)所經營之網站，
            當您註冊為《Anue鉅亨》之會員，即表示您已閱讀、了解本使用條款之所有條款內容，並同意以電子文件方式行使同意權，
            且具有書面同意之效果，受到以下各項條款之約束。如您是未滿二十歲之未成年人，則您必須在註冊免費會員前將本使用條款請您的法定代理人或監護人閱讀，
            並得到其同意，始可註冊及使用《Anue鉅亨》所提供之各項會員服務（包括簡訊及網路服務，以下稱本服務）。
            當您註冊成為《Anue鉅亨》之會員或使用本公司所提供之服務，即視為您的法定代理人或監護人已經閱讀、了解並同意本使用條款。
          </p>

          <h2>【Anue鉅亨免費會員服務使用條款】</h2>

          <p>
            一、會員帳號、密碼與安全性<br />
            1. 請依註冊申請程序所提示之項目，登錄您本人正確、真實及完整之個人資料，不得有造假或冒用他人資料之情形。當您的個人資料有異動時，請立即更新，
            以維持您個人資料之正確、真實及完整。如因您登錄不實資料或冒用他人名義，致侵害他人之權利或違法時，您應自負法律責任。<br />
            2. 為保障您的會員權益，您所提供的電子郵件地址必須為您自己所使用之正確電子郵件地址(Email address)；
            當會員申請完成時，密碼將寄至您的電子郵件地址。<br />
            3. 您註冊完成後將會取得一組帳號及密碼，您有義務妥善保管您的帳號及密碼並維持其機密與安全，不得洩漏予任何第三人，
            您應對以您的帳號所進行的所有活動和行為應負完全法律責任。<br />
            4. 如您提供之資料有變更者，您應主動至會員中心更新，以保證您的會員權益。
          </p>

          <p>
            二、個資蒐集前告知事項<br />
            1. 本公司蒐集您的個人資料之目的在於進行身分確認、聯絡、物品寄送、服務提供、會員管理、客戶管理、行銷與統計調查與分析等。<br />
            2. 本公司於網站內蒐集的個人資料包括：C001辨識個人者：如姓名、地址、電話、電子郵件等資訊；C002辨識財務者：
            如銀行帳戶之號碼與姓名、信用卡或簽帳卡之號碼、個人之其他號碼或帳戶(於提供付費服務/商品之情形)；
            C003政府資料中之辨識者：如身分證字號或護照號碼(外國人)；C011個人描述：如性別、國籍、出生年月日；
            C021家庭情形：如婚姻有無等；C038職業；C052資格或技術：如學歷資格等；C081：收入、所得、資產與投資。<br />
            3. 利用期間：本公司營運期間。利用地區：本公司營運地區及本網站提供服務之地區。利用對象及方式：
            個人資料蒐集除用於本公司之會員管理、客戶管理外，並予以下利用：
            (1)服務提供及物品寄送：於提供服務或交寄相關商品/贈品時，本公司會將您的個人資料自行或交付予相關合作廠商、物流廠商、
            郵寄廠商用於服務提供及物品寄送之目的。(2)金融交易及授權：將財務相關資訊，於金融交易過程(如信用卡授權、轉帳)時提交給金融機構/合作廠商以完成交易。
            (3)行銷與統計調查與分析：本公司會將您的個人資料自行或交付本公司之關係企業、合作廠商、金融機構、物流廠商、
            郵寄廠商等為行銷與統計調查與分析之目的予以處理、利用。<br />
            4. 就您提供予本公司之個人資料，得行使以下權利：查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除。行使方式如下：
            (1)如您欲查詢或請求閱覽、請求製給複製本、請求補充或更正等，您可直接登入會員中心，自行閱覽、列印複本、補充或更正個人資料。
            另，如您係要求本公司製給複製本者，每一頁資料，酌收處理費新台幣貳拾元整。(2)如欲請求停止蒐集、處理或利用、請求刪除，
            您應以正式書面來函詳細寫明所欲行使之權利種類、內容，以及本名、地址、電話等通訊連絡方式，檢附身分證明文件影本(如國民身分證或護照影本)，
            寄至本公司營業地址要求辦理，未具備上述要件者，為尚未完成請求之程序，雙方同意應不起算本公司處理期間，請求手續不完備者，本公司得通知補件，
            並於完成補件後，始完成請求程序，並開始起算辦理期間。<br />
            5. 如未提供真實、正確及完整之個人資料，您將無法成為本網站之會員，本公司有權拒絕您的會員申請，並可不提供本網站服務。
          </p>

          <p>
            三、會員服務與使用規則<br />
            1. 您於註冊程序完成並由本公司完成相關設定後，您即取得本會員之資格，您並可以開始使用本服務。
            本公司有權增加、變更或取消會員服務或功能之全部或一部，無須個別通知會員；本網站有關現有或將來之各項服務均適用本使用條款。<br />
            2. 會員在使用本服務過程中，必須遵循以下規則，否則本公司有權不經通知逕行暫停、取消或終止全部或一部會員權益：<br />
            (1) 遵守本使用條款之規定。<br />
            (2) 遵守法令規定，不得有任何違法情事。<br />
            (3) 不得有任何侵害他人權利之情事。<br />
            (4) 不得為任何非法目的而使用簡訊、網路服務系統、本網站、本服務。<br />
            (5) 遵守所有與網路服務有關的網路協定、規定和程式。<br />
            (6) 不得利用本公司簡訊及網路服務系統進行任何可能對互聯網或任何其他網站的正常運轉造成不利影響的行為。<br />
            (7) 不得利用本網站、本公司簡訊及網路服務系統傳輸任何騷擾、誹謗、恐嚇、猥褻或其他任何不當、違法之資訊、資料及內容。<br />
            (8) 不得利用本網站、本公司簡訊及網路服務系統進行任何不利於本公司之行為。<br />
            (9) 如發現任何非法使用用戶帳號或帳號出現安全漏洞的情況，應立即通告本公司。<br />
          </p>

          <p>
            四、隱私權保護<br />
            1. 您可在不需輸入任何資料的情況下使用本網站多項服務（不包含：即時股市報價資訊、個人化自選服務及討論區發言權等）。
            除法令另有規定外，本公司將不會在未明確告知的情況下，收集您的任何個人資料。當您希望使用即時股市報價資訊、
            個人化自選服務及討論區發言權等服務時，依證交所相關規定本網站將要求您填寫個人基本資料，本公司將妥善保護您所填寫的資料。<br />
            2. 本公司會記錄您連線的位址及在相關網站內的瀏覽活動等資料，惟僅供作網站流量分析和網路行為調查，以便於改善日後網站的服務品質，
            此類資訊只用來進行數據統計分析，不涉及個人身分資料。本公司會到您的電腦設定並取用cookie，Cookies是伺服端為了區別使用者的不同，
            經由瀏覽器寫入使用者硬碟的一些簡短資訊。只有原設置cookie的網站能讀取其內容。Cookie 並不含任何資料足使他人透過電話、電子郵件與您聯絡。<br />
            3. 本網站相關網站或網頁都可能包含其他網站或網頁的連結，對於此等不屬於本網站之網站或網頁，不論關於其內容或隱私權政策，均與本網站無關。<br />
            4. 在下列情況下，本公司有權查看或提供您的個人資料給有權機關、或主張其權利受侵害並提出適當證明之第三人：<br />
            (1)依法令規定、或依司法機關或其他有權機關的命令。<br />
            (2)為執行本會員條款、或您違反本會員條款或本網站其他條款。<br />
            (3)為維護本網站系統之正常運作及安全。<br />
            (4)為保護本公司、本網站或其他第三人的合法權益。
          </p>

          <p>
            五、會員權益<br />
            本公司每月花費鉅資購買全球金融資訊免費提供予會員，當您完成免費會員申請後，本公司將提供您多項免費會員服務
            （本公司有權自行調整提供之服務內容，如有調整，詳見本網站公告，本公司不另作個別通知）。
            本公司致力提供專業華文財經資訊，為提供廣大網友更貼心的需求，會依您所登錄的會員資料進行統計分析，
            並不定時寄送第一手商情資訊不另外收取E-mail傳輸費用；同時，會員亦有義務接收本公司不定期所發出之「Anue鉅亨會員報」eDM
            (提供各種活動訊息與網站新內容服務介紹)，我們會嚴控發出電子郵件的數量與品質，若您確定不想要收到本會員報時，請來電告知，
            但本公司有權取消您的免費會員資格。由於本公司主要收入是來自廣告，為提供您更完善的金融資訊，敬請各位會員予以支持。 <br />
          </p>

          <p>
            六、廣告事宜<br />
            1. 本網站所傳輸或刊載之廣告，關於其內容所展現的文字、圖片、動畫、聲音、影像或以其他方式對商品或服務之說明，
            都是由廣告主或廣告代理商所提供，對於廣告之內容，本網站基於尊重廣告主或廣告代理商權利，不對廣告之內容進行審查。
            您應該自行判斷該廣告的正確性及可信度，本網站不對廣告做任何擔保責任，亦不負任何法律責任。<br />
            2. 本公司隨時會與其他公司、廠商等第三人（「內容提供者」）合作，由其提供包括新聞、訊息、電子報、eDM等不同內容供本網站刊登，
            本網站於刊登時均將註明內容提供者。基於尊重內容提供者之智慧財產權，本網站對其所提供之內容並不做實質之審查或修改，
            對該等內容之正確真偽及適法性亦不負任何責任。<br />
            3. 本網站主要收入來自廣告主的贊助，讓本公司能夠持續提供高品質的全球金融資訊與服務，凡經註冊成為本網站會員後，
            即視為同意接收本網站所發出之會員電子報及eDM 等廣告訊息。
          </p>

          <p>
            七、服務暫停、中斷或終止<br />
            1. 本公司於下列情形之一時，有權隨時暫停、中斷或終止本服務之全部或一部， 而無須通知會員，本公司對使用者不負擔任何賠償責任：<br />
            (1) 本網站相關系統設備進行遷移、更換或維護。<br />
            (2) 因不可歸責於本公司所造成服務停止或中斷。<br />
            (3) 因不可抗力所造成服務停止或中斷。<br />
            2. 對於本公司網站之暫停、中斷或終止，可能造成您使用上的不便、資料遺失或其他經濟及時間上之損失，您平時應採取適當的防護措施，
            以保障您的權益，本公司不對會員或任何第三人負擔任何責任。
          </p>

          <p>
            八、與第三人網站之連結<br />
            本網站或本服務可能會提供連結至其他網站或網路資源的連結。您可能會因此連結至其他業者經營的網站/網頁，但不表示本公司與該等業者有任何關係。
            其他業者經營的網站/網頁均由各該業者自行負責，不屬本公司控制及負責範圍之內。本公司對任何檢索結果或外部連結，不擔保其合適性、
            可依賴性、即時性、有效性、正確性及完整性。如您因第三人網站/網頁而產生之糾紛或損害，請您逕行與該網站/網頁之業者聯絡，本公司不負任何責任。
          </p>

          <p>
            九、免責聲明及責任限制<br />
            1.本公司對本服務及本網站不提供任何明示或默示的擔保。本公司不保證以下事項：<br />
            (1) 本網站及本服務符合您的需求。<br />
            (2) 本網站及本服務不受干擾、及時提供、安全可靠或免於出錯。<br />
            (3) 由本網站及本服務而取得之資訊、資料或結果為正確或可靠。<br />
            (4) 您經由本網站及本服務購買或取得之任何產品、服務、資訊或其他資料將符合您的期望。<br />
            2. 您自本網站或經由本服務取得之建議和資訊，無論其為書面或口頭，均不構成本公司之保證。<br />
            3. 本網站及本服務提供之各項資訊僅供參考，您依據相關資訊所為之投資或其他任何行為，均應自行評估風險並自負其責，
            本公司不負擔任何投資風險責任或其他任何法律責任。<br />
            4. 您明確了解並同意本公司及關係企業、負責人、受僱人、代理人、合夥人及授權人，無須為您任何直接、間接、附隨、特別、衍生、
            懲罰性的損害負責，包括但不限於因下述事由所生利潤、商譽、使用、資料之損害或其他無形損失(a)使用或無法使用本服務；
            (b)替代商品及服務之購買成本；(c)他人未經授權存取或修改您的傳輸或資料；(d)任何第三人就本服務所為之聲明或行為，或(e)任何其他與本服務有關者。<br />
            5. 您確實明瞭在使用本網站及本服務時，可能遇到以下風險：如斷線、斷電、網路壅塞或其他不可歸責於本公司事由所造成之傳輸中斷、停滯、阻礙等情事，
            致無法觀看或無法順暢觀看，本公司就前述傳輸問題不負任何賠償或補償責任。
          </p>

          <p>
            . 若本公司依法或依約須對您負擔賠償或補償責任時，其賠償或補償責任以有爭議之該筆交易之實收金額為上限，
            本公司對於任何因該筆交易無法交易所生之其他損害（包但不限於間接損失），不負任何補償或賠償責任。
          </p>

          <p>
            十、會員內容之移除/刪除、保留或揭露<br />
            本公司基於下列事由，有權不經通知逕行將會員所傳送、張貼或發表之內容予以移除/刪除、保留或揭露：<br />
            司法或警察機關偵查犯罪需要時。<br />
            依政府機關之要求時。<br />
            因會員之行為違反法令或本使用條款或本網站各項服務之相關條款規定時。<br />
            基於維護公益或保護本公司或他人權利。<br />
            事先獲得會員之同意。
          </p>

          <p>
            十一、違約賠償<br />
            您同意保障和維護本公司及第三人的權益，如會員有侵害他人權利或、違反法令或本使用條款或本網站各項服務之條款之情事，
            致本公司或任何第三人受有損害者，您應賠償本公司或第三人因此所生之一切損害(包括但不限於罰鍰、罰金、賠償金、和解金、訴訟費及律師費等）。
          </p>

          <p>
            十二、智慧財產權<br />
            1. 本網站上所使用或提供之軟體、程式及內容（包括但不限於文字、說明、圖畫、圖片、圖形、檔案、頁面設計、網站規劃與安排等）
            之專利權、著作權、商標權、營業秘密、專門技術及其他智慧財產權與權利均屬本公司或其他權利人所有，非經本公司或權利人事先書面授權同意，
            會員不得重製、改作，編輯、公開傳播、公開播送、公開上映、公開演出、出租、散布、進行還原工程、解編、反向組譯或為其他方式之使用如有違反，
            會員除應自負法律責任外，本公司並有權逕行終止會員權益及本服務之全部或一部。如因而對本公司造成損害或損失，該會員應賠償本公司因此所生之一切損害。<br />
            2. 為了提供會員更加完善之服務，本公司會接洽合作廠商提供各種圖檔、圖片或其他著作或資料，供會員得以瀏覽、檢索。
            但圖檔、圖片及其他著作或資料均是由各該廠商所提供，其合法性及正確性，與本公司無涉。
          </p>

          <p>
            十三、不得轉讓<br />
            本公司提供之各項服務及其他一切因會員身份享有之權益，皆不得以任何方式將其授權、轉讓、移轉、出租、出借予其他任何第三人或繼承…等。
            如有違反，非經本公司同意，對本公司均不生效力。會員應自負其責，本公司並有權逕行終止會員權益及本服務之全部或一部。
          </p>

          <p>
            十四、條款修訂<br />
            本公司有修改本使用條款之權利，如本使用條款修定者，您同意自該修訂條款於本網站公告之時起受其拘束，本網站將不對會員個別通知。
            如您於公告後繼續使用本服務，則視為您已經同意該修訂條款。
          </p>

          <p>
            十五、通知與送達<br />
            除本使用條款另有約定從其約定外，本網站及本服務之通知均可以透過本網站頁面公告、電子郵件等方式進行。如以網站公告方式，
            則自公告之時起即視為通知已到達會員；如以電子郵件方式，則自電子郵件發送之時視為已到達會員。
          </p>

          <p>
            十六、準據法及管轄法院<br />
            1. 關於本使用條款之解釋或適用，以中華民國之法律為準據法<br />
            2. 會員因本使用條款及使用本服務而與本公司所生之爭議，同意本誠信原則解決之，如有訴訟之必要，同意以台灣台北地方法院為第一審管轄法院。
          </p>

          <p>
            十七、其他規定<br />
            1. 如本使用條款之部份無效時，不影響其他約款之效力。<br />
            2. 本使用條款之標題僅為閱讀方便而設，不影響本使用條款之解釋。
          </p>
        </div>
      </div>
    );
  }
}

export default AgreementContent;
