import * as React from 'react';
import { useEffect, useState, useRef } from 'react';

let isHighchartLoaded = false;
const initHighchart = () =>
  new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = `/highchart-${env.md5}.js`;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });

export default function(props: any) {
  const [isLoaded, setLoaded] = useState(false);
  const Comp = useRef(null);

  async function check() {
    if (!isHighchartLoaded) {
      isHighchartLoaded = true;
      await initHighchart();
    }
    Comp.current = await props.getComponent();

    setLoaded(true);
  }
  useEffect(() => {
    check();
  }, []);

  if (isLoaded && Comp.current) {
    // @ts-ignore
    const Klass = Comp.current.default;
    // @ts-ignore
    return <Klass {...props} />;
  }

  return null;
}
