import * as React from 'react';
import { useEffect, useRef, useState, useMemo } from 'react';
import getty from 'anue-fe-sdk/getty';
import ChatMessage from 'components/ChatMessageClassic/ChatMessageClassic';
import ChatroomAdBanner from 'components/ChatroomAdBanner';
import WayPoint from 'components/WayPoint/WayPoint';
import { getDeviceInfo } from 'hooks/device';
import useSoftwareScroll from 'hooks/soft-scroll';
import { pageSize } from 'hooks/chat';
import styles from './chat-room-list.scss';

interface ChatRoomListProps {
  data: ChatMessage[];
  uid: string | null;
  onNewMessage: (fn: () => void) => void;
  onEndReached: () => void;
}

const device = getDeviceInfo();
const shouldUseSoftwareScroll = device.isFireFox || device.isIE || device.isEdge;
const containerOverflowY = shouldUseSoftwareScroll ? 'hidden' : 'auto';

let scrollTimer: any = null;

console.log('[ChatRoomList] page size = %o', pageSize);

function ChatRoomList({ data, uid, onEndReached, onNewMessage }: ChatRoomListProps) {
  const scroll = useRef<HTMLDivElement>(null);
  const [renderCount, setRenderCount] = useState(pageSize * 1.25);
  const notify = useRef<HTMLDivElement | null>(null);
  const tick = useRef<number>(-1);
  const [attach, softScrollTo, getSoftScrollPosition] = useSoftwareScroll();

  useEffect(() => {
    const scrollElement = scroll.current;

    // use soft scroll on Firefox and IE11
    if (shouldUseSoftwareScroll && scrollElement) {
      console.log('soft scroll attached');
      attach(scrollElement.querySelector('ul'));
    }

    onNewMessage(handleNewMessage);
  }, []);

  useEffect(() => {
    scrollToBottomIfNeeded();
  }, [getty(data, [0, 'mid'])]);

  function handleNewMessage() {
    const notifyElement = notify.current;
    const scrollElement = scroll.current;

    // TODO: Safari scroll top fix, Safari always returns scrollTop = 0
    if (notifyElement && scrollElement && !device.isSafari) {
      const canShowNotify = device.isFireFox
        ? getSoftScrollPosition() > 500
        : scrollElement.scrollHeight - scrollElement.scrollTop > scrollElement.clientHeight * 2;
      if (canShowNotify) {
        notifyElement.style.visibility = 'visible';
      }
    }
  }

  function scrollToBottomIfNeeded() {
    const scrollElement = scroll.current;

    if (getSoftScrollPosition() < 500) {
      softScrollTo(0);
    } else if (
      scrollElement &&
      scrollElement.scrollHeight - scrollElement.clientHeight - scrollElement.scrollTop < 500
    ) {
      scrollTo();
    }
  }

  function scrollTo(y?: number) {
    const scrollElement = scroll.current;
    if (scrollElement) {
      if (scrollTimer) {
        clearTimeout(scrollTimer);
      }
      scrollTimer = setTimeout(() => {
        scrollElement.scrollTo(0, y || scrollElement.scrollHeight * 2);
      }, 300);
    }
  }

  /**
   * In case the chat list growing so big, here we clip invisible message nodes
   * when user scroll to the latest message.
   */
  function removeInvisibleMessages() {
    if (renderCount > pageSize * 1.5) {
      console.log('[ChatRoomList] remove clipped messages', renderCount - pageSize * 1.5);
      setRenderCount(pageSize * 1.5);
    }
  }

  function renderList() {
    const list = data;

    if (!list || list.length === 0) {
      return (
        <div className={styles.spinner}>
          <div className="anue-dots" />
          <i>聊天室載入中</i>
        </div>
      );
    }

    console.log('[ChatRoomList] render %o messages (total %o)', renderCount, list.length);

    const elements: JSX.Element[] = [];
    const ids: Record<string, boolean> = {};

    for (let i = 0; i < renderCount; i++) {
      const m = list[i];
      const nextUid = getty(list, [i + 1, 'profile', 'ga_id']) || getty(list, [i + 1, 'profile', 'email']);
      // skip malformed data
      if (!m) {
        break;
      }
      // skip messages with the same mid (de-dupe)
      if (ids[m.mid]) {
        continue;
      }
      ids[m.mid] = true;

      const muid = getty(m, ['profile', 'ga_id']) || getty(m, ['profile', 'email']);
      const myMessage = uid === `${muid}`;

      elements.push(<ChatMessage key={m.mid} compact={muid === nextUid || myMessage} isMyMessage={myMessage} {...m} />);
    }
    return elements;
  }

  /**
   * This function triggers when list reaches its end. If there is still
   * more messages in memory (data.length < renderCount + pageSize x 1.5)
   * we will just increase `renderCount` by 1.5 times of `pageSize`.
   * When (renderCount + pageSize x 1.5 >= data.length) which means there's
   * no enough message in memory, we have to request more message from remote.
   *
   * [end reaches]
   *       +
   *       |
   *       v
   * <has enough messages?>  --YES--> [renderCount + 1.5pz] --> [render list with current size + 1.5pz]
   *          |
   *          NO
   *          |
   *          +----> [renderCount + 1.5pz & fetch more] --> [render list with current size + 1.5pz]
   *                               |                           (not changing anything)
   *                 API response with new page
   *                               |
   *                               +----->[render list with current size + 1.5pz]
   *
   */
  function onEnd() {
    if (Date.now() - tick.current > 100) {
      setRenderCount(renderCount + pageSize);
      const bufferRenderCount = renderCount + pageSize * 1.5;

      if (data.length < bufferRenderCount) {
        onEndReached();
      }
      tick.current = Date.now();
    }
  }

  function onEnterBottom() {
    removeInvisibleMessages();
  }

  function handleNotifyClicked() {
    if (notify.current) {
      if (shouldUseSoftwareScroll) {
        softScrollTo(0);
      } else if (scroll.current) {
        scroll.current.scrollTo(0, scroll.current.scrollHeight);
      }
      notify.current.style.visibility = 'hidden';
      removeInvisibleMessages();
    }
  }

  return (
    <>
      <ChatroomAdBanner />
      <div
        data-tid="chat-room-list"
        ref={scroll}
        style={{
          overflowY: containerOverflowY,
        }}
        className={`${styles.wrapper} ${styles[device.layout]}`}
      >
        <ul
          className={styles.list}
          style={
            shouldUseSoftwareScroll
              ? {
                  maxWidth: 'calc(100% - 8px)',
                }
              : undefined
          }
        >
          <WayPoint tid="chat-list-lower-bound" onEnter={onEnterBottom} />
          {renderList()}
          <WayPoint tid="chat-list-upper-bound" onEnter={onEnd} />
        </ul>
      </div>
      <div ref={notify} className={styles.notify} onClick={handleNotifyClicked}>
        新訊息
      </div>
    </>
  );
}

export default React.memo(
  (props: ChatRoomListProps) => <ChatRoomList {...props} />,
  (prev, next) => prev.uid === next.uid && prev.data.length === next.data.length
);
