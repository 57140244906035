import React, { Component, PropTypes } from 'react';
import cx from 'classnames';
import gaDataset from 'fe-common-library/dest/utils/gaDataset';
import styles from './NaviMenu.scss';
import { SEND_PROJ_TARGET } from 'utils/analytics';
import { naviMenuListType } from '../../utils/propTypes';

class NaviMenu extends Component {
  static propTypes = {
    list: PropTypes.arrayOf(naviMenuListType).isRequired,
  };

  renderMenuList = list => {
    let result = [];

    if (list.length > 0) {
      result = list.map(row => {
        const content = row.img ? <img src={row.img} alt={row.title} /> : row.title;

        if (row.onClickCallBack) {
          return (
            <a
              href={`#${row.title}`}
              onClick={row.onClickCallBack}
              key={row.title}
              className={cx({ [styles['is-child-dom']]: !!row.img })}
              data-anue-ga-event={row.gaEvent || ''}
              {...gaDataset({
                dataPrefix: SEND_PROJ_TARGET,
                eventName: 'Click_Search',
                clickItem: row.title,
              })}
            >
              {content}
            </a>
          );
        }

        return (
          <a
            {...row.redirect}
            rel="noopener noreferrer"
            key={row.title}
            className={cx({ [styles['is-child-dom']]: !!row.img })}
            data-anue-ga-event={row.gaEvent || ''}
            data-ga-target="talk"
            data-ga-event-name="Click_Info"
            data-ga-click-item={content}
          >
            {content}
          </a>
        );
      });
    }

    return result;
  };

  render() {
    const { list } = this.props;

    return (
      <div>
        <nav className={styles['dropdown-menu-wrapper']}>
          {this.renderMenuList(list)}
        </nav>
      </div>
    );
  }
}

export default NaviMenu;
