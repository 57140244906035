import 'firebase/database';
import * as Network from 'anue-fe-sdk/Network';
import firebase from 'firebase/app';
import firebaseConfig from 'constant/firebaseConfig';
import Variation from 'constant/variation';
import { useState, useEffect, useRef } from 'react';
import { getProfile } from 'hooks/auth';
import Eventy from 'anue-fe-sdk/eventy';

export enum MessageEvents {
  UPDATE_MESSAGE,
  NOTIFY_NEW_MESSAGE,
  UPDATE_ONLINE,
  UPDATE_STATUS,
}

export enum MessageStatus {
  HISTORY = 'HISTORY',
  SENDING = 'SENDING',
  FAILED = 'FAILED',
  OK = 'OK',
}

export const event = new Eventy();

// export this for testing
export const pageSize = ~~Math.abs((window.innerHeight / 60) * 1.5);

const queue: Array<Record<string, ChatMessage>> = [];

firebase.initializeApp(firebaseConfig);

const db = firebase.database();
const messageHook = db.ref(Variation.chat.ref);

let vmid = 0;
let lastHistoryId: string = '';
let staticMessages: ChatMessage[] = window.__PREFETCH__ ? snapshotToList(window.__PREFETCH__) : [];
let staticHistory: ChatMessage[] = [];
let onlineCount = 0;
const deferredMessages: Record<string, ChatMessage> = {};

// 1. fetch the first page list

if (staticHistory.length === 0) {
  messageHook
    .limitToLast(pageSize)
    .orderByChild('time')
    .once('value', handleHistory);
}

messageHook.limitToLast(1).on('child_added', onLatestMessage);

/* online count */
// Ref: http://stackoverflow.com/questions/15982215/firebase-count-online-users/15982583#15982583
const onlineHook = db.ref(Variation.chat.online);
const currentUserRef = onlineHook.push();

db.ref('.info/connected').on('value', snapshot => {
  if (snapshot.val()) {
    currentUserRef.onDisconnect().remove();
    currentUserRef.set(true);
  }
});

// listen to online user number change
onlineHook.on('value', snapshot => {
  onlineCount = snapshot.numChildren();
  event.emit(MessageEvents.UPDATE_ONLINE, onlineCount);
});

/**
 * Transform queued firebase message snapshot into list.
 */
function dequeue() {
  const snapshot = queue.shift();
  const list: ChatMessage[] = [];

  for (const m in snapshot) {
    const msg = snapshot[m];
    if (msg && msg.text) {
      msg.mid = m;
      list.push({
        ...msg,
        animated: true,
        mid: m,
        status: MessageStatus.HISTORY,
      });
    }
  }
  return list;
}

function onLatestMessage(snapshot) {
  const newMessages: ChatMessage = snapshot.val();

  if (!newMessages) {
    return;
  }
  vmid++;
  newMessages.mid = `m-${vmid}`;
  const task = {
    [newMessages.mid]: newMessages,
  };

  // drop the first single message as it's duplicated
  if (deferredMessages[newMessages.token]) {
    for (let i = 0; staticMessages.length; i++) {
      const msg = staticMessages[i];
      if (i > 100) {
        break;
      }
      if (msg.token === newMessages.token) {
        delete deferredMessages[msg.token];
        staticMessages[i] = {
          ...newMessages,
          mid: msg.token,
          animated: true,
          status: MessageStatus.HISTORY,
        };
        event.emit(`${msg.token}`, {
          status: MessageStatus.OK,
          content: newMessages.text,
        });
        break;
      }
    }
  } else if (vmid !== 1) {
    queue.unshift(task);
  }
}

/**
 * Transform firebase snapshot messages into an array.
 */
function snapshotToList(snapshot: ChatMessageSnapshot) {
  const list: ChatMessage[] = [];
  let oldest = Number.MAX_SAFE_INTEGER || 9007199254740991;
  // transform table into list
  for (const k in snapshot) {
    const m = snapshot[k];
    // record oldest message's id as we're going to use this id
    // as the key identifier when fetching history.
    if (m.time < oldest) {
      oldest = m.time;
      lastHistoryId = k;
    }
    list.unshift({ ...m, status: MessageStatus.HISTORY, mid: k });
  }
  return list;
}

function handleHistory(snapshot) {
  const newMessages: ChatMessageSnapshot = snapshot.val();
  const next = snapshotToList(newMessages);

  staticHistory = [...staticHistory, ...next];
  event.emit(MessageEvents.UPDATE_MESSAGE);
}

function fetchHistory() {
  if (lastHistoryId) {
    messageHook
      .endAt(null, lastHistoryId)
      .limitToLast(50)
      .once('value', handleHistory);
  }
}

export async function sendMessage(content: string, resendToken?: string) {
  const profile = getProfile();

  if (!profile) {
    return;
  }

  const token = resendToken || `${Math.random()}`;

  try {
    // send message to backend and wait for the message to be broadcasted
    const message: ChatMessage = {
      mid: token,
      type: 'user',
      time: Date.now() / 1000,
      token,
      text: content,
      animated: true,
      status: MessageStatus.SENDING,
      isMyMessage: true,
      profile: {
        avatar: profile.avatar,
        name: profile.name,
        uid: profile.uid,
        vip: 0,
      },
    };
    if (resendToken) {
      event.emit(`${token}`, { status: MessageStatus.SENDING });
    } else {
      deferredMessages[token] = message;
      staticMessages.unshift(message);
      // before message actually sent, put message to memory stack
      // and notify chat room list to update so user can see the message instantly.
      // but the message will display as `sending`.
      event.emit(MessageEvents.UPDATE_MESSAGE);
    }
    const resp = await Network.getDriver().send({
      method: 'post',
      url: `/api/v3/chat`,
      auth: true,
      body: {
        message: content,
        token,
        firebase: Variation.firebaseChannel,
      },
    });

    if (resp.statusCode > 300) {
      throw {
        detail: resp,
      };
    }
  } catch (err) {
    setTimeout(() => {
      event.emit(`${token}`, { status: MessageStatus.FAILED });
    }, 100);
    console.warn('failed to send message', token, err);
  }
}

// in case the message update too fast, here we leverage a FIFO queue
// so it won't make too much impact to main thread
setInterval(function() {
  let next: ChatMessage[] = [];

  while (queue.length > 0) {
    next = next.concat(dequeue());
  }
  if (next.length > 0) {
    staticMessages = next.concat(staticMessages);
    event.emit(MessageEvents.UPDATE_MESSAGE);
    event.emit(MessageEvents.NOTIFY_NEW_MESSAGE);
  }
}, 700);

export function onReply(fn: any) {
  return event.on('reply', fn);
}

export function reply(
  message: string,
  profile: {
    avatar: string;
    name: string;
    uid: any;
    vip: 0 | 1;
  }
) {
  event.emit('reply', {
    message,
    profile,
  });
}

/* Banner campaign */
// this.campaignsFirebase = database.ref(BANNER_CAMPAIGN_ROOT);
// this.campaignsFirebase.once('value', this.loadBannerCampaign);
// this.campaignsFirebase.on('value', this.onBannerCampaignChanged);

export default function useChat(): [
  ChatMessage[],
  React.MutableRefObject<number>,
  number,
  () => void,
  (fn: EventyHandler) => EventyRemover
] {
  const [messages, setMessages] = useState<ChatMessage[]>(staticMessages);
  const historyCount = useRef<number>(staticHistory.length);
  const [count, setCount] = useState<number>(onlineCount);

  useEffect(function() {
    const removeMessageListener = event.on(MessageEvents.UPDATE_MESSAGE, function() {
      historyCount.current = staticHistory.length;
      setMessages([...staticMessages, ...staticHistory]);
    });
    const removeOnlineListener = event.on(MessageEvents.UPDATE_ONLINE, function(nextCount) {
      setCount(nextCount);
    });
    return () => {
      removeMessageListener();
      removeOnlineListener();
    };
  }, []);

  return [messages, historyCount, count, fetchHistory, fn => event.on(MessageEvents.NOTIFY_NEW_MESSAGE, fn)];
}
