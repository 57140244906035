// Encrypt string
// REF. https://github.com/KyleBanks/XOREncryption/blob/master/JavaScript/XOREncryption.js
export default function encryptDecrypt(input) {
  const key = ['c', 'n', 'y', 'e', 's', 'r', 'o', 'c', 'k']; // Can be any chars, and any size array
  const output = [];

  for (let i = 0; i < input.length; i += 1) {
    const charCode = input.charCodeAt(i) ^ key[i % key.length].charCodeAt(0); // eslint-disable-line no-bitwise

    output.push(String.fromCharCode(charCode));
  }

  return output.join('');
}
