import * as React from 'react';
import Variation from 'constant/variation';
import { useEffect, useRef } from 'react';
import AdProfiles from 'components/DFP/AdProfiles';
import { AdSlotWithWally } from 'cnyes-dfp';
import NewsList, { NewsItemMemo } from 'components/NewsList/NewsList';
import useNews from 'hooks/news';

interface NewsListByTypeProps {
  type: 'realtime' | 'related' | 'video';
  updateInterval?: number;
  symbol?: string;
}

const isTWStock = Variation.firebaseChannel === 'TWStock';

const AdsRenderer = {
  video: (index: number) => {
    if (index === 1) {
      return (
        <>
          <NewsItemMemo
            logo={require('assets/litv.png')}
            label="LiTV"
            isVideo
            data={{
              title: '鉅亨看世界',
              channelTitle: 'LiTv 線上影視',
              id: 'litv',
              src: '',
              isLiTV: true,
              srcset: '',
            }}
          />
          <AdSlotWithWally lazyLoading={false} key="ad-block-news-item" profile={AdProfiles.globalNews('video')} />
        </>
      );
    }
  },
  realtime: (index: number) => {
    if (index === 1) {
      const profile = isTWStock ? AdProfiles.bottomLeftNewsListA : AdProfiles.globalNews('realtime');

      return <AdSlotWithWally lazyLoading={false} key="ad-block-news-item" profile={profile} />;
    }
    return null;
  },
  related: (index: number) => {
    if (index === 1) {
      const profile = isTWStock ? AdProfiles.bottomLeftNewsListA : AdProfiles.globalNews('news');
      return <AdSlotWithWally lazyLoading={false} key="ad-block-news-item" profile={profile} />;
    }
    return null;
  },
};

function NewsListByType({ type, symbol, updateInterval }: NewsListByTypeProps) {
  const [news, fetchRealtimeNews, fetchRelatedNews, fetchVideoNews] = useNews();
  const nextPage = useRef<() => void>(() => {});

  async function fetchData() {
    switch (type) {
      case 'realtime':
        await fetchRealtimeNews();
        break;
      case 'related':
        if (symbol) {
          nextPage.current = await fetchRelatedNews(symbol);
        }
        break;
      case 'video':
        nextPage.current = await fetchVideoNews();
        break;
    }
  }

  useEffect(() => {
    let it;
    fetchData();
    if (updateInterval) {
      it = setInterval(fetchData, updateInterval);
    }
    return () => {
      if (it) {
        clearInterval(it);
      }
    };
  }, [symbol]);

  const collection = String(type === 'related' ? symbol : type);

  return (
    <NewsList
      type={type}
      video={type === 'video'}
      isLoaded={!!news[collection]}
      data={news[collection] || []}
      onNextPage={() => {
        nextPage.current();
      }}
      renderAdsAtIndex={AdsRenderer[type]}
    />
  );
}

export default NewsListByType;
