export default {
  Code: 0,
  Title: 3,
  lp: 6,
  ChangeNet: 11,
  TodayHigh: 12,
  TodayLow: 13,
  backupLp: 21,
  Name: 200009,
  ChineseName: 200008,
  EnglishName: 200024,
  TradeTime: 200007,
  Symbol: 200010,
  Price: 200026, // non-formatted => 6
  Change: 200027, // non-formatted => 11
  ChangePercent: 200044, // non-formatted => 56
  HighPrice: 200028, // non-formatted => 12
  LowPrice: 200029, // non-formatted => 13
  OpenPrice: 200030, // non-formatted => 19
  LastClose: 200031, // non-formatted => 21
  YearHighPrice: 200036, // non-formatted => 90
  YearLowPrice: 200037, // non-formatted => 91
  FiftyTwoWeekHighPrice: 3265,
  FiftyTwoWeekLowPrice: 3266,
  Bid: 200032, // non-formatted => 22
  Sell: 200033, // non-formatted => 25
  OneWeek: 200045,
  OneMonth: 200040, // non-formatted => 3380
  ThreeMonth: 200038, // non-formatted => 3378
  SixMonth: 200039, // non-formatted => 3379
  TwelveMonth: 200041, // non-formatted => 3381
  HighPriceAnnually: 200042, // non-formatted => 7868
  LowPriceAnnually: 200043, // non-formatted => 7869
  Direction: 200025,
  Volume: 800001,
  IsOpen: 800002,
  Session: 800003,
  IsOpening: 800041,
  ThisYearToNow: 200051,
  hasInfo: 800005,
  hasMajorInvestor: 800006,
  hasRevenue: 800007,
  hasMarginShort: 800008,
  requestedSymbol: 800013,
  hasEps: 800015,
  NonFormattedChartQuote: {
    Price: 6,
    Change: 11,
    LastClose: 21,
    ChangePercent: 56,
  },
};
