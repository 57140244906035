import * as React from 'react';
import globalConfig from 'constant/globalConfig';
import styles from './allowed-link.scss';

// tslint:disable-next-line
const defaultLinkThumbnail = require('assets/anue-logo.svg');

interface AllowedLinkProps {
  href: string;
  typeName?: string;
  title?: string;
  cover?: string;
}

export default function AllowedLink({ title, href, cover, typeName }: AllowedLinkProps) {
  const isCoverMatched = cover && String(cover).indexOf('https://') === 0;
  let src = defaultLinkThumbnail;

  if (isCoverMatched && cover) {
    src = cover;
  }

  const content = globalConfig.isIE ? (
    <a href={href} className={styles.link} target="_blank" style={{ width: '100%' }} rel="noopener noreferrer">
      <span className={styles['ie-href']}>{href}</span>
    </a>
  ) : (
    <a href={href} className={styles.link} target="_blank" rel="noopener noreferrer">
      {typeName && <span className={styles.label}>{typeName}</span>}
      <span className={styles.thumbnail}>
        <div
          className={title ? styles.tiny : styles.large}
          style={{
            backgroundImage: `url(${src})`,
            width: '100%',
            backgroundSize: isCoverMatched ? 'cover' : 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </span>
      {title && <div className={styles.title}>{title}</div>}
      <span className={styles.href}>{href}</span>
    </a>
  );

  return content;
}
