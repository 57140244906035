import { useState, useEffect, useRef } from 'react';
import Variation from 'constant/variation';
import Eventy from 'anue-fe-sdk/eventy';
import api from 'helpers/api';

type usePortFolio = [
  string[][],
  number,
  (num: number) => void,
  (useDefault?: boolean) => Promise<void>,
  (groupIndex: number, symbols: string[]) => Promise<void>
];

let globalActiveIndex: number = 0;
let globalState: string[][] = [];
let updateTimer: NodeJS.Timer | null = null;

const event = new Eventy();

function groupSelector(items: Portfolio.APIResponseItems): string[][] {
  return (
    items &&
    Object.keys(items).map(item =>
      items[item] && items[item].data ? items[item].data.map(symbolObj => symbolObj.product_id) : []
    )
  );
}

export default function usePortFolio(): usePortFolio {
  const [groups, setGroups] = useState<string[][]>(globalState);
  const [activeGroup, setActiveGroup] = useState<number>(globalActiveIndex);
  const lock = useRef(false);

  useEffect(() => {
    const removeActive = event.on('setActive', index => {
      globalActiveIndex = index;
      setActiveGroup(index);
    });
    const removeSync = event.on('sync', state => {
      globalState = state;
      setGroups([...globalState]);
    });
    return () => {
      removeActive();
      removeSync();
    };
  }, []);

  function setActiveGroupIndex(index: number) {
    event.emit('setActive', index);
  }

  async function getGroups(useDefault: boolean = false) {
    const response = await api<Portfolio.APIResponseItems>(
      {
        auth: true,
        url: useDefault ? Variation.portfolio.defaultGroupsApi : Variation.portfolio.getPortfolio,
      },
      ['items'],
      (res: Portfolio.APIResponseItems) => {
        event.emit('sync', groupSelector(res));
      }
    );

    if (response.error) {
      event.emit('sync', [[], [], [], []]);
    }
  }

  async function updateGroup(groupIndex: number, symbols: string[]) {
    if (symbols.length > 100) {
      alert('自選群組不可超過100個商品');
      return;
    }

    if (lock.current) {
      console.log('updateGroup locked as another update is in progress');
      return;
    }

    const nextGroup = [...groups];
    nextGroup[groupIndex - 1] = symbols.map(s => s.toUpperCase());

    if (updateTimer) {
      clearTimeout(updateTimer);
    }

    updateTimer = setTimeout(async () => {
      await api<string[][]>(
        {
          method: 'PUT',
          auth: true,
          url: `${Variation.portfolio.putPortfolio}`,
          body: {
            group: groupIndex,
            data: symbols,
          },
        },
        [],
        () => {}
      );

      event.emit('sync', nextGroup);
    }, 1000);
  }

  return [groups, activeGroup, setActiveGroupIndex, getGroups, updateGroup];
}
