import { useState, useEffect } from 'react';
import createStore from 'utils/store';
import idx from 'idx';
import { GETv1QuoteBySymbol } from 'utils/api';
import { replaceCreator } from 'utils/stringHelper';
import UniversalPropsFormatted from 'constant/universal-props-formatted';

const transferEmptyStr = replaceCreator('--');

type QuotesStore = Record<string, Quote>;
type GlobalStore = {
  staticSymbol: string;
  symbols: Record<string, string>;
  quotes: QuotesStore;
};
type SymbolType = string | string[];
type useSharedQuotes<T> = [QuotesStore, (symbol: SymbolType) => void, (symbol: string) => Promise<Quote | null>];

const REQUEST_INTERVAL = 30000;
const store = createStore<GlobalStore>();

const getQuotes = async (symbol: SymbolType) => {
  try {
    const resp = await GETv1QuoteBySymbol(symbol);
    const statusCode = idx(resp, _ => _.data.statusCode);
    const data = idx(resp, _ => _.data.data);

    if (statusCode === 200 && data) {
      // data format
      const quotes = data.reduce((p, c) => {
        const symbolCode: string = c[UniversalPropsFormatted.requestedSymbol];

        p[symbolCode] = {
          symbol: symbolCode,
          productCode: c[UniversalPropsFormatted.Symbol],
          name: c[UniversalPropsFormatted.Name],
          lp: transferEmptyStr(c[UniversalPropsFormatted.Price]),
          ch: transferEmptyStr(c[UniversalPropsFormatted.Change]),
          chp: transferEmptyStr(c[UniversalPropsFormatted.ChangePercent]),
          isTrading: c[UniversalPropsFormatted.IsOpen],
          tradeTime: c[UniversalPropsFormatted.TradeTime],
          hasInfo: c[UniversalPropsFormatted.hasInfo],
          hasMajorInvestor: c[UniversalPropsFormatted.hasMajorInvestor],
          hasRevenue: c[UniversalPropsFormatted.hasRevenue],
          hasMarginShort: c[UniversalPropsFormatted.hasMarginShort],
          hasEps: c[UniversalPropsFormatted.hasEps],
          prevClosePrice: transferEmptyStr(c[UniversalPropsFormatted.LastClose]),
          volume: c[UniversalPropsFormatted.Volume],
          direction: c[UniversalPropsFormatted.Direction],
        };
        return p;
      }, {});

      store.dispatch({ quotes });

      return quotes[symbol];
    } else {
      throw new Error(statusCode);
    }
  } catch (error) {
    console.error('Get Quotes Fail:', error.message);
  }
};

setInterval(async () => {
  const { staticSymbol, symbols } = store.getState();
  const allSymbols = { ...symbols, [staticSymbol]: staticSymbol };

  await getQuotes(Object.keys(allSymbols));
}, REQUEST_INTERVAL);

const sharedQuotes = <T>() => (): useSharedQuotes<T> => {
  const [quotes, setQuotes] = useState<QuotesStore>({});

  useEffect(
    () =>
      store.subscribe(nextState => {
        setQuotes(prevQuotes => ({ ...prevQuotes, ...nextState.quotes }));
      }),
    []
  );

  const setSymbols = (symbol: SymbolType) => {
    let symbols: Record<string, string> = {};

    if (Array.isArray(symbol)) {
      symbols = symbol.reduce((p, cur) => {
        return { ...p, [cur]: cur };
      }, {});
    } else {
      symbols = { [symbol]: symbol };
    }

    getQuotes(symbol);
    store.dispatch({ symbols });
  };

  const setStaticSymbol = async (symbol: string) => {
    store.dispatch({ staticSymbol: symbol });

    try {
      const res = await getQuotes(symbol);

      return res;
    } catch (e) {
      return null;
    }
  };

  return [quotes, setSymbols, setStaticSymbol];
};

export default sharedQuotes<QuoteUniversal>();
