import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import styles from './portfolio-header.scss';
import Variation from 'constant/variation';
import { sendEventLog, SEND_PROJ_TARGET } from 'utils/analytics';
import { login } from 'hooks/auth';
import useSearch from 'hooks/search';
import SearchResult from 'components/SearchResult/SearchResult';
import gaDataset from 'fe-common-library/dest/utils/gaDataset';
import sendDataLayer from 'fe-common-library/dest/utils/sendDataLayer';

interface PortfolioHeaderProps {
  isLogin: boolean;
  onGroupChange: (i: number) => void;
  onSymbolSelected: (symbol: string) => void;
  currentGroup: number;
}

function PortfolioHeader({ isLogin, onSymbolSelected, onGroupChange, currentGroup }: PortfolioHeaderProps) {
  const [searchResult, search, clearSearch] = useSearch();
  const [selectedSearchIndex, setSelectedSearchIndex] = useState<number>(0);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const input = useRef<HTMLInputElement | null>(null);
  const nextPage = useRef<() => void>(() => {});
  const keyword = useRef<string>('');

  useEffect(() => {
    if (input.current && showSearch) {
      input.current.focus();
    }
  }, [showSearch]);

  useEffect(
    function() {
      if (!isLogin && showSearch) {
        setShowSearch(false);
        clearSearch();
      }
    },
    [isLogin]
  );

  async function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const keyWord = e.target.value;
    keyword.current = keyWord;

    if (keyWord) {
      nextPage.current = await search(keyWord);
    } else {
      clearSearch();
    }
  }

  function renderControls() {
    if (showSearch) {
      return (
        <input
          ref={input}
          onKeyDown={handleKey}
          onChange={handleSearch}
          className={styles.search}
          placeholder="輸入股票代碼或名稱"
        />
      );
    }
    return (
      <ul className={`row ${styles.pager}`}>
        {[1, 2, 3, 4].map(i => (
          <li
            key={i}
            data-active={i - 1 === currentGroup}
            data-anue-ga-event={`${Variation.stockChannelName},投資組合選擇 click,${isLogin ? i : '登入'},proj`}
            {...gaDataset({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_投資組合',
              clickItem: !isLogin ? '登入' : i,
            })}
            onClick={() => {
              if (!isLogin) {
                login('login_plus');
              } else {
                onGroupChange(i - 1);
              }
            }}
          >
            {i}
          </li>
        ))}
      </ul>
    );
  }

  function handleKey(e: React.KeyboardEvent) {
    switch (e.keyCode) {
      case 13:
        handleItemSelected(selectedSearchIndex);
        break;
      case 40:
        setSelectedSearchIndex(Math.min(selectedSearchIndex + 1, searchResult.length - 1));
        break;
      case 38:
        setSelectedSearchIndex(Math.max(selectedSearchIndex - 1, 0));
        break;
      case 27:
        handleAction();
        break;
    }
  }

  function handleAction() {
    if (isLogin) {
      clearSearch();
      setShowSearch(!showSearch);
    } else {
      login('login_plus');
      sendEventLog(Variation.stockChannelName, '新增自選 click', '登入', {}, SEND_PROJ_TARGET);
      sendDataLayer({
        dataPrefix: SEND_PROJ_TARGET,
        eventName: 'Click_新增自選',
        section: '投資組合',
        clickItem: '登入',
      });
    }
  }

  function handleHover(index: number) {
    setSelectedSearchIndex(index);
  }

  function handleItemSelected(index: number) {
    const result = searchResult[index];
    if (result && result.symbol) {
      onSymbolSelected(result.symbol.toUpperCase());
      sendEventLog(
        Variation.stockChannelName,
        '新增自選 click',
        `${currentGroup}_${result.symbol.toUpperCase()}`,
        {},
        SEND_PROJ_TARGET
      );
      sendDataLayer({
        dataPrefix: SEND_PROJ_TARGET,
        eventName: 'Click_新增自選',
        section: '投資組合',
        clickItem: `${Number(currentGroup) + 1}_${result.symbol}_${result.chName
          .replace('<mark>', '')
          .replace('</mark>', '') || result.enName}`,
      });
    }
    if (input.current) {
      input.current.value = '';
    }
    clearSearch();
  }

  const searchWindowHeight = searchResult.length > 0 ? Math.min(searchResult.length * 45, 250) : 0;

  return (
    <section className={`row ${styles.container}`}>
      <img src={require('assets/icon-star-empty.svg')} alt="投資組合" />
      <h2>投資組合</h2>
      {renderControls()}
      <img
        alt="加入投資組合"
        className={styles.action}
        onClick={handleAction}
        src={showSearch ? require('assets/icon-cancel-addstock.svg') : require('assets/icon-add-fav.svg')}
      />
      <SearchResult
        data={keyword.current ? searchResult : []}
        top={40}
        height={searchWindowHeight}
        onMouseEnter={handleHover}
        selectedIndex={selectedSearchIndex}
        onPageEnd={() => {
          nextPage.current();
        }}
        onClick={handleItemSelected}
      />
    </section>
  );
}

export default PortfolioHeader;
