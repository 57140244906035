import 'firebase/database';
import firebase from 'firebase/app';
import idx from 'idx';
import { useReducer, useEffect } from 'react';
import useAuth, { getProfile } from 'hooks/auth';
import globalConfig from 'constant/globalConfig';
import Eventy from 'anue-fe-sdk/eventy';

export const event = new Eventy();

export enum NotifyEvents {
  UPDATE_NOTIFY,
  UPDATE_ORDER,
  UPDATE_TAGNEWS,
  UPDATE_STATUS,
}

export const NotifyVar = {
  ref: 'in-web-notify',
  videoOrder: 'inWebOrderNotify',
  videoActivity: 'inWebActivityNotify',
  tagNews: 'inWebTagNewsNotify',
};

const firebaseConfig = {
  apiKey: globalConfig.FirebaseApiKey,
  databaseURL: globalConfig.FirebaseDatabaseURL,
};

const initNotifyState: InWebNotify.State = {
  inWebOrderNotify: false,
  inWebActivityNotify: false,
  inWebTagNewsNotify: false,
};

let globalNotifyState: InWebNotify.State = {
  ...initNotifyState,
};

let notifyHook;

function reducer(state: InWebNotify.State, action: InWebNotify.Action) {
  let nextState = { ...state };

  switch (action.type) {
    case NotifyVar.videoOrder:
    case NotifyVar.videoActivity:
    case NotifyVar.tagNews:
      nextState[action.type] = action.data;
      break;
    case NotifyVar.ref:
      nextState = action.data;
      break;
    default:
      nextState = state;
      break;
  }

  globalNotifyState = nextState;

  return nextState;
}

export function getNotify(type) {
  if (type) {
    return idx(globalNotifyState, _ => _[type]) || false;
  } else {
    return false;
  }
}

export function initNotify() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  return firebase.database();
}

function handleNotifyChange(snapshot) {
  if (snapshot.val()) {
    const notifyObj = snapshot.val();
    event.emit(NotifyEvents.UPDATE_NOTIFY, notifyObj);
  } else {
    console.log('may not init this identity_id');
  }
}

export default function useNotify(): [InWebNotify.State] {
  const [authState] = useAuth();
  const [state, dispatch] = useReducer(reducer, globalNotifyState);

  useEffect(function() {
    const removeNotifyListener = event.on(NotifyEvents.UPDATE_NOTIFY, function(nextNotify) {
      dispatch({
        type: NotifyVar.ref,
        data: nextNotify,
      });
    });
    return () => {
      removeNotifyListener();
    };
  }, []);

  useEffect(
    function() {
      const profile = getProfile();
      if (authState && profile) {
        const userId = profile.identity_id;
        const db = initNotify();
        notifyHook = db.ref(`${NotifyVar.ref}/${userId}`);
        notifyHook.on('value', handleNotifyChange);
      } else {
        event.emit(NotifyEvents.UPDATE_NOTIFY, initNotifyState);
        if (notifyHook) {
          notifyHook.off('value', handleNotifyChange);
        }
      }
    },
    [authState]
  );

  return [state];
}
