import React, { Component, PropTypes } from 'react';
import { DFPProvider } from 'cnyes-dfp';
import AdProfiles from './AdProfiles';
import Wally from './WallyContainer';
import './DFP.scss';

class DFPProviderWrapper extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  };

  handleSlotRenderEnded = (provider, event) => {
    const adElement = document.getElementById(`${event.slot.getSlotElementId()}-container`);
    // Use it with liveStreamBlockVideoAd
    const videoAdWrapper = document.querySelector('.video-ad-wrapper');

    switch (event.slot.getAdUnitPath()) {
      case AdProfiles.liveStreamBlockVideoAd.path: {
        if (!event.isEmpty && adElement && videoAdWrapper) {
          // Display the wrapper of the video ad
          videoAdWrapper.style.display = 'block';

          const videoIframeWrapper = adElement.querySelector('iframe');
          // Track the width of the video iframe
          const inlineStyleWidthValue = /width: (\d+)px/;
          // Set up a attribution observer
          const videoAdPlayingMutationObserver = new MutationObserver(mutationList => {
            if (mutationList && mutationList.length > 0) {
              mutationList.forEach(mutation => {
                if (mutation.type === 'attributes') {
                  // Find width of the iframe from the attributes
                  const iframeWidth = inlineStyleWidthValue.exec(String(mutation.target.attributes.style.value));

                  // Hide the ad wrapper and disconnect with the observer if width of the iframe is zero.
                  if (iframeWidth && Number(iframeWidth[1]) === 0) {
                    // Disconnect the observer
                    videoAdPlayingMutationObserver.disconnect();
                    // Remove the style.ad and the wrapper
                    videoAdWrapper.style.display = 'none';
                  }
                }
              });
            }
          });

          // Hook the iframe with the observer and only observe the style change
          videoAdPlayingMutationObserver.observe(videoIframeWrapper, {
            attributeFilter: ['style'],
          });
        }

        break;
      }
      case AdProfiles.bottomLeftNewsListA.path:
      case AdProfiles.adSetBesideSearchBarA.path:
      case AdProfiles.adSetBesideSearchBarB.path: {
        if (!event.isEmpty && adElement) {
          adElement.style.display = 'block';
        }

        break;
      }
      case AdProfiles.nativeAdStockTalkChatroom.path:
        if (!event.isEmpty && adElement) {
          adElement.style.display = 'block';
        }
        break;
      default:
        break;
    }

    if (event.isEmpty && adElement) {
      adElement.style.display = 'none';
    }
  };

  render() {
    return (
      <DFPProvider onSlotRenderEnded={this.handleSlotRenderEnded} loadGPT>
        <Wally>
          {this.props.children}
        </Wally>
      </DFPProvider>
    );
  }
}

export default DFPProviderWrapper;
