import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import pad from 'utils/pad';
import XOR from 'utils/XOREncryption';
import { isLogin } from 'hooks/auth';
import { event, MessageStatus, sendMessage, reply } from 'hooks/chat';

// import AllowedLink from './AllowedLink';
import styles from './chat-message-classic.scss';
import RichChatMessage from 'components/RichChatMessage';

export const LinkReg = /(\[.*?\]\(http[s]?:\/\/\S+\))/g;
export const LinkSeperatorReg = /\[title:(.*)\|cover:(\S*)\]\((http[s]?:\/\/\S+)\)/i;
export const LinkSeperatorBasicReg = /\[(.*)\]\((http[s]?:\/\/\S+)\)/i;
export const LinkSeperatorThumbnailOnly = /\[(.*)\]\((https:\/\/www.tradingview.com\/\S+)\)/i;

interface ChatMessageProps extends ChatMessage {
  compact?: boolean;
}

function ChatMessage({ profile, text, time, type, mid, animated, token, status }: ChatMessageProps) {
  if (!profile) {
    return null;
  }

  const { name, vip, avatar, uid } = profile;
  const [runtimeState, setRuntimeState] = useState<{
    status?: MessageStatus;
    content?: string;
  }>({});
  const messageClassName = [styles.message];
  const computedStatus = runtimeState.status || status;

  useEffect(() => {
    let remove;

    if (status === MessageStatus.SENDING) {
      remove = event.on(`${token}`, messageState => {
        console.log('receive remote state', messageState);
        setRuntimeState(messageState);
      });
    }
    return () => {
      if (remove) {
        remove();
      }
    };
  }, []);

  if (animated) {
    messageClassName.push(styles.animate);
  } else {
    messageClassName.push(styles['no-animate']);
  }

  const isSending = computedStatus === MessageStatus.SENDING;
  const isFailed = computedStatus === MessageStatus.FAILED;

  let colorClass = '';

  if (vip) {
    if (String(type).indexOf('groupDownThreshold') > -1 || type === 'limitDown') {
      colorClass = styles['vip-link-down'];
    } else if (String(type).indexOf('groupUpThreshold') > -1 || type === 'limitUp') {
      colorClass = styles['vip-link-up'];
    } else {
      colorClass = styles['vip-link-default'];
    }
  } else {
    colorClass = styles.default;
  }

  const contentProps: any = {
    className: styles.content,
  };
  const inner = (
    <div className={`${styles.text} ${styles.left}`} data-mid={mid}>
      <RichChatMessage name={name} text={runtimeState.content || text} type={type} vip={vip > 0} />
    </div>
  );

  function renderStatus() {
    if (isSending) {
      return '傳送中';
    } else if (isFailed) {
      return '重送';
    }
    if (!time) {
      return null;
    }
    const now = new Date(time * 1000);
    return `${pad(now.getHours(), 2, '0')}:${pad(now.getMinutes(), 2, '0')}`;
  }

  function handleReply() {
    // TODO: enable message reply function
    // if (isLogin() && false) {
    //   const source = document.querySelector<HTMLDivElement>(
    //     `[data-mid="${mid}"]`
    //   );
    //   reply(source ? source.innerText : '', profile);
    // }
  }

  return (
    <li
      className={classNames(messageClassName, colorClass)}
      data-time={time}
      data-anue={encodeURIComponent(XOR(`${uid}`))}
      data-id={mid}
      onClick={handleReply}
    >
      <img className={styles.avatar} src={avatar} alt={name} />
      <div {...contentProps}>
        <span className={styles.author}>
          {vip === 1 && <img alt={name} className={styles.vipicon} src={require('assets/icon-vip.svg')} />}
          {name}
        </span>
        {inner}
      </div>
      <span
        className={`${styles.time} ${isFailed ? styles.resend : styles.normal}`}
        onClick={e => {
          e.stopPropagation();
          if (isFailed) {
            sendMessage(text, token);
          }
        }}
      >
        {renderStatus()}
      </span>
    </li>
  );
}

export default React.memo(
  (props: ChatMessageProps) => <ChatMessage {...props} />,
  (prev, next) => prev.mid === next.mid && next.status === prev.status && next.isMyMessage === prev.isMyMessage
);
