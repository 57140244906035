const config = (function getConfig(hostname) {
  switch (hostname) {
    case 'localhost.int.cnyes.cool':
    case 'stock.int.cnyes.cool':
    case 'global-stock.int.cnyes.cool':
      return {
        twStockUrl: 'https://stock.int.cnyes.cool',
        globalStockUrl: 'https://global-stock.int.cnyes.cool',
        loginUrl: 'https://login.int.cnyes.cool',
        newsUrl: 'https://news.int.cnyes.cool',
        // Google Analytics
        googleAnalyticsId: 'UA-21465399-85',
        googleAnalyticsIdForCross: 'UA-21465399-75',
        googleAnalyticsIdForProj: 'UA-21465399-46',
        googleAnalyticsIdForGlobal: 'UA-21465399-86',
        // Google Tag Manager
        googleTagManagerId: 'GTM-582TRFS',
        googleTagManagerContainerId: 'GTM-NRQBTT2',
        googleTagManagerContainerIdForProj: 'GTM-Proj-Int',
        googleTagManagerContainerIdForGlobal: 'GTM-Global-Int',
        // PIWIK
        piwikScript: 'https://b.cnyes.cool/c-spa.js',
        // AWS region
        region: 'ap-northeast-1',
        // cognito Identity pool id
        IdentityPoolId: 'ap-northeast-1:1c765baf-a934-4d61-b757-2457369d3f05',
        // FB AppId
        FBAppId: '591048477746530',
        // Google AppId
        GoogleAppId: '600742427084-7mss8ce6rn4d6mpeaibspc7j1vb2jg8g.apps.googleusercontent.com',
        // Google AppKey
        GoogleAppKey: 'hgfort0WGSAuokwv8cD0A00G',
        // Firebase api key
        FirebaseApiKey: 'AIzaSyBV0FBv9VpO7Nb15FUfXMMs4jwlrZf5VqA',
        // Firebase database url
        FirebaseDatabaseURL: 'https://cnyes-int.firebaseio.com',
        videoAPI: 'https://video.api.int.cnyes.cool',
        reuterAPI: 'https://ws.api.int.cnyes.cool',
        marketAPI: 'https://marketinfo.api.int.cnyes.cool',
        defaultAPI: 'https://api.int.cnyes.cool',
        essAPI: 'https://ess.api.int.cnyes.cool',
        EsunStockOrderIframeURL: 'https://hastradingsit.esunsec.com.tw',
        memberServiceEndpoint: 'https://member.api.int.cnyes.cool',
        TrackJsApp: 'stock-fe-beta',
        CnyesBaseUrl: 'int.cnyes.cool',
      };
    case 'localhost.gamma.cnyes.cool':
    case 'stock.gamma.cnyes.cool':
    case 'global-stock.gamma.cnyes.cool':
      return {
        twStockUrl: 'https://stock.gamma.cnyes.cool',
        globalStockUrl: 'https://global-stock.gamma.cnyes.cool',
        channel: 'stock-gamma',
        loginUrl: 'https://login.gamma.cnyes.cool',
        newsUrl: 'https://news.cnyes.com',
        // Google Analytics
        googleAnalyticsId: 'UA-21465399-84',
        googleAnalyticsIdForCross: 'UA-21465399-75',
        googleAnalyticsIdForProj: 'UA-21465399-46',
        googleAnalyticsIdForGlobal: 'UA-21465399-86',
        // Google Tag Manager
        googleTagManagerId: 'GTM-582TRFS',
        googleTagManagerContainerId: 'GTM-NRQBTT2',
        googleTagManagerContainerIdForProj: 'GTM-Proj-Stage',
        googleTagManagerContainerIdForGlobal: 'GTM-582TRFS',
        // PIWIK
        piwikScript: 'https://b.cnyes.cool/c-spa.js',
        // AWS region
        region: 'ap-northeast-1',
        // cognito Identity pool id
        IdentityPoolId: 'ap-northeast-1:a5d7410a-113e-4dcd-ace0-fd5be54770f9',
        // Google AppId
        GoogleAppId: '307675903684-hqt34o21olpi8cdsbjuf89inm5b6mf5r.apps.googleusercontent.com',
        // Google AppKey
        GoogleAppKey: 'ogVTUrfHqf-xmRSCPXGnilKG',
        // FB AppId
        FBAppId: '325242790993768',
        // Firebase api key
        FirebaseApiKey: 'AIzaSyDSF5-ONm9E98aycx06u6HxpPyxfAWHTUo',
        // Firebase database url
        FirebaseDatabaseURL: 'https://cnyes-prod-1307.firebaseio.com/',
        videoAPI: 'https://video.api.cnyes.com',
        reuterAPI: 'https://ws.api.cnyes.com',
        marketAPI: 'https://marketinfo.api.cnyes.com',
        defaultAPI: 'https://api.cnyes.com',
        essAPI: 'https://ess.api.cnyes.com',
        EsunStockOrderIframeURL: 'https://hastradinguat.esunsec.com.tw',
        memberServiceEndpoint: 'https://member.api.prod.cnyes.com',
        memberAlwaysRightAPI: 'https://member-always-right.api.cnyes.com',
        TrackJsApp: 'stock-fe-gamma',
        CnyesBaseUrl: 'gamma.cnyes.cool',
      };
    case 'localhost.stage.cnyes.cool':
    case 'stock.stage.cnyes.cool':
    case 'global-stock.stage.cnyes.cool':
      return {
        twStockUrl: 'https://stock.stage.cnyes.cool',
        globalStockUrl: 'https://global-stock.stage.cnyes.cool',
        channel: 'stock-stage',
        loginUrl: 'https://login.stage.cnyes.cool',
        newsUrl: 'https://news.cnyes.com',
        // Google Analytics
        googleAnalyticsId: 'UA-21465399-84',
        googleAnalyticsIdForCross: 'UA-21465399-75',
        googleAnalyticsIdForProj: 'UA-21465399-46',
        googleAnalyticsIdForGlobal: 'UA-21465399-86',
        // Google Tag Manager
        googleTagManagerId: 'GTM-582TRFS',
        googleTagManagerContainerId: 'GTM-NRQBTT2',
        googleTagManagerContainerIdForProj: 'GTM-Proj-Stage',
        googleTagManagerContainerIdForGlobal: 'GTM-KJPTVH4',
        // PIWIK
        piwikScript: 'https://b.cnyes.cool/c-spa.js',
        // AWS region
        region: 'ap-northeast-1',
        // cognito Identity pool id
        IdentityPoolId: 'ap-northeast-1:a5d7410a-113e-4dcd-ace0-fd5be54770f9',
        // Google AppId
        GoogleAppId: '307675903684-hqt34o21olpi8cdsbjuf89inm5b6mf5r.apps.googleusercontent.com',
        // Google AppKey
        GoogleAppKey: 'ogVTUrfHqf-xmRSCPXGnilKG',
        // FB AppId
        FBAppId: '325242790993768',
        // Firebase api key
        FirebaseApiKey: 'AIzaSyDSF5-ONm9E98aycx06u6HxpPyxfAWHTUo',
        // Firebase database url
        FirebaseDatabaseURL: 'https://cnyes-prod-1307.firebaseio.com/',
        videoAPI: 'https://video.api.cnyes.com',
        reuterAPI: 'https://ws.api.cnyes.com',
        marketAPI: 'https://marketinfo.api.cnyes.com',
        defaultAPI: 'https://api.cnyes.com',
        essAPI: 'https://ess.api.cnyes.com',
        EsunStockOrderIframeURL: 'https://hastradinguat.esunsec.com.tw',
        memberServiceEndpoint: 'https://member.api.cnyes.com',
        memberAlwaysRightAPI: 'https://member-always-right.api.cnyes.com',
        TrackJsApp: 'stock-fe-beta',
        CnyesBaseUrl: 'stage.cnyes.cool',
      };
    case 'localhost.prod.cnyes.cool':
    case 'stock.cnyes.com':
    case 'global-stock.cnyes.com':
      return {
        twStockUrl: 'https://stock.cnyes.com',
        globalStockUrl: 'https://global-stock.cnyes.com',
        loginUrl: 'https://login.cnyes.com',
        newsUrl: 'https://news.cnyes.com',
        // Google Analytics
        googleAnalyticsId: 'UA-21465399-83',
        googleAnalyticsIdForCross: 'UA-21465399-47',
        googleAnalyticsIdForProj: 'UA-145056278-5',
        googleAnalyticsIdForGlobal: 'UA-145056278-1',
        // Google Tag Manager
        googleTagManagerId: 'GTM-582TRFS',
        googleTagManagerContainerId: 'GTM-PNR8BZV',
        googleTagManagerContainerIdForProj: 'GTM-T3FF4WB',
        googleTagManagerContainerIdForGlobal: 'GTM-582TRFS',
        // PIWIK
        piwikScript: 'https://b.cnyes.cool/b-spa.js',
        // AWS region
        region: 'ap-northeast-1',
        // cognito Identity pool id
        IdentityPoolId: 'ap-northeast-1:a5d7410a-113e-4dcd-ace0-fd5be54770f9',
        // Google AppId
        GoogleAppId: '307675903684-hqt34o21olpi8cdsbjuf89inm5b6mf5r.apps.googleusercontent.com',
        // Google AppKey
        GoogleAppKey: 'ogVTUrfHqf-xmRSCPXGnilKG',
        // FB AppId
        FBAppId: '325242790993768',
        // Firebase api key
        FirebaseApiKey: 'AIzaSyDSF5-ONm9E98aycx06u6HxpPyxfAWHTUo',
        // Firebase database url
        FirebaseDatabaseURL: 'https://cnyes-prod-1307.firebaseio.com/',
        videoAPI: 'https://video.api.cnyes.com',
        reuterAPI: 'https://ws.api.cnyes.com',
        marketAPI: 'https://marketinfo.api.cnyes.com',
        defaultAPI: 'https://api.cnyes.com',
        essAPI: 'https://ess.api.cnyes.com',
        EsunStockOrderIframeURL: 'https://hastrading.esunsec.com.tw',
        memberServiceEndpoint: 'https://member.api.cnyes.com',
        memberAlwaysRightAPI: 'https://member-always-right.api.cnyes.com',
        TrackJsApp: 'stock-fe-prod',
        CnyesBaseUrl: 'cnyes.com',
      };
    case 'localhost.beta.cnyes.cool':
    case 'stock.beta.cnyes.cool':
    case 'global-stock.beta.cnyes.cool':
    case 'global-stock-dev.beta.cnyes.cool':
    case 'stock-dev.beta.cnyes.cool':
    case 'app.beta.cnyes.cool':
    default:
      return {
        twStockUrl: 'https://stock.beta.cnyes.cool',
        globalStockUrl: 'https://global-stock.beta.cnyes.cool',
        loginUrl:
          hostname.indexOf('stock-dev') === 0 ? 'https://login-dev.beta.cnyes.cool' : 'https://login.beta.cnyes.cool',
        newsUrl: 'https://news.beta.cnyes.cool',
        // Google Analytics
        googleAnalyticsId: 'UA-21465399-85',
        googleAnalyticsIdForCross: 'UA-21465399-75',
        googleAnalyticsIdForProj: 'UA-21465399-46',
        googleAnalyticsIdForGlobal: 'UA-21465399-86',
        // Google Tag Manager
        googleTagManagerId: 'GTM-KJPTVH4',
        googleTagManagerContainerId: 'GTM-NRQBTT2',
        googleTagManagerContainerIdForProj: 'GTM-Proj-Beta',
        googleTagManagerContainerIdForGlobal: 'GTM-KJPTVH4',
        // PIWIK
        piwikScript: 'https://b.cnyes.cool/c-spa.js',
        // AWS region
        region: 'ap-northeast-1',
        // cognito Identity pool id
        IdentityPoolId: 'ap-northeast-1:2d36a566-b3c0-4826-b370-8b3d9a7a79df',
        // Google AppId
        GoogleAppId: '877358328802-gev051j1ljrdibo6i5f09c5njaa9ra9r.apps.googleusercontent.com',
        // Google AppKey
        GoogleAppKey: 'mJZO0SJVc5jMN_tMwak5lyli',
        // FB AppId
        FBAppId: '591048567746521',
        // Firebase api key
        FirebaseApiKey: 'AIzaSyCigMeA6vhmWI2PrgPzut9yJY3Qtqtu5jY',
        // Firebase database url
        FirebaseDatabaseURL: 'https://cnyes-beta.firebaseio.com',
        videoAPI: 'https://video.api.beta.cnyes.cool',
        reuterAPI: 'https://ws.api.beta.cnyes.cool',
        marketAPI: 'https://marketinfo.api.beta.cnyes.cool',
        defaultAPI: 'https://api.beta.cnyes.cool',
        essAPI: 'https://ess.api.beta.cnyes.cool',
        EsunStockOrderIframeURL: 'https://hastradingsit.esunsec.com.tw',
        memberServiceEndpoint: 'https://member.api.beta.cnyes.cool',
        memberAlwaysRightAPI: 'https://member-always-right.api.beta.cnyes.cool',
        TrackJsApp: 'stock-fe-beta',
        CnyesBaseUrl: 'beta.cnyes.cool',
      };
  }
})(typeof window === 'undefined' ? 'app.beta.cnyes.cool' : window.location.hostname);

const ua = navigator.userAgent;

export default {
  ...config,
  isIE: ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1,
};
