// Service worker somehow breaks TradingView, so we're going to disable it on Window system.
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker
      .register(`/sw-${env.md5}.js`)
      .then(function(reg) {
        console.log('ServiceWorker registration successful with scope: ', reg.scope);
      })
      .catch(function(err) {
        console.log('service worker failed to register', err);
      });
  });
}
