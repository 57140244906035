import React, { PureComponent, PropTypes } from 'react';
import styles from './SimpleModal.scss';

const styleType = PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]);

class SimpleModal extends PureComponent {
  static propTypes = {
    overlayStyle: styleType,
    containerStyle: styleType,
    closeHandleStyle: styleType,
    enableModal: PropTypes.bool,
    enableClose: PropTypes.bool,
    closeHandler: PropTypes.func,
    children: PropTypes.element.isRequired,
  };

  static defaultProps = {
    overlayStyle: {},
    containerStyle: {},
    closeHandleStyle: {},
    enableModal: false,
    enableClose: true,
    closeHandler: undefined,
  };

  handleClose = e => {
    if (e) {
      e.preventDefault();
    }

    if (this.props.closeHandler) {
      this.props.closeHandler(e);
    }
  };

  render() {
    const { overlayStyle, containerStyle, closeHandleStyle, enableClose, enableModal } = this.props;

    if (enableModal) {
      return (
        <div className={styles.wrapper} style={overlayStyle}>
          <div className={styles.container} style={containerStyle}>
            {enableClose &&
              <div className={styles['close-handle']} style={closeHandleStyle} onClick={this.handleClose} />}
            {this.props.children}
          </div>
        </div>
      );
    }

    return null;
  }
}

export default SimpleModal;
