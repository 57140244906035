import * as React from 'react';
import globalConfig from 'constant/globalConfig';
import { getProfile } from 'hooks/auth';
import history from 'utils/history';
import { sendEventLog, SEND_PROJ_TARGET } from 'utils/analytics';
import Variation from 'constant/variation';
import AllowedLink from 'components/AllowedLink/AllowedLink';
import EsunOpenButton from 'components/EsunOpenButton';
import styles from './rich-chat-message.scss';

interface RichChatMessageProps {
  text: string;
  type: string;
  vip?: boolean;
  name: string;
}

enum LinkType {
  Tag,
  ValidLink,
  Reply,
  Text,
  Invalid,
}

interface AbstractLink {
  type: LinkType;
  props: Record<string, any>;
}

const mdLinkRgx = /\[[^\]]+\]\([^\)]+\)/g;

function parse(text): AbstractLink[] {
  const abstracts: AbstractLink[] = [];

  text = text.replace(mdLinkRgx, (link: string) => {
    const [args, src] = link.substr(1, link.length - 2).split('](');
    let linkType = LinkType.Invalid;
    let props: Record<string, any> = {};
    const matchesTag = src.split(':');
    const matchesTradingView = src.indexOf('https://www.tradingview.com') === 0;

    // reply message
    if (args.indexOf('@') === 0) {
      linkType = LinkType.Reply;
      props = {
        name: args.substr(1),
        quote: src,
      };
    }
    // allowed url link patterns (news, trading view)
    else if (
      matchesTradingView ||
      src.indexOf(globalConfig.newsUrl) === 0 ||
      src.indexOf('https://news.cnyes.com') === 0 ||
      src.indexOf('https://m.cnyes.com') === 0
    ) {
      linkType = LinkType.ValidLink;
      // arguments are separated by | , e.g. [title: foo|cover:https://example.com/img.png]
      props = args.split('|').reduce(
        (p, c) => {
          const chunks = c.split(':');
          const argName = chunks.shift();
          if (argName) {
            p[argName] = chunks.join(':');
          }
          return p;
        },
        {
          hint: matchesTradingView ? '圖表' : '新聞',
          cover: matchesTradingView ? src : null,
          href: src,
        }
      );
    }
    // symbol tag patterns [台積電](TWS:2330:STOCK)
    else if (matchesTag.length === 3) {
      linkType = LinkType.Tag;
      props = { name: args, code: matchesTag[2], symbol: src };
    }

    if (linkType === LinkType.Invalid) {
      return link;
    }
    abstracts.push({
      type: linkType,
      props,
    });
    return '${stub}';
  });

  return text.split('${stub}').reduce((p, c) => {
    p.push({
      type: LinkType.Text,
      props: c,
    });
    const nextJSX = abstracts.shift();
    if (nextJSX) {
      p.push(nextJSX);
    }
    return p;
  }, []);
}

function RichChatMessage({ text, type, vip }: RichChatMessageProps) {
  const parts = parse(text);
  const userProfile = getProfile();

  return (
    <>
      {parts.map((def, i) => {
        switch (def.type) {
          case LinkType.ValidLink:
            return (
              <AllowedLink
                key={`jsx-${i}-a`}
                typeName={def.props.hint}
                cover={def.props.cover}
                title={def.props.title}
                href={def.props.href}
              />
            );
          case LinkType.Tag:
            const showEsunButton = /^t.+:stock$/i.test(def.props.symbol);
            return (
              <a
                key={`jsx-tag-${i}`}
                href={`/market/${def.props.symbol}`}
                data-stock-tag={def.props.symbol}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  sendEventLog(
                    Variation.stockChannelName,
                    '聊天室 click',
                    type === 'user' ? `用戶{${def.props.name.slice(1)}}` : `機器人{${def.props.name.slice(1)}}`,
                    {},
                    SEND_PROJ_TARGET
                  );
                  history.getHistory().push(`/market/${def.props.symbol}`);
                }}
                data-ga-category={`Hashtag_Click_${type}`}
                data-ga-action="Click"
                data-ga-label={type}
              >
                {def.props.name}
                {!vip && showEsunButton && (
                  <EsunOpenButton
                    name={def.props.name}
                    symbol={def.props.symbol}
                    trackingClickFrom="聊天室"
                    userId={userProfile && userProfile.uid}
                    cb={() => {
                      history.getHistory().push(`/market/${def.props.symbol}`);
                    }}
                  />
                )}
              </a>
            );
          case LinkType.Reply:
            return (
              <div key={`jsx-reply-${i}`} className={styles.quote}>
                <span>回應 {def.props.name}:</span>
                <i>{def.props.quote}</i>
              </div>
            );
          case LinkType.Text:
            return def.props;
          default:
            return null;
        }
      })}
    </>
  );
}

export default React.memo((props: RichChatMessageProps) => <RichChatMessage {...props} />);
