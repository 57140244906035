import React, { Component } from 'react';
import 'fe-common-library/dest/components/NotFound/style.css';
import NotFound from 'fe-common-library/dest/components/NotFound';
import Header from 'widgets/Header';
import DFPProviderWrapper from 'components/DFP/DFPProviderWrapper';

class NotFoundPage extends Component {
  render() {
    return (
      <DFPProviderWrapper>
        <div style={{ backgroundColor: '#f1f1f1' }}>
          <Header />
            <NotFound mode="desktop" rootStyle={{ height: 'calc(100vh - 47px)' }} />
        </div>
      </DFPProviderWrapper>
    );
  }
}

export default NotFoundPage;
