import idx from 'idx';

import Variation from 'constant/variation';
import universalProps from 'constant/universal-props';
import { GETv1ChartHistory, GETv1SymbolByUrl } from 'utils/api';

const debug = false;
let id = 0;

function log(...args) {
  if (debug) {
    console.log(...args);
  }
}

function normalizeSymbol(symbol: string = '') {
  return symbol
    .split(':')
    .slice(-3)
    .join(':');
}

/**
 * DataFeed implementation followed TradingView JS API
 * {@see https://github.com/tradingview/charting_library/wiki/JS-Api}
 */
export default class DataFeedImpl implements DataFeedAbstract {
  config: DataFeedConfig;
  latestBarMap: Record<string, number> = {};
  subscribes = {};
  isRealtime: boolean = true;
  fid: number;
  lastPriceMap: Record<string, number> = {};
  marginMap: Record<string, number[]> = {};
  sessionMap: Record<string, number[]> = {};

  constructor(config: DataFeedConfig) {
    this.config = config;
    this.fid = ++id;
  }

  private requestBarsAndUpdateTimeCache = async ({
    symbol,
    resolution,
    from,
    to,
    latestOnly = false,
  }: DataFeedRequestBarArgs): Promise<{
    bars: DataFeedBarData[];
    max: number;
  } | void> => {
    symbol = normalizeSymbol(symbol);

    const cacheKey = `${symbol}${resolution}`;
    if (isNaN(from) || isNaN(to)) {
      return;
    }
    const resp = await GETv1ChartHistory({
      symbol,
      resolution,
      quote: 1,
      from: Math.max(from, to),
      to: Math.min(from, to),
    });

    const data = idx(resp, _ => _.data.data) || {};
    const { t = [], c = [], o = [], v = [], h = [], l = [] } = data;
    let max = this.latestBarMap[cacheKey] || -1;

    log('filter response data with key %o', cacheKey);

    const result = t.reduce<DataFeedBarData[]>((p, current, i) => {
      const isNewBar = current > max;

      if ((latestOnly && isNewBar) || !latestOnly) {
        const bar = {
          time: current * 1000,
          volume: v[i],
          open: o[i],
          close: c[i],
          high: h[i],
          low: l[i],
        };
        p.push(bar);
      }

      if (isNewBar) {
        max = current;
      }

      return p;
    }, []);

    log('update latest timestamp [%o] from %o to %o', cacheKey, this.latestBarMap[cacheKey], max);
    this.latestBarMap[cacheKey] = max;
    log('%cget updated %o bars %o ', 'color: brown', result.length);

    // update max-min margin of the symbol if resolution is "1"
    if (resolution === '1') {
      this.marginMap[symbol] = this.marginMap[symbol] || [99999999, -1];

      result.forEach(b => {
        if (b.close > this.marginMap[symbol][1]) {
          this.marginMap[symbol][1] = b.close;
        }
        if (b.close < this.marginMap[symbol][0]) {
          this.marginMap[symbol][0] = b.close;
        }
      });

      // emit data range change event so real-time chart can re-calculate visible range
      if (this.config.onDataRangeChange) {
        this.config.onDataRangeChange();
      }
    }

    return {
      bars: result.sort((a, b) => (a.time < b.time ? -1 : 1)),
      max,
    };
  };

  getPriceMarginBySymbol = symbol => this.marginMap[symbol];

  getLastCloseBySymbol = symbol => this.lastPriceMap[symbol];

  cleanup = () => {
    for (const i in this.subscribeBars) {
      clearInterval(this.subscribeBars[i]);
      delete this.subscribeBars[i];
    }
    this.isRealtime = false;
  };

  /**
   * A function which called when chat is ready with supplied configuration object.
   *
   * @param fn This call is intended to provide the object filled with the configuration data.
   * This data partially affects the chart behavior and is called server-side customization.
   * Charting Library assumes that you will call the callback function and pass your datafeed
   * configurationData as an argument.
   * Configuration data is an object
   */
  onReady = (fn: DataFeedOnReadyCallback) => {
    if (this.config) {
      setTimeout(() => {
        fn(this.config);
      });
    } else {
      throw {
        detail: 'DataFeedImpl error, onReady should not called without specifying config to feed instance.',
      };
    }
  };

  /**
   * Implementation of symbol search functionality.
   *
   * @param userInput Text entered by user in the symbol search field
   * @param exchange The requested exchange (chosen by user). Empty value means no filter was specified.
   * @param symbolType string. The requested symbol type: index, stock, forex, etc (chosen by user).
   * Empty value means no filter was specified.
   */
  searchSymbols = async (
    userInput: string,
    exchange: string = '',
    symbolType: DataFeedSymbolType = '',
    onResultReadyCallback: DataFeedSearchResultCallback
  ) => {
    // @ts-ignore
    const data = await GETv1SymbolByUrl(Variation.search.url(userInput, 1));
    const results: SearchResult[] = idx(data, p => p.data.data.items) || [];

    onResultReadyCallback(
      results.map(d => {
        return {
          symbol: d.symbol,
          full_name: d.chName || d.enName,
          description: d.chName || d.enName,
          exchange: d.exchange,
          ticker: d.symbol,
          type: `${d.mtype}-${d.exchange}`,
        };
      })
    );
  };

  /**
   * This is where the TradingView resolve symbol from `prop` or `search input`.
   * Since our datafeed uses different symbology, we have to also take care both
   * our and TradingView's default symbol format
   */
  resolveSymbol = async (
    symbolName: string,
    onSymbolResolvedCallback: DataFeedSymbolResolveCallback,
    onResolveErrorCallback
  ) => {
    log('resolve symbol', symbolName);
    symbolName = normalizeSymbol(symbolName);
    // first we need to check if the symbol has correct parts
    const parts = symbolName.split(':');

    if (!parts || parts.length < 3) {
      onResolveErrorCallback('failed to resolve symbol as it has incorrect format');
      return;
    }

    try {
      const resp = await GETv1ChartHistory({ symbol: symbolName, resolution: 'M', quote: 1 });
      const statusCode = idx(resp, _ => _.data.statusCode);
      const data = idx(resp, _ => _.data.data);

      if (data && data.session && data.quote && statusCode === 200) {
        const lastClose = idx(data, d => d.quote[universalProps.LastClose]);
        const [sessionStart, sessionEnd] = [
          new Date(+data.session[0][0] * 1000),
          new Date(+data.session[data.session.length - 1][1] * 1000),
        ];

        if (lastClose) {
          this.lastPriceMap[symbolName] = lastClose;
        }

        // TradingView consumes timestamp with second-level precision
        this.sessionMap[symbolName] = [~~(+sessionStart / 1000), ~~(+sessionEnd / 1000)];

        const info: DataFeedSymbolInfo = {
          name: symbolName,
          description: data.quote['200009'],
          ticker: '',
          type: 'stock',
          // tslint:disable-next-line
          session: `${sessionStart.getHours()}${sessionStart.getMinutes()}-${sessionEnd.getHours()}${sessionEnd.getMinutes()}`,
          // get first part as the exchange
          exchange: parts[0],
          listed_exchange: parts[0],
          timezone: 'Asia/Taipei',
          minmov: 1,
          minmove2: 0,
          fractional: false,
          pricescale: 10000,
          has_intraday: true,
          has_weekly_and_monthly: true,
          supported_resolutions: ['1', 'D', 'W', 'M'],
          data_status: 'endofday',
          currency_code: '',
        };

        if (this.config.onSymbolChange) {
          this.config.onSymbolChange(info.name);
        }
        onSymbolResolvedCallback(info);
      }
    } catch (err) {
      console.log('resolve failed', symbolName);
      this.config.onFeedError('cannot_resolve');
      onResolveErrorCallback('failed to resolve symbol');
    }
  };

  /**
   * This method is called when charting view requesting chart data.
   * Basically we'll call charting API and convert the response to TradingView compatible format.
   */
  private lastFromTime: number | null = null;
  getBars = async (
    symbolInfo: DataFeedSymbolInfo,
    resolution: DataFeedResolution,
    from: number,
    to: number,
    onHistoryCallback: DataFeedHistoryCallback,
    onErrorCallback
  ) => {
    const now = new Date();
    const dateStr = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
    log('%c [GET BARS] invoked', 'font-weight: bolder');

    if (/[0-9]+[DWM]/.test(resolution)) {
      // @ts-ignore
      resolution = String(resolution).replace(/[0-9]/g, '');
    }

    if (resolution === '1' && Math.max(from, to) < ~~(+new Date(dateStr) / 1000)) {
      log('%c Request bar aborted as it it requested day line history before limited date', 'color: red');
      onHistoryCallback([], { noData: true });
      return;
    }

    try {
      const symbol = symbolInfo.exchange + ':' + symbolInfo.name;
      log(
        '%cRequest bars \n%s\n \n[from]  %s \n[to]    %s \n[total] %d M %d D %d H %d m',
        'color: blue',
        `resolution=${resolution}&from=${from}&to=${to}`,
        new Date(from * 1000),
        new Date(to * 1000),
        (to - from) / (86400 * 30),
        ((to - from) % (86400 * 30)) / 86400,
        ((to - from) % 86400) / 3600,
        ((to - from) % 3600) / 60
      );

      const toDate = new Date(to * 1000);
      const currentDate = new Date();
      const isCurrentMonth =
        toDate.getFullYear() === currentDate.getFullYear() && toDate.getMonth() === currentDate.getMonth();

      // 定義10年的秒數
      const tenYearsInSeconds = 10 * 365 * 24 * 60 * 60;

      // 如果不是當月，則使用上次的 from
      if (!isCurrentMonth) {
        to = this.lastFromTime || to;
      }

      // 檢查時間範圍，並調整為最多10年
      if (to - from > tenYearsInSeconds) {
        from = to - tenYearsInSeconds; // 或者根據需要調整 to
      }
      this.lastFromTime = from;

      // fetch chart data from our feed
      const { bars } = await this.requestBarsAndUpdateTimeCache({
        symbol,
        resolution,
        from,
        to,
      });

      const noData = bars.length < 1 || bars[0].time <= from;

      onHistoryCallback(noData ? [] : bars, { noData });
    } catch (err) {
      this.config.onFeedError('get_bars_failed');
      onErrorCallback(err);
    }
  };

  /**
   * Charting Library calls this function when it wants to receive real-time updates for a symbol.
   * The Library assumes that you will call `onRealtimeCallback` every time you want to update the
   * most recent bar or to add a new one.
   * @remark When you call `onRealtimeCallback` with bar having time equal to the most recent bar's
   * time then the entire last bar is replaced with the bar object you've passed into the call.
   */
  subscribeBars = async (
    symbolInfo: DataFeedSymbolInfo,
    resolution: DataFeedResolution,
    onRealtimeCallback: DataFeedRealtimeCallback,
    subscribeUID
  ) => {
    const key = `${symbolInfo.exchange}:${symbolInfo.name}${resolution}`;
    const symbol = `${symbolInfo.exchange}:${symbolInfo.name}`;

    log('%c [SUBSCRIBE] feed #%o => %o', 'font-weight: bolder', this.fid, key);
    log('cache = %o', this.latestBarMap[key]);

    // since our server does not push data actively to client, here we have poll
    // the data ourself, however, it's not a good idea always request for full-range
    // charting data. Here we will request minimum data from server by set `from` and `to`
    // to an appropriate range.
    this.subscribes[subscribeUID] = setInterval(async () => {
      if (!this.isRealtime) {
        log('abort chart update due to realtime disable');
        return;
      }
      // every time we requested chart data we store the largest timestamp in memory
      // with a hashed key `${symbol}${resolution}` so we know the exact range to request.
      const from = this.latestBarMap[key];
      const to = from + 15;
      const { bars } = await this.requestBarsAndUpdateTimeCache({
        symbol,
        resolution,
        from,
        to,
        latestOnly: true,
      });
      log('feed [%o] subscriptions: %o', this.fid);
      if (debug) {
        console.table(this.subscribes);
      }

      log('%cput %d new bars %o', 'color: green;', bars.length, bars);
      bars.forEach(onRealtimeCallback);
    }, this.config.updateInterval);
  };

  toggleRealtime = val => {
    this.isRealtime = val;
  };

  unsubscribeBars(subscribeUID: string | number) {
    log('%c [UNSUBSCRIBE] feed #%o', 'font-weight: bolder', this.fid);

    if (this.subscribes[subscribeUID]) {
      clearInterval(this.subscribes[subscribeUID]);
      delete this.subscribes[subscribeUID];
    }
  }
}
