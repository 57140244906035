import React from 'react';
import AdProfiles from 'components/DFP/AdProfiles';
import { AdSlot } from 'cnyes-dfp';
import styles from './chat-room-ad-banner.scss';

const canRenderAd = true;

function ChatroomAdBanner() {
  return canRenderAd ? (
    <div className={styles.ad}>
      <div className="anue-dots" />
      <div id="chat-list-ad">
        <AdSlot
          hideOnInitial
          lazyLoading={false}
          profile={AdProfiles.nativeAdStockTalkChatroom}
          key="ad-live-stream-block_1"
        />
      </div>
    </div>
  ) : null;
}

export default React.memo(ChatroomAdBanner);
