import { isTwStockChannel } from 'constant/variation';

const Shared = anue.shared;
const Network = Shared.get('library.net');
const paramly = Shared.get('utils.paramly');
const getty = Shared.get('utils.getty');

export interface APIResult<T> {
  data: T | null;
  error: any;
  next: () => void;
}

interface ParamRequestOptions extends Anue.Network.RequestOptions {
  /**
   * Specify this option value to use cached response for {ttl} ms.
   * The request url and params will be used as the cache key.
   */
  ttl?: number;
  params?: Record<string, string | number>;
  hasNext?(data);
  auth?: boolean;
}

const cache: Record<string, { time: number; data: any }> = {};

export default async function api<T>(options: ParamRequestOptions, selector: string[], callback?: (data: T) => void) {
  const { method, url, params, body, auth } = options;
  const result: APIResult<T> = {
    data: null,
    error: null,
    next: () => {
      if (options.params && typeof options.params.page === 'number') {
        options.params.page++;
        api(options, selector, callback);
      }
    },
  };

  try {
    const urlWithParams = `${url}${params ? paramly(params) : ''}`;
    let resp: any = null;

    // when ttl specified, check if there's a valid cache
    if (options.ttl && cache[urlWithParams]) {
      if (Date.now() - cache[urlWithParams].time < options.ttl) {
        result.data = cache[urlWithParams].data as T;
      } else {
        delete cache[urlWithParams];
      }
    }
    // when not using cache, send actual request via network
    if (!result.data) {
      resp = await Network.getDriver().send<T>({
        method: method || 'GET',
        auth,
        headers: {
          'X-CNYES-APP': 'stock',
          'X-System-Kind': isTwStockChannel ? 'STOCK' : 'GLOBAL_STOCK',
        },
        url: urlWithParams,
        body,
      });

      // apply selector if there's one
      if (selector) {
        result.data = getty(resp, selector) as T;
      } else {
        result.data = resp as T;
      }

      // cache success response if options.ttl is specified
      if (options.ttl && result.data) {
        cache[urlWithParams] = {
          data: result.data,
          time: Date.now(),
        };
      }
    }

    // use response as the error detail if response if empty
    if (!result.data) {
      result.error = resp;
    } else if (!result.error && callback) {
      callback(result.data);
    }
  } catch (error) {
    result.error = error;
  }
  return result;
}
