import * as React from 'react';
import cx from 'classnames';
import Variation from 'constant/variation';
import Chatroom from 'containers/Chatroom/Chatroom';
import useDevice from 'hooks/device';
import useUI, { dispatch } from 'hooks/ui';
// import EsunStockOrderDashboardContainer from 'containers/EsunStockOrderDashboard/EsunStockOrderDashboard';
// import SinoTradeOrderDashboardContainer from 'containers/SinoTradeOrderDashboard/SinoTradeOrderDashboard';
import styles from './styles.scss';
import gaDataset from 'fe-common-library/dest/utils/gaDataset';
import { SEND_PROJ_TARGET } from 'utils/analytics';

interface AsideRightItems {
  title: string;
  content: (props: any) => React.ReactComponentElement<any>;
}

interface AsideProps {
  pathname: string;
  layout: 'mobile' | 'desktop';
  tabIndex: number;
}

interface DirectLinkTab {
  title: string;
  url: string;
}

// TODO:[QA-2586]股市talk下單連結錯誤,因為之前業務邏輯拔除造成找不到元件渲染錯誤,改成下單後直接倒轉連結-永豐金下單連結邏輯
const ASIDE_RIGHT_TABS: AsideRightItems[] = [
  { title: '聊天室', content: () => <Chatroom /> },
  // {
  //   title: '玉山證券下單',
  //   content: props => <EsunStockOrderDashboardContainer {...props} />,
  // },
  // {
  //   title: '永豐金證券下單',
  //   content: props => <SinoTradeOrderDashboardContainer {...props} />,
  // },
];

const DIRECT_LINK_TABS: DirectLinkTab[] = [
  // {
  //   title: '永豐金證券下單',
  //   url: 'http://campaign.cnyes.com/topics/sinopacsecttwtw/',
  // },
];

function Aside({ layout, tabIndex, pathname }: AsideProps) {
  function renderTabsTitle() {
    return ASIDE_RIGHT_TABS.map(({ title }, i) => (
      <div
        key={`aside-right-tabs-title-${title}`}
        className={cx([styles.tab, { [styles.active]: tabIndex === i }])}
        onClick={() => dispatch({ type: 'aside', payload: { tabIndex: i } })}
        data-anue-ga-event={`${Variation.stockChannelName},聊天室 click,${title},proj`}
      >
        {title}
      </div>
    ));
  }
  function renderDirectLinkTabsTitle() {
    return DIRECT_LINK_TABS.map(({ title, url }) => (
      <div key={title} className={cx([styles.tab])}>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          {...gaDataset({
            dataPrefix: SEND_PROJ_TARGET,
            eventName: 'Click_交易',
            section: '聊天室',
            clickItem: title,
          })}
        >
          {title}
        </a>
      </div>
    ));
  }

  const tabContent = ASIDE_RIGHT_TABS[tabIndex].content({ pathname });

  return (
    <>
      {layout === 'desktop' && Variation.firebaseChannel === 'TWStock' && (
        <div className={styles.tabs}>
          {renderTabsTitle()}
          {renderDirectLinkTabsTitle()}
        </div>
      )}
      <div className={styles['tab-content']}>{tabContent}</div>
    </>
  );
}

function AsideContainer({ pathname }: { pathname: string }) {
  const [device] = useDevice();
  const [ui] = useUI();

  return <Aside layout={device.layout} tabIndex={ui.aside.tabIndex} pathname={pathname} />;
}

export default React.memo(
  (props: { pathname: string }) => <AsideContainer pathname={props.pathname} />,
  (prev, next) => prev.pathname === next.pathname
);
