const Unit = ['', 'K', 'M'];

/**
 * Get correspond format string by given `symbol` and `volume`.
 * @see https://cnyesrd.atlassian.net/wiki/spaces/PS/pages/641695749
 */
export default function getVolume(symbol: string, volume: string) {
  const parts = `${symbol}`.toUpperCase().split(':');
  const market = parts[0];
  const symbolType = parts[2];
  let amount = +volume;

  if (isNaN(amount) || !market || !symbolType || market === 'GI') {
    return '';
  }

  amount = +amount.toFixed(1);

  // For index, the volume number will not computed by backend
  if (symbolType.toUpperCase() === 'INDEX') {
    return `${(amount / 100000000).toLocaleString('en-US')} 億`;
  }

  let unit = '';

  if ((market === 'USS' || market === 'HKS') && amount !== 0) {
    const log = Math.floor(Math.log10(amount) / 3);
    unit = Unit[log] || '';
    amount = +(amount / Math.pow(10, log * 3)).toFixed(1);
  }

  const val = amount.toLocaleString('en-US');

  // For other symbols, the volume is computed by backend, simply append
  // appropriate unit to it.
  switch (market) {
    case 'TWF':
      return `${val}口`;
    case 'TWG':
      return `${val}股`;
    case 'CNS':
      return `${val}手`;
    case 'USS':
    case 'HKS':
      return `${val}${unit}股`;
    case 'TWS':
      return `${val}張`;
  }
  return '';
}
