import { useEffect, useState } from 'react';

interface DeviceInfo {
  isLegacy: boolean;
  isIE: boolean;
  isFireFox: boolean;
  isEdge: boolean;
  isSafari: boolean;
  layout: 'desktop' | 'mobile';
  height: number;
  width: number;
}

const ua = navigator.userAgent;

const state: DeviceInfo = {
  isLegacy: typeof Promise === 'undefined',
  isIE: /MSIE /.test(ua) || /Trident.*rv/.test(ua),
  isEdge: /Edge\//.test(ua),
  isSafari: typeof safari !== 'undefined',
  isFireFox: /firefox/i.test(ua),
  layout: window.innerWidth > 1024 ? 'desktop' : 'mobile',
  height: window.innerHeight,
  width: window.innerWidth,
};

export function getDeviceInfo() {
  return state;
}

export default function useDevice() {
  const [info, setInfo] = useState<DeviceInfo>(state);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setInfo({
        ...info,
        height: window.innerHeight,
        width: window.innerWidth,
      });
    });
  }, []);

  return [info];
}
