import Variation from 'constant/variation';

const channel = Variation.firebaseChannel === 'TWStock'
  ? 'stocktalk'
  : 'globalstock';

const AdProfiles = {
  bottomLeftNewsListA: {
    path: '/1018855/cnyes_stocktalk_news_native',
    size: ['fluid'],
    className: 'ad-block-news-item',
  },
  nativeAdStockTalkChatroom: {
    path: `/1018855/cnyes_${channel}_chatroom_native`,
    size: ['fluid', [250, 100]],
    className: 'ad-block-chatroom',
    hideOnInitial: true,
  },
  adSetBesideSearchBarA: {
    path: '/1018855/cnyes_stocktalk_145*35_A',
    size: [145, 35],
    className: 'ad-block-search-bar',
  },
  adSetBesideSearchBarB: {
    path: '/1018855/cnyes_stocktalk_145*35_B',
    size: [145, 35],
    className: 'ad-block-search-bar',
  },
  liveStreamBlockVideoAd: {
    path: '/1018855/cnyes_stocktalk_video',
    size: [1, 1],
    className: 'ad-live-steam-block',
    targetKey: 'wally',
  },
  financialVideoAd: {
    path: '/1018855/cnyes_stocktalk_video_native',
    size: ['fluid'],
    className: 'ad-block-news-item',
    targetKey: 'wally',
  },
  idleBanner: {
    path: `/1018855/cnyes_${channel}_970*90`,
    size: [970, 90],
    className: 'ad-block-bottom-banner',
  },
  // slug should be 'realtime' | 'news' | 'video'
  globalNews: slug => ({
    path: `/1018855/cnyes_globalstock_${slug}_native`,
    size: ['fluid'],
    className: 'ad-block-news-item',
  }),
};

export default AdProfiles;
