/**
 * Software scroll hooks is a workaround for browsers which not supporting
 * `flex-direction: column-reverse` scrolling (i.e FireFox, IE).
 * This hook simply attach a `wheel` event listener and change `translateY`
 * of the list element to an appropriate value correspond to the `deltaY`
 * on event firing.
 */
import { useRef, useEffect } from 'react';

type useSoftScroll = () => [(el: HTMLUListElement | null) => void, (n: number) => void, () => number];

interface ScrollAgent {
  recalculate: () => void;
  scrollTo: (n: number) => void;
  getScrollPosition: () => number;
}

// const debug = document.createElement('div');
// debug.setAttribute(
//   'style',
//   'position: fixed; right: 4px; top: 4px; bakground-color:white;'
// );
// document.body.appendChild(debug);

function createSoftwareScrollAgent(container: HTMLUListElement) {
  const parentElement = container.parentElement;
  const tracker = document.createElement('div');

  // debounce flag
  let tick = false;
  let position = 0;
  let range = 0;
  let outerHeight = 0;
  let innerHeight = 0;
  let trackerHeight = 0;
  let prevY = -1;

  function handleSoftwareScroll(e: { deltaY: number }) {
    if (!tick) {
      const nextPosition = position - Math.sign(e.deltaY) * Math.max(Math.abs(e.deltaY), 30);
      requestAnimationFrame(function() {
        scrollTo(nextPosition);
        tick = false;
      });
    }
    tick = true;
  }

  /**
   * Scroll event triggered when user dragging scroll tracker
   */
  function handleTrackerScroll(e) {
    if (container && tracker) {
      const movementY = e.screenY - prevY;
      const offset = (movementY / outerHeight) * innerHeight;

      prevY = e.screenY;
      handleSoftwareScroll({ deltaY: offset });
    }
  }

  function scrollTo(n: number) {
    if (container && n > -3) {
      position = Math.min(n, range + 3);
      container.style.transform = `translate3D(0,${position}px,0)`;
      // debug.innerText = `p:${position} oh:${outerHeight} ih: ${innerHeight} r:${range}`;
      setTrackerPosition();
    }
  }

  function recalculate() {
    // recalculate inner scroll range when software scroll is enabled
    if (parentElement && tracker && container) {
      outerHeight = parentElement.clientHeight;
      innerHeight = container.clientHeight;

      range = innerHeight;
      trackerHeight = (outerHeight / innerHeight) * outerHeight;
      tracker.style.height = `${trackerHeight}px`;
      setTrackerPosition();
    } else {
      console.error('soft scrolling target show have a parent element.');
    }
  }

  function handleMouseDown(e) {
    prevY = e.screenY;
    // prevent selection happen when user dragging the tracker
    document.onselectstart = function() {
      return false;
    };
    window.addEventListener('mousemove', handleTrackerScroll);
  }

  function handleMouseUp() {
    document.onselectstart = function() {
      return true;
    };
    window.removeEventListener('mousemove', handleTrackerScroll);
  }

  /**
   * Adjust tracker's height correspond to current scroll height
   */
  function setTrackerPosition() {
    if (tracker && container) {
      const nextTrackerPosition = (position / range) * outerHeight;

      if (nextTrackerPosition > outerHeight - trackerHeight) {
        tracker.style.bottom = `${Math.min(nextTrackerPosition, outerHeight - trackerHeight - 10)}px`;
      } else {
        tracker.style.bottom = `${Math.max(nextTrackerPosition, 0)}px`;
      }
    }
  }

  if (parentElement) {
    tracker.setAttribute(
      'style',
      'position: absolute; bottom: 0px; right: 0; width: 8px; height: 0; z-index: 2; background-color: #ccc; border-radius: 4px'
    );

    tracker.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    parentElement.appendChild(tracker);
    container.addEventListener(
      'wheel',
      // @ts-ignore
      handleSoftwareScroll
    );
  }

  function getScrollPosition() {
    return position;
  }

  return {
    recalculate,
    getScrollPosition,
    scrollTo,
  };
}

const softScroll: useSoftScroll = function() {
  const scrollAgent = useRef<ScrollAgent | null>(null);

  useEffect(() => {
    if (scrollAgent.current) {
      scrollAgent.current.recalculate();
    }
  });

  function attach(el: HTMLUListElement | null) {
    if (el) {
      scrollAgent.current = createSoftwareScrollAgent(el);
    }
  }

  function scrollTo(n) {
    if (scrollAgent.current) {
      scrollAgent.current.scrollTo(n);
    }
  }

  function getPosition() {
    if (scrollAgent.current) {
      return scrollAgent.current.getScrollPosition();
    }
    return 0;
  }

  return [attach, scrollTo, getPosition];
};

export default softScroll;
