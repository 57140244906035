import React from 'react';
import cx from 'classnames';
import { dispatch } from 'hooks/ui';
import Variation from 'constant/variation';
import { sendEventLog, SEND_PROJ_TARGET } from '../../utils/analytics';
import styles from './styles.scss';
import sendDataLayer from 'fe-common-library/dest/utils/sendDataLayer';

interface EsunOpenButtonProps {
  content?: string;
  isLarge?: boolean;
  cb: () => void;
  userId: string;
  trackingClickFrom: string;
  name: string;
  symbol: string;
}

export default function EsunOpenButton(props: EsunOpenButtonProps) {
  const handleClickMakingOrderBtn = () => {
    const gaAction = props.trackingClickFrom ? `click - ${props.trackingClickFrom}` : 'click';
    const gaLabel = props.userId ? ` user id - ${props.userId}` : '未登入的使用者';

    // TODO:[QA-2586]股市talk下單連結錯誤：
    // dispatch({
    //   type: 'aside',
    //   payload: {
    //     tabIndex: 1,
    //   },
    // });

    dispatch({
      type: 'esun',
      payload: {
        tab: 'navigate',
      },
    });
    // props.switchAsideRightTab(1);
    // props.postMsgEsunNavigateToTradeTab(true);
    // 如果是預設 callback name 就不執行 cb。
    if (props.cb.name !== '_defaultCbFunction') {
      props.cb();
    }

    sendEventLog('玉山下單', gaAction, gaLabel);
    // new project ga event
    sendEventLog(
      Variation.stockChannelName,
      props.trackingClickFrom === '聊天室' ? '聊天室 click' : '行情 click',
      '下單',
      {},
      SEND_PROJ_TARGET
    );
    sendDataLayer({
      dataPrefix: SEND_PROJ_TARGET,
      eventName: 'Click_交易',
      section: props.trackingClickFrom,
      clickItem: `下單_${props.symbol}_${props.name}`,
    });

    if (!props.symbol) {
      return;
    }

    // [QA-2586]生成永豐金合作案的下單盒連結
    const [market, code] = String(props.symbol).split(':');
    const linkType = ['TWS', 'TWG'].includes(market) ? 'S' : 'H';
    window.open(
      `https://www.sinotrade.com.tw/newweb/OrderGO/?strProd=0108&strWeb=0007&utm_source=cnyes&utm_medium=button_0719&utm_campaign=NW_TSP_02&platform=cnyes&type=${linkType}&stockid=${code}`,
      '_blank'
    );
  };
  const btnStyle = [styles['esun-trade-btn']];

  if (props.isLarge) {
    btnStyle.push(styles['is-large']);
  }

  return (
    <button className={cx(btnStyle)} onClick={handleClickMakingOrderBtn}>
      {props.content}
    </button>
  );
}

EsunOpenButton.defaultProps = {
  trackingClickFrom: undefined,
  userId: undefined,
  content: '下單',
  isLarge: false,
  cb: function _defaultCbFunction() {
    return undefined;
  },
  symbol: '',
};
