import * as React from 'react';
import styles from './search-result-item.scss';

interface SearchResultItemProps {
  data: SearchResult;
  selected?: boolean;
  onMouseEnter?: () => void;
  onClick?: () => void;
}

const validateFieldName = data => data.chName || data.enName;

function SearchResultItem({ data, selected, onMouseEnter, onClick }: SearchResultItemProps) {
  return (
    <li
      className={styles.container}
      key={data.chName}
      onClick={onClick}
      data-selected={selected}
      onMouseEnter={onMouseEnter}
    >
      <span
        className={styles.code}
        dangerouslySetInnerHTML={{
          __html: data.code,
        }}
      />
      <span
        className={styles.abbr}
        dangerouslySetInnerHTML={{
          __html: validateFieldName(data),
        }}
      />
      {data.mtype && data.exchange && <span className={styles['stock-type']}>{`${data.mtype}-${data.exchange}`}</span>}
    </li>
  );
}

export default React.memo(
  (props: SearchResultItemProps) => <SearchResultItem {...props} />,
  (prev, next) => prev.data.code === next.data.code && prev.selected === next.selected
);
