/* global ga */
/* global _paq */
/* global Piwik */
/* eslint no-param-reassign: 0 */
import analyticsConfig from '../constant/analyticsConfig';
import loadScript, { appendScript } from './loadScript';

const isServer = typeof window === 'undefined';

// t0: old default GA
// cross: old global GA
export const SEND_PROJ_TARGET = ['proj', 'talk']; // ONLY include GA those need to send specific events.
const ALL_GA_TARGETS = ['proj', 'talk', 'global']; // ONLY include project GA, global GA and others. t0 and cross would be deprecated.

const DEFAULT_SEND_EVENT_TARGETS = ['t0'];
const DEFAULT_PAGE_VIEW_TARGETS = ['t0', 'cross', 'proj', 'global'];

function updatePiwikConfig() {
  if (typeof Piwik !== 'undefined' && Piwik.getAsyncTracker) {
    const piwik = Piwik.getAsyncTracker();

    piwik.setCustomUrl(window.location.href);
    piwik.setDocumentTitle(document.title);
  }
}

function send(sendObject, targets = DEFAULT_SEND_EVENT_TARGETS) {
  if (isServer) {
    return;
  }

  targets.forEach(item => {
    // console.log(item);
    ga(`${item}.send`, sendObject);
  });
}

export function setUserId(uid) {
  // https://cnyesrd.atlassian.net/browse/GS-6763
  // only set to global ga id
  if (isServer) {
    return;
  }

  // set user id to cross, proj and global ga
  ga('cross.set', 'userId', uid);
  ALL_GA_TARGETS.forEach(gaTarget => {
    ga(`${gaTarget}.set`, 'userId', uid);
  });
}

export function setPage(path) {
  if (isServer) {
    return;
  }
  const _loc = window.location;
  const locString = `${_loc.protocol}//${location.host}${path}${_loc.search}${_loc.hash}`;
  const sendObject = {
    page: path,
    location: locString,
  };

  ga('set', sendObject);
  ga('cross.set', sendObject);
  ALL_GA_TARGETS.forEach(gaTarget => {
    ga(`${gaTarget}.set`, sendObject);
  });
}

export function initialGTM() {
  if (isServer) {
    return;
  }
  const { gtmId, gtmContainerId, gtmContainerIdForProj, gtmContainerIdForGlobal } = analyticsConfig;
  const GTMHeadScriptSetup = gtmId => `
  <!-- Google Tag Manager -->
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(

  {'gtm.start': new Date().getTime(),event:'gtm.js'}
  );var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}');
  <!-- End Google Tag Manager -->
  `;
  const GTMBodyScriptSetup = gtmId => `
    <!-- Google Tag Manager (noscript) -->
    <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    <!-- End Google Tag Manager (noscript) -->
  `;

  appendScript(GTMHeadScriptSetup(gtmId), 'head');
  appendScript(GTMBodyScriptSetup(gtmId), 'body', 'noscript');
  appendScript(GTMHeadScriptSetup(gtmContainerId), 'head');
  appendScript(GTMBodyScriptSetup(gtmContainerId), 'body', 'noscript');
  appendScript(GTMHeadScriptSetup(gtmContainerIdForProj), 'head');
  appendScript(GTMBodyScriptSetup(gtmContainerIdForProj), 'body', 'noscript');
  appendScript(GTMHeadScriptSetup(gtmContainerIdForGlobal), 'head');
  appendScript(GTMBodyScriptSetup(gtmContainerIdForGlobal), 'body', 'noscript');
}

export async function initialAnalytics() {
  if (isServer) {
    return;
  }
  const { gaId, gaIdForCross, gaIdForProj, gaIdForGlobal } = analyticsConfig;

  await loadScript('//www.google-analytics.com/analytics.js');

  ga('create', gaId, 'auto');
  ga('create', gaIdForCross, 'auto', 'cross');
  ga('create', gaIdForProj, 'auto', 'proj');
  ga('create', gaIdForGlobal, 'auto', 'global');
}

export function pageview(path, targets = DEFAULT_PAGE_VIEW_TARGETS) {
  if (isServer) {
    return;
  }

  const sendObject = {
    hitType: 'pageview',
    page: path,
    location: window.location.href,
  };

  send(sendObject, targets);

  // PIWIK tracking
  updatePiwikConfig();
  _paq.push(['trackPageView']);
}

export function sendEventLog(
  eventCategory = '',
  eventAction = '',
  eventLabel = '',
  extraConfigs = {},
  targets = DEFAULT_SEND_EVENT_TARGETS
) {
  if (isServer) {
    return;
  }
  eventCategory += '';
  eventAction += '';
  eventLabel += '';
  const sendObject = Object.assign(
    {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel,
    },
    extraConfigs
  );

  send(sendObject, targets);

  // PIWIK tracking
  // _paq.push(['trackEvent', eventCategory, eventAction, eventLabel]);
}

export function sendPiwikCustomVariable(memberId) {
  if (isServer) {
    return;
  }
  _paq.push(['setCustomVariable', 2, 'memberId', memberId, 'visit']);
}

/**
 * @param {Array} gaName      (required) ga name
 * @param {String} category   ga event category
 * @param {String} action     ga event action
 * @param {String} label      ga event label
 * @returns {Object}          An object: { data-{gaName}-ga-category,... }
 */
export function gaDataset(gaName, category = null, action = null, label = null) {
  if (!category && !action && !label && !gaName) {
    return {};
  }

  return gaName.reduce((pre, cur) => {
    return {
      ...pre,
      [`data-${cur}-ga-category`]: category,
      [`data-${cur}-ga-action`]: action,
      [`data-${cur}-ga-label`]: label,
    };
  }, {});
}
