import * as React from 'react';
import idx from 'idx';
import { useRef, useEffect, useState, useMemo } from 'react';
import {
  widget,
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
} from './vendor/charting_library/charting_library.min';
import classnames from 'classnames';
import WidgetConfigBuilder from './WidgetConfigBuilder';
import WidgetButtonBuilder, { TradingViewButton } from './WidgetButtonBuilder';
import DataFeedImpl from './DataFeedImpl';
import MarketStatus from 'components/MarketStatus/MarketStatus';
import Variation from 'constant/variation';
// styles
import styles from './trading-view.scss';

/**
 * TradingView 注意事項
 * 升級新版後會有異常的 loading screen 鎖住畫面的狀態。
 * 發生的情境目前觀察到有，1. 當線圖畫完閒置幾秒後, 2. 當 chart 的大小被改變的時候（例如像點擊左邊縮合toolbar功能）
 * 目前解決辦法在build出來的 /build/dist/static/tv_static/static/bundles/libray.XXXXXXX.min.js 中直接將loading遮罩 display: none;
 * 需要處理的有兩個地方
 * 1. `<div class="chart-loading-screen">` => `<div class="chart-loading-screen" style="display: none;">`
 * 2. `<div class="chart-loading-screen-shield">` => `<div class="chart-loading-screen-shield" style="display: none;">`
 */
export interface TradingViewProps {
  symbol: string;
  interval?: number;
  debug?: boolean;
  customClassName?: string;
}

type SupportedResolution = '1' | 'D' | 'W' | 'M';

interface MaIndicator {
  number: number;
  color: string;
}

interface StudyType {
  id: string;
  name: string;
}

interface ChartType {
  line: 2;
  KBar: 1;
}

const DEFAULT_RESOLUTION: SupportedResolution = 'D';
const MA_NAME = 'Moving Average';
let tvId = 1;

export default React.memo(
  function TradingView(props: TradingViewProps) {
    const id = useRef(tvId++);
    const feed = useRef<DataFeedImpl | null>(null);
    const tv = useRef<IChartingLibraryWidget | null>(null);
    const entities = useRef<Record<string, string | null>>({});
    const [chartReady, setChartReady] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [isMarketLoaded, setIsMarketLoaded] = useState<boolean>(false);
    const [hideToolbar, setHideToolbar] = useState(false);
    const [countPanes, setCountPanes] = useState<number>(1);
    const selectedSymbol = useRef<string>('');
    const canSaveStudies = useRef<boolean>(false);
    const buttons = useRef<Record<string, TradingViewButton>>({});
    const CHART_TYPE: ChartType = {
      line: 2,
      KBar: 1,
    };
    const marketValue = props.symbol.match(/^(\w+):/);
    const delaySetMarkerStatusIsLoaded = (isLoaded: boolean, delay?: number) => {
      const delaySwitchStatusTime = delay || 500;

      setTimeout(() => {
        setIsMarketLoaded(isLoaded);
      }, delaySwitchStatusTime);
    };

    selectedSymbol.current = props.symbol;

    function updateMainPaneMargin() {
      const tvw = tv.current;

      if (tvw) {
        const marginMainChart = tvw.chart().getPanes().length * 10;

        tvw.applyOverrides({
          'paneProperties.topMargin': marginMainChart,
          'paneProperties.bottomMargin': marginMainChart,
        });
      }
    }

    function setVisibleMarginForRealTimeChart() {
      const widgetInstance = tv.current;

      if (widgetInstance) {
        const chart = widgetInstance.chart();
        const datafeed = feed.current;

        if (chart.resolution() === '1' && datafeed) {
          const price = datafeed.getLastCloseBySymbol(selectedSymbol.current);

          if (price) {
            const [min, max] = datafeed.marginMap[selectedSymbol.current];
            const h = max - min;
            let topMargin = 10;
            let bottomMargin = 10;
            let marginHeight = 0;

            if (price > max) {
              marginHeight = price - max;
              topMargin = 30 + (marginHeight / (h + marginHeight)) * 40;
              bottomMargin = 30;
            } else if (price < min) {
              marginHeight = min - price;
              topMargin = 30;
              bottomMargin = 30 + (marginHeight / (h + marginHeight)) * 40;
            }
            widgetInstance.applyOverrides({
              'paneProperties.topMargin': topMargin,
              'paneProperties.bottomMargin': bottomMargin,
            });
          }
        }
      }
    }

    function loadSavedTemplates(interval: string) {
      const tvw = tv.current;

      if (tvw) {
        canSaveStudies.current = true;
        const key = interval === '1' ? `${Variation.storage}_1` : Variation.storage;
        const cache = localStorage[key];
        const savedTemplate = cache ? JSON.parse(cache) : null;
        console.log('load templates', key, savedTemplate);
        if (savedTemplate) {
          tvw.chart().applyStudyTemplate(savedTemplate);
          return true;
        }
      }

      return false;
    }

    function changeResolution(resolution, chartType) {
      const tvw = tv.current;
      const btns = buttons.current;
      const datafeed = feed.current;

      canSaveStudies.current = false;
      if (tvw && btns) {
        for (const i in btns) {
          if (i === resolution) {
            btns[i].style({ color: '#fff' }).outerBackground('#e03f19');
          } else {
            btns[i].style({ color: '#383838' }).outerBackground('#fff');
          }
        }

        delaySetMarkerStatusIsLoaded(false, 0);

        tvw.chart().setResolution(resolution, () => {
          delaySetMarkerStatusIsLoaded(true);
          loadSavedTemplates(resolution);
          // Hide MA indicators if the resolution is 1
          const isMAVisible = resolution !== '1';
          const enableMAs = tvw
            .chart()
            .getAllStudies()
            .filter(s => s.name.indexOf(MA_NAME) > -1);

          enableMAs.forEach((m: StudyType) => {
            const entityId = idx(m, _ => _.id);
            console.log('set visible of %o to %o', m.name, isMAVisible);
            tvw.chart().setEntityVisibility(entityId, isMAVisible);
          });

          // Increment the margin of the major pane based on the amount of the panes
          if (resolution !== '1') {
            // Update the margin if the resolution is changed.
            updateMainPaneMargin();
          }
        });
        tvw.chart().setChartType(chartType);

        if (entities.current.lastCloseLine) {
          // @ts-ignore
          tvw.chart().removeEntity(entities.current.lastCloseLine);
        }

        if (datafeed && resolution === '1') {
          setTimeout(() => {
            // re-draw last close line on switching to real-time mode.
            const price = datafeed.getLastCloseBySymbol(selectedSymbol.current);
            console.log('symbol [%o] last price = %o', selectedSymbol.current, price);

            if (price) {
              entities.current.lastCloseLine = tvw.chart().createShape(
                {
                  time: Date.now(),
                  price,
                },
                {
                  shape: 'horizontal_line',
                  text: '昨收線',
                  lock: true,
                  overrides: { linewidth: 1, linecolor: '#333', linestyle: 2 },
                }
              );
            }

            // set X-axis to align trading session's begin and end
            const tradingSession = datafeed.sessionMap[selectedSymbol.current];

            if (tradingSession) {
              tvw.chart().setVisibleRange(
                {
                  from: tradingSession[0],
                  to: tradingSession[1],
                },
                () => {}
              );
            }

            // adjust Y-axis so user can see the min, max, and last price
            setVisibleMarginForRealTimeChart();
          }, 500);
        }
      }
    }

    // Add MA20 & MA60 indicator to the chart.
    function setMALines(tvw: IChartingLibraryWidget, maType?: MaIndicator) {
      const renderMa = maType ? [maType] : [{ number: 20, color: '#6BCAB1' }, { number: 60, color: '#F3B459' }];

      function createStudyPromise(m) {
        return new Promise(resolve => {
          tvw.chart().createStudy(MA_NAME, false, false, [m.number], resolve, {
            'plot.color': m.color || '#6BCAB1',
            'plot.transparency': 0,
            precision: 2,
          });
        });
      }

      return Promise.all(renderMa.map(createStudyPromise));
    }
    async function init() {
      const datafeed = new DataFeedImpl({
        exchanges: [
          {
            name: 'stock',
            value: marketValue ? marketValue[1] : 'TWS',
            desc: marketValue ? marketValue[1] : 'TWS',
          },
        ],
        // not really sure what these props are for
        symbols_types: '',
        supported_resolutions: ['1', 'D', 'W', 'M'],
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: false,
        onFeedError: setError,
        updateInterval: Number(props.interval) || 60000,
        onDataRangeChange: setVisibleMarginForRealTimeChart,
      });

      const options: ChartingLibraryWidgetOptions = WidgetConfigBuilder({
        containerId: id.current,
        symbol: props.symbol,
        datafeed,
        debug: false,
      });

      const tvw = new widget({
        ...options,
        // @ts-ignore
        datafeed,
      });
      feed.current = datafeed;
      delaySetMarkerStatusIsLoaded(false, 0);

      // access chart instance after its ready, otherwise you get an error
      tvw.onChartReady(async () => {
        tv.current = tvw;
        tvw.subscribe('toggle_sidebar', (val: boolean) => {
          setHideToolbar(val);
        });

        const chart = tvw.chart();

        delaySetMarkerStatusIsLoaded(true);

        chart.executeActionById('hideAllMarks');

        // create buttons on header
        const btns: Record<string, TradingViewButton> = [
          {
            resolution: '1',
            title: '即時',
            chartType: CHART_TYPE.line,
          },
          {
            resolution: 'D',
            title: '日線',
            chartType: CHART_TYPE.KBar,
          },
          {
            resolution: 'W',
            title: '週線',
            chartType: CHART_TYPE.KBar,
          },
          {
            resolution: 'M',
            title: '月線',
            chartType: CHART_TYPE.KBar,
          },
        ].reduce((p, c) => {
          const isFirst = c.resolution === DEFAULT_RESOLUTION;

          p[c.resolution] = WidgetButtonBuilder(tvw)
            .text(c.title)
            .style({
              cursor: 'pointer',
              color: isFirst ? '#fff' : '#383838',
            })
            .outerBackground(isFirst ? '#e03f19' : '#fff')
            .click(function() {
              changeResolution(c.resolution, c.chartType);
            });

          return p;
        }, {});

        setChartReady(true);

        const isSavedSettingsEmpty = !localStorage[Variation.storage] && !localStorage[`${Variation.storage}_1`];

        if (isSavedSettingsEmpty) {
          await setMALines(tvw);
        }

        if (isSavedSettingsEmpty) {
          const defaultSetting = JSON.stringify(chart.createStudyTemplate(false));
          localStorage[Variation.storage] = defaultSetting;
          localStorage[`${Variation.storage}_1`] = defaultSetting;
        }

        loadSavedTemplates('');

        tvw.subscribe('onAutoSaveNeeded', () => {
          if (canSaveStudies.current) {
            const templates = tvw.chart().createStudyTemplate(false);
            const key = tvw.chart().resolution() === '1' ? `${Variation.storage}_1` : Variation.storage;
            console.log('onAutoSaveNeeded: ', key, templates);
            const data = (localStorage[key] = JSON.stringify(templates));
            // for the first open, initialize cache with default value
            if (data) {
              localStorage[key] = data;
            }
            // Update the margin if the chart or the indicators is updated.
            updateMainPaneMargin();
          }
        });

        setError(null);
        buttons.current = btns;

        // Update the margin if the chart is ready.
        updateMainPaneMargin();
      });
    }

    useEffect(() => {
      const view = tv.current;
      setError(null);
      // whenever symbol changed, reset to day line panel
      changeResolution('D', CHART_TYPE.KBar);
      // render chart for new symbol, resolution and time interval when props.symbol changes
      if (view && view.chart().symbol() !== props.symbol) {
        delaySetMarkerStatusIsLoaded(false);
        view.setSymbol(props.symbol, '1D', () => {});
        view.chart().setResolution(DEFAULT_RESOLUTION, () => {});
        view.onChartReady(() => {
          delaySetMarkerStatusIsLoaded(true, 0);
        });
      }
    }, [props.symbol]);

    useEffect(function initializeTradingView() {
      if (typeof window === 'undefined') {
        return;
      }
      // which means TradingView instance is not initialized yet
      init();
    }, []);

    useEffect(() => {
      return () => {
        if (feed.current) {
          feed.current.cleanup();
        }
        if (tv.current) {
          tv.current.remove();
        }
        console.log('TradingView instance cleanup ..');
      };
    }, []);

    const logoClassName = hideToolbar ? '-shift' : '';
    const containerClass = classnames(styles['trading-view-container'], {
      [`${props.customClassName}`]: !!props.customClassName,
    });

    return (
      <div className={styles['trading-view']}>
        {useMemo(
          () => (
            <div className={styles['trading-view']}>
              <div className={containerClass} id={`${id.current}`} />
              <div
                className={
                  chartReady
                    ? classnames(
                        styles.col,
                        styles['trading-view-logo'],
                        styles[`trading-view-logo-mini${logoClassName}`]
                      )
                    : classnames(styles.col, styles['trading-view-logo'], styles['trading-view-loading'])
                }
                // @TODO remove style
                // style={{ display: 'none' }}
              >
                {!chartReady && (
                  <div className={styles.spinner}>
                    <div className="anue-dots" />
                  </div>
                )}
                {(error || !chartReady) && (
                  <div className={styles['trading-view-msg']}>
                    {error && '無法載入圖表，請刷新頁面或選擇其他商品'}
                    {!error && !chartReady && '圖表載入中請稍候'}
                  </div>
                )}
              </div>
            </div>
          ),
          [chartReady, error, logoClassName]
        )}
        <MarketStatus symbol={selectedSymbol.current} isLoaded={isMarketLoaded} />
      </div>
    );
  },
  (prev, next) => prev.symbol === next.symbol
);
