import Eventy from 'anue-fe-sdk/eventy';

export default <T>(initialState: any = {}) => {
  let store: T = initialState;
  const event = new Eventy();

  return {
    /**
     * subscribe to the change in store, the returned value is a function which cancels the subscribe
     */
    subscribe: (fn: (data: T) => void): (() => void) => event.on(1, fn),
    /**
     * dispatch changes will also trigger all subscriber hooks on this store
     */
    dispatch: nextState => {
      store = Object.assign({}, store, nextState);
      event.emit(1, store);
    },
    getState: () => store,
  };
};
