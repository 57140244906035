import globalConfig from 'constant/globalConfig';
const essAPI = globalConfig.essAPI;
const defaultAPI = globalConfig.defaultAPI;
const memberAlwaysRightAPI = globalConfig.memberAlwaysRightAPI;
export const isTwStockChannel = /^stock/i.test(location.hostname);

const portfolio = {
  apiVersion: isTwStockChannel ? 'v3' : 'v4',
  getPortfolio: isTwStockChannel
    ? `${memberAlwaysRightAPI}/member-always-right/api/v1/portfolios?product_type=STOCK`
    : `${memberAlwaysRightAPI}/member-always-right/api/v1/portfolios?product_type=GLOBAL_STOCK`,
  putPortfolio: isTwStockChannel
    ? `${memberAlwaysRightAPI}/member-always-right/api/v1/portfolios/STOCK`
    : `${memberAlwaysRightAPI}/member-always-right/api/v1/portfolios/GLOBAL_STOCK`,
  defaultGroupsApi: isTwStockChannel
    ? `${memberAlwaysRightAPI}/member-always-right/api/v1/portfolios?product_type=STOCK`
    : `${memberAlwaysRightAPI}/member-always-right/api/v1/portfolios?product_type=GLOBAL_STOCK`,
};

const quote = isTwStockChannel
  ? {
      defaultSymbol: 'TWS:TSE01:INDEX',
    }
  : {
      defaultSymbol: 'GI:DJI:INDEX',
    };

const search = {
  pathname: `${essAPI}/ess/api/v1/siteSearch/fundQuoteName`,
  url: isTwStockChannel
    ? (keyword, num) => `${search.pathname}?q=${keyword}&page=${num}&category=TW`
    : (keyword, num) => `${search.pathname}?q=${keyword}&page=${num}&category=GLOBAL,CC`,
};

const news = isTwStockChannel
  ? {
      related: symbol => `${defaultAPI}/media/api/v1/newslist/${symbol}/news`,
    }
  : {
      related: symbol => `${defaultAPI}/media/api/v1/newslist/${symbol}/global/news`,
    };

const realtimeNews = `${defaultAPI}/media/api/v1/newslist/stock`;

const chat = isTwStockChannel
  ? {
      ref: 'messages',
      online: 'online',
      hintSymbol: '2330',
    }
  : {
      ref: 'messages-global',
      online: 'online-global',
      hintSymbol: 'AAPL',
    };
const meta = isTwStockChannel
  ? {
      title: '股市talk',
    }
  : {
      title: '國際股市talk',
    };

const firebaseChannel = isTwStockChannel ? 'TWStock' : 'GlobalStock';
const storage = isTwStockChannel ? 'tv_templates' : 'tv_global_templates';
const ref = isTwStockChannel ? 'stock' : 'global-stock';
const stockChannelName = isTwStockChannel ? '台股股市Talk' : '國際版股市Talk';

export default {
  quote,
  search,
  portfolio,
  firebaseChannel,
  chat,
  meta,
  news,
  realtimeNews,
  storage,
  ref,
  stockChannelName,
};
