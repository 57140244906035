import React from 'react';
import { isTwStockChannel } from 'constant/variation';
import SimpleModal from '../SimpleModal/SimpleModal';
import styles from './SourceModal.scss';

interface SourceModalProps {
  isEnabled?: boolean;
  closeHandler?: () => void;
}

function SourceModal({ isEnabled, closeHandler }: SourceModalProps) {
  const overlayStyle = {
    position: 'fixed',
  };

  return (
    <SimpleModal enableModal={isEnabled} enableClose closeHandler={closeHandler} overlayStyle={overlayStyle}>
      <div className={`${styles['source-modal-wrapper']} ${isTwStockChannel ? styles.tw : styles.global}`}>
        <h2>資料來源</h2>
        {isTwStockChannel ? (
          <>
            <ul>
              <li>臺灣證券交易所 TWSE</li>
              <li>證券櫃檯買賣中心 TPEx</li>
              <li>台灣期貨交易所 Taifex</li>
            </ul>
            <p>
              <p>
                本網站內容係經鉅亨網股份有限公司處理提供，資料僅供參考。
                <br />
                <br />
                所有資訊應以台灣證券交易所、櫃檯買賣中心、台灣期貨交易所公告資料為主。
                <br />
                <br />
                使用者依本資料交易發生損失需自行負責，本公司不對資料內容錯誤、更新延誤或傳輸中斷負任何責任。
                <br />
              </p>
            </p>
          </>
        ) : (
          <>
            <ul>
              <li>路透社</li>
              <li>FactSet</li>
              <li>香港交易所</li>
              <li>上海交易所</li>
              <li>深圳交易所</li>
              <li>Nasdaq</li>
              <li>NYSE</li>
            </ul>
            <p>
              本內容經係經鉅亨網股份有限公司處理提供，資料僅供參考。
              <br />
              <br />
              所有資訊應以交易所公告資料為主
              <br />
              <br />
              使用者依本資料交易發生損失需自行負責，本公司不對資料內容錯誤、更新延誤或傳輸中斷負任何責任。
              <br />
            </p>
          </>
        )}
      </div>
    </SimpleModal>
  );
}

export default SourceModal;
