export default {
  Code: 0,
  Name: 200009,
  TradeTime: 200007,
  ProductCode: 200010,
  Price: 6,
  Change: 11,
  ChangePercent: 56,
  HighPrice: 12,
  LowPrice: 13,
  LastClose: 21,
  Bid: 22,
  Sell: 25,
  OneMonth: 3380,
  ThreeMonth: 3378,
  SixMonth: 3379,
  TwelveMonth: 3381,
  HighPriceAnnually: 7868,
  LowPriceAnnually: 7869,
  Volume: 800001,
  hasInfo: 800005,
  hasMajorInvestor: 800006,
  hasRevenue: 800007,
  hasMarginShort: 800008,
  hasEps: 800015,
  isTrading: 800002,
  requestedSymbol: 800013,
  Direction: 200025,
};
