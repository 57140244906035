export function appendScript(
  script,
  appendTarget = 'body',
  scriptType = 'script'
) {
  return new Promise(resolve => {
    if (!script) {
      return;
    }

    const scriptElement = document.createElement(scriptType);
    const targetDOM =
      document.getElementsByTagName(appendTarget)[0] ||
      document.querySelector(appendTarget);

    scriptElement.innerHTML = script;
    targetDOM.appendChild(scriptElement);
    scriptElement.onload = resolve;
  });
}

// Load scripts asyncronously
export default function loadScript(src) {
  return new Promise(resolve => {
    const s = document.createElement('script');

    s.src = src;
    s.type = 'text/javascript';
    s.async = 'true';
    document.body.appendChild(s);
    s.onload = resolve;
  });
}
