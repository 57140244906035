import React from 'react';
import styles from './EsunAbout.scss';

function EsunAboutContent() {
  return (
    <div className={styles['content-wrapper']}>
      <div className={styles['esun-about-wrapper']}>
        <h1>2019 Anue 鉅亨網股市Talk X 玉山證券 是投資人聊天交易的好所在</h1>
        <br />
        <h2>鉅亨網</h2>
        <p>
          2016年Anue鉅亨網推出股市Talk，讓投資人瞭解台股大小事，掌握獲利契機，同時是聊天認識股友、了解其他投資人投資策略的好地方。
        </p>
        <p>
          2019年4月，中立客觀的鉅亨網與安心可靠的玉山證券攜手合作，將「最即時台股資訊服務」、「認識股友的好所在」與「即時掌握財經動態」的股市Talk再進化；以高等級保護的SSL加密技術，和金融等級的防火牆將使用者個資嚴格防衛共同推出「下單功能」。目的要讓股市Talk的投資人顛覆交易環境的想像，使交易台股層次再提升，原來證券下單是可以如此輕鬆又方便！透過一個電腦視窗，就能了解台股即時資訊、觀看財經節目，還能與其他網友一同討論投資策略，精準執行交易，抓住每檔熱門討論股。
        </p>

        <h2>最即時台股資訊服務</h2>

        <p>「股市Talk」整合多種即時台股資訊在單一視窗：個股即時報價、個股相關新聞、影音新聞。</p>

        <h2>認識股友的好所在</h2>

        <p>透過「股市Talk」聊天功能，能了解其他投資人的投資策略，還能讓看盤更有趣。</p>

        <h2>即時掌握財經動態</h2>

        <p>
          Anue鉅亨網新聞團隊，彙整全球重大財經新聞，搭配自選股功能，精準提供台股新聞，節省搜尋時間，掌握最即時財經趨勢。
        </p>

        <h2>安全便利的交易體驗</h2>

        <p>
          玉山證券提供開戶、帳務等模組置入，提供投資人單一視窗與介面，快速線上開戶與下單，告別視窗切換痛點與優化使用體驗，抓住每檔熱門討論股。
        </p>
      </div>
    </div>
  );
}

export default React.memo(() => <EsunAboutContent />, () => true);
