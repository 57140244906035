import { useState, useRef } from 'react';
import { GETv1EssSiteSearchFundQuoteName } from 'utils/api';

export default function useSearch(): [
  SearchResult[],
  (keyword: string) => Promise<() => Promise<void>>,
  () => void,
  boolean
] {
  const [result, setResult] = useState<SearchResult[]>([]);
  const [isSearching, setSearchStatus] = useState<boolean>(false);
  const latestSearchId = useRef<number>(0);
  let { current: timer } = useRef<NodeJS.Timer | null>(null);

  async function search(keyword: string) {
    let nextPage = 1;
    let lastPage = -1;
    let list: SearchResult[] = [];

    async function getPage(num: number) {
      console.log('search %o page %o', keyword, num);
      const searchId = ++latestSearchId.current;
      const response = await GETv1EssSiteSearchFundQuoteName(keyword, num);

      if (searchId < latestSearchId.current) {
        console.log(
          'search id = %o aborted  as latest search is %o keyword=[%o]',
          searchId,
          latestSearchId.current,
          keyword
        );
        return;
      }

      lastPage = response.data.lastPage;
      list = list.concat(response.data.items);
      setResult([...list]);
      setSearchStatus(false);
    }

    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(async () => {
      setSearchStatus(true);
      await getPage(1);
    }, 300);

    return async function() {
      if (++nextPage <= lastPage) {
        await getPage(nextPage);
      }
    };
  }

  function clear() {
    setResult([]);
  }

  return [result, search, clear, isSearching];
}
