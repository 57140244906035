import React, { Component } from 'react';
import { requestType, matchShape } from 'utils/propTypes';
import { replaceCreator } from 'utils/stringHelper';
import { GETv1Info } from '../../utils/api';
import styles from './Info.scss';

const dayTradeMarkMapping = {
  1: '不可以',
  2: '可先買後賣或先賣後買',
  3: '只可先買後賣',
};

const replaceAndReturnPercent = replaceCreator('-', value => `${value}%`);
const replaceAndReturn100million = replaceCreator('-', value => `${value}億`);

export default class Info extends Component {
  static propTypes = {
    match: matchShape.isRequired,
  };

  static contextTypes = {
    request: requestType,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  componentWillMount() {
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    const {
      params: { id },
    } = this.props.match;

    if (id !== nextProps.match.params.id) {
      this.setState(
        {
          data: {},
        },
        () => {
          this.getData();
        }
      );
    }
  }

  getData() {
    const { id } = this.props.match.params;

    GETv1Info(id)
      .then(
        res => {
          if (res && res.data && res.data.data) {
            this.setState({
              data: res.data.data,
            });
          }
        },
        error => {
          if (__DEVELOPMENT__ && console && console.error) {
            console.error(`API error: ${error}`);
          }
        }
      );
  }

  makeLink = link => {
    if (link) {
      const parsedLink = link.toLowerCase();

      return (
        <a href={parsedLink} target="_blank" rel="noopener noreferrer">
          {parsedLink}
        </a>
      );
    }

    return null;
  };

  renderOkOrNot = value => {
    if (value !== undefined) {
      return value ? '可以' : '不可以';
    }

    return null;
  };

  renderDayTradeMark = value => {
    if (value !== undefined) {
      return dayTradeMarkMapping[value] || null;
    }

    return null;
  };

  render() {
    const {
      companyName,
      startAtS,
      listingDateS,
      president,
      spokesman,
      telephoneNumber,
      email,
      agency,
      agencyPhone,
      companyAddress,
      url,
      description,
      stockType,
      industryType,
      paidUpCapital,
      foreignStockOwnRatio,
      utilization,
      stockMarginRatio,
      shortSelling,
      dayTradeMark,
      marketValueE,
      netAsset,
      pbr,
      perValue,
    } = this.state.data;

    return (
      <div className={`${styles['info-table']} col`}>
        {companyName && (
          <div className="row">
            <span>公司名稱</span>
            <span>{companyName}</span>
          </div>
        )}
        {(startAtS || listingDateS) && (
          <div className="row">
            <span>成立日期</span>
            <span>{startAtS || '-'}</span>
            <span>上市日期</span>
            <span>{listingDateS || '-'}</span>
          </div>
        )}
        {(president || spokesman) && (
          <div className="row">
            <span>董事長</span>
            <span>{president}</span>
            <span>發言人</span>
            <span>{spokesman}</span>
          </div>
        )}
        {(telephoneNumber || email) && (
          <div className="row">
            <span>聯絡電話</span>
            <span>{telephoneNumber}</span>
            <span>電子郵件</span>
            <span>{email}</span>
          </div>
        )}
        {agency && (
          <div className="row">
            <span>股務代理</span>
            <span>{agency} {agencyPhone || ''}</span>
          </div>
        )}
        {companyAddress && (
          <div className="row">
            <span>公司地址</span>
            <span>{companyAddress}</span>
          </div>
        )}
        {url && (
          <div className="row">
            <span>公司網址</span>
            <span>{this.makeLink(url)}</span>
          </div>
        )}
        {description && (
          <div className="row">
            <span>主要業務</span>
            <span>{description}</span>
          </div>
        )}
        {(stockType || industryType) && (
          <div className="row">
            <span>市場別</span>
            <span>{stockType}</span>
            <span>產業別</span>
            <span>{industryType}</span>
          </div>
        )}
        {(paidUpCapital || marketValueE) && (
          <div className="row">
            <span>實收資本</span>
            <span>{replaceAndReturn100million(paidUpCapital)}</span>
            <span>公司市值</span>
            <span>
              {marketValueE !== null
                ? replaceAndReturn100million(marketValueE)
                : '-'}
            </span>
          </div>
        )}
        {(foreignStockOwnRatio || foreignStockOwnRatio) && (
          <div className="row">
            <span>外資持股</span>
            <span>{replaceAndReturnPercent(foreignStockOwnRatio)}</span>
            <span>融資使用率</span>
            <span>{replaceAndReturnPercent(utilization)}</span>
          </div>
        )}
        {(netAsset || stockMarginRatio) && (
          <div className="row">
            <span>淨值</span>
            <span>{netAsset !== null ? netAsset : '-'}</span>
            <span>券資比</span>
            <span>{replaceAndReturnPercent(stockMarginRatio)}</span>
          </div>
        )}
        {(pbr || shortSelling) && (
          <div className="row">
            <span>淨值比</span>
            <span>{pbr !== null ? pbr : '-'}</span>
            <span>平盤下融券</span>
            <span>{this.renderOkOrNot(shortSelling)}</span>
          </div>
        )}
        {(perValue || dayTradeMark) && (
          <div className="row">
            <span>本益比</span>
            <span>{perValue !== null ? perValue : '-'}</span>
            <span>現股當沖</span>
            <span>{this.renderDayTradeMark(dayTradeMark)}</span>
          </div>
        )}
      </div>
    );
  }
}
