import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from 'containers/App/AppContainer';
import LazyHighchart from 'components/LazyHighchart';
import Info from 'components/Info/Info';
import NotFoundPage from 'containers/NotFoundPage/NotFoundPage.js';
import Variation from 'constant/variation';
import AgreementPage from 'components/AgreementPage/AgreementPage';
import EsunAboutPage from 'components/EsunAboutPage/EsunAboutPage';

const LazyComponent = fn => props => <LazyHighchart getComponent={fn} {...props} /> || null;

const EmptyRoute = ({ location, history }) => {
  if (location.pathname === '/') {
    history.push(`/market/${Variation.quote.defaultSymbol}`);
  }

  return null;
};

function RouteDefine() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={EmptyRoute} />
        <Route path="/market/:id?/:tab?">
          <App>
            <Route
              path="/market/:id/3investor"
              component={LazyComponent(
                async () => await import(/* webpackChunkName: "investor" */ 'components/Investor/Investor')
              )}
            />
            <Route
              path="/market/:id/margin"
              component={LazyComponent(
                async () => await import(/* webpackChunkName: "margin" */ 'components/Margin/Margin')
              )}
            />
            <Route
              path="/market/:id/revenue"
              component={LazyComponent(
                async () => await import(/* webpackChunkName: "revenue" */ 'components/Revenue/Revenue')
              )}
            />
            <Route
              path="/market/:id/(eps|income|balance|ratios)"
              component={LazyComponent(
                async () => await import(/* webpackChunkName: "fin-report" */ 'components/FinReport/FinReport')
              )}
            />
            <Route path="/market/:id/info" component={Info} />
          </App>
        </Route>
        <Route path="/agreement" component={AgreementPage} />
        <Route path="/esun-about" component={EsunAboutPage} />
        <Route path="404" component={NotFoundPage} />
        <Route path="*" status={404} component={NotFoundPage} />
      </Switch>
    </div>
  );
}

export default RouteDefine;
