import React, { Component, PropTypes } from 'react';
import { SEND_PROJ_TARGET } from '../../utils/analytics';
import { handleSearchAttrsAndAppendGaEvent } from '../../utils/append-ga-event';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line
  require('element-closest');
}

class AnalyticsWrapper extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    sendEvent: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: undefined,
  };

  static handleSearchAnueGaAttr() {
    const rootDom = document.getElementById('anue-ga-wrapper');

    this.attributesObserver = new MutationObserver(mutationsList => {
      let hasChildChanged = false;

      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          hasChildChanged = true;
        }
      }

      if (hasChildChanged) {
        SEND_PROJ_TARGET.forEach(target => {
          handleSearchAttrsAndAppendGaEvent(target);
        });
      }
    });

    this.attributesObserver.observe(rootDom, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  }

  componentDidMount() {
    AnalyticsWrapper.handleSearchAnueGaAttr();
  }

  sendEventLog = e => {
    // old event - will deprecated in few months.
    const target = e.target && e.target.closest('[data-ga-category],[data-ga-action],[data-ga-label]');
    const gaKeys = ['category', 'action', 'label'];

    if (target) {
      const gaParams = gaKeys.map(key => target.getAttribute(`data-ga-${key}`));

      this.props.sendEvent(...gaParams); // old ga event
    }

    // new events
    SEND_PROJ_TARGET.forEach(gaName => {
      const gaTarget =
        e.target &&
        e.target.closest(`[data-${gaName}-ga-category],[data-${gaName}-ga-action],[data-${gaName}-ga-label]`);

      if (gaTarget) {
        const gaParams = gaKeys.map(key => gaTarget.getAttribute(`data-${gaName}-ga-${key}`));

        this.props.sendEvent(...gaParams, {}, [gaName]);
      }
    });
  };

  render() {
    return <div id="anue-ga-wrapper" onClick={this.sendEventLog}>{this.props.children}</div>;
  }
}

export default AnalyticsWrapper;
